import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Edit,
  setAutomaticRefresh,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  useAuthenticated,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
  useMutation,
  LinearProgress,
} from "react-admin";
import {
  API,
  Logger,
} from "aws-amplify";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  useMediaQuery,
  Theme,
  Tooltip,
} from "@material-ui/core";
import MeetingCountdown from "../components/MeetingCountdown";
import { MeetingsStateContext } from "../App";
import { AmplifyImageField } from "../components/AmplifyImageField";
import Paper from "@material-ui/core/Paper";
import clone from "lodash/clone";
import type {
  ListMeetingInvitesByProfileIdQuery,
  MeetingInvite,
  ProductQuestion,
  QuestionResponse,
  Team,
  TeamImage,
  UpdateQuestionResponseInput,
} from "../API";
import {
  MeetingInvitationType,
  MeetingStatus,
  ModelSortDirection,
} from "../API";
import NoDeleteToolbar from "../components/NoDeleteToolbar";
import type { ProductQuestionWithMeetingInvite } from "../types";
import { AWS_TIMESTAMP_AS_LUXON_FORMAT } from "../constants";
import { listMeetingInvitesByProfileId } from "../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { sellerBuyerStyles } from "../layout/theme";
import {
  awsDateTimeStringToDateTime,
  createOrUpdateQuestionResponse,
  getCurrentDateTime,
  getMeetingInvitesCount,
  getTeamByMeetingIdAndBuyerOrSeller,
  getTeamImageByTeamId,
  logEvent,
} from "../lib/helpers";
import ScheduledMeetingsMenu from "../components/ScheduledMeetingsMenu";
import { usePreferences } from "@react-admin/ra-preferences";

const logger = new Logger("SellerUpcomingDoctors");

/**
 * Seller  Upcoming Products
 * 
 * @returns {JSX.Element}
 */
export const SellerUpcomingDoctorsPage = () => {

  useAuthenticated(); // redirects to login if not authenticated
  const [ mutate ] = useMutation();
  const redirect = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = sellerBuyerStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  dispatch(setAutomaticRefresh(false));
  const [ questionResponsesData, setQuestionResponsesData ] = useState<Map<string, QuestionResponse>>(new Map());
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ isFormDirty, setIsFormDirty ] = useState<boolean>(false);
  const [ saveTimeout, setSaveTimeout ] = useState<number | NodeJS.Timeout>(0);
  const [ saveCounter, setSaveCounter ] = useState<number>(0);
  const [ isNoQuestions, setIsNoQuestions ] = useState<boolean>(false);
  const [ data, setData ] = useState<ProductQuestionWithMeetingInvite[]>([]);
  const [ page, setPage ] = useState(1);
  const [ totalCount, setTotalCount] = useState(0);
  const [ hasPreviousPage, setHasPreviousPage ] = useState(false);
  const [ hasNextPage, setHasNextPage ] = useState(false);
  const [ tokensByPageNumber, setTokensByPageNumber ] = useState<Map<string, string | null>>(new Map());
  const [ teamImagesMap, setTeamImagesMap ] = useState<Map<string, TeamImage>>(new Map());
  const [ clockSyncEnabled ] = usePreferences("clockSyncEnabled", false);
  const [ cachedServerTimeDifference ] = usePreferences("cachedServerTimeDifference", 0);
  const meetingsState = useContext(MeetingsStateContext);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const {
    meetingInvitesState,
    nextMeetingInvite,
    currentProfile,
    isSeller,
    isBuyer,
    userAgentData,
    defaultLogo,
    inAppLogo,
  } = meetingsState;
  const scheduledMeetingInvites = meetingInvitesState.scheduledMeetings;
  const getNextPage = () => {
    setIsLoaded(false);
    setPage((page + 1) > totalCount ? 1 : page + 1);
  }
  const getPreviousPage = () => {
    setIsLoaded(false);
    setPage((page - 1) < 1 || (page - 1) > totalCount ? 1 : page - 1);
  }
  const nextPageNumber = () => `${page + 1}`;

  useEffect(() => {
    if (currentProfile && currentProfile.id) {
      logger.info("SellerUpcomingDoctors called...");
      const now = getCurrentDateTime(cachedServerTimeDifference, clockSyncEnabled).toUTC()
      const daysAgo = now.minus({ days: 3 });
      const daysAhead = now.plus({ days: 3 });
      const startDateTimeRange = daysAgo.toFormat(
        AWS_TIMESTAMP_AS_LUXON_FORMAT
      );
      const endDateTimeRange = daysAhead.toFormat(
        AWS_TIMESTAMP_AS_LUXON_FORMAT
      );
      getMeetingInvitesCount(
        MeetingStatus.CANCELLED,
        startDateTimeRange,
        endDateTimeRange,
        currentProfile.id,
        true, // sets not equal condition
      ).then(
        (countResult) => {
          logger.info("getMeetingInvitesCount result", countResult);
          setTotalCount(countResult.count);
          if (countResult.count === 0) {
            redirect("/");
          }
        }
      ).catch(
        (err: Error) => {
          // TODO show and capture error
          logger.error(
            "getMeetingInvitesCount error",
            err,
          );
        }
      );
    }
  }, [currentProfile?.id, clockSyncEnabled, cachedServerTimeDifference]);

  useEffect(() => { // NOTE: new query for meeting and questions response (notes)
    if (currentProfile && totalCount > 0) {
      const now = getCurrentDateTime(cachedServerTimeDifference, clockSyncEnabled).toUTC()
      const daysAgo = now.minus({ days: 3 });
      const daysAhead = now.plus({ days: 3 });
      const startDateTimeRange = daysAgo.toFormat(
        AWS_TIMESTAMP_AS_LUXON_FORMAT
      );
      const endDateTimeRange = daysAhead.toFormat(
        AWS_TIMESTAMP_AS_LUXON_FORMAT
      );
      const nextToken = tokensByPageNumber.get(`${page}`);
      logger.info('pagination - page', page);
      logger.info(`pagination - token for page ${page}`, nextToken);
      (API.graphql({
        query: listMeetingInvitesByProfileId,
        variables: {
          limit: 1,
          profileId: currentProfile.id,
          startDateTime: {
            between: [ 
              startDateTimeRange,
              endDateTimeRange,
            ],
          },
          sortDirection: ModelSortDirection.ASC,
          filter: {
            status: {
              ne: MeetingStatus.CANCELLED,
            },
          },
          ...(nextToken && {
            nextToken,
          }),
        },
      }) as Promise<GraphQLResult>).then(
        async (queryResult) => {
          if (queryResult.errors || !queryResult.data) {
            logger.error("queryResult", queryResult);
            throw new Error("Data provider error");
          }
          let numberOfResults = 0;
          let currentNextToken: null | string = null;
          if (page === 1) {
            if (hasPreviousPage) {
              setHasPreviousPage(false);
            }
          } else {
            if (!hasPreviousPage) {
              setHasPreviousPage(true);
            }
          }
          if (hasNextPage && (totalCount === page || page > totalCount)) {
            setHasNextPage(false);
          }
          const listMeetingInvitesByProfileIdResults = (queryResult.data as ListMeetingInvitesByProfileIdQuery).listMeetingInvitesByProfileId;
          if (listMeetingInvitesByProfileIdResults && listMeetingInvitesByProfileIdResults.items) {
            if (listMeetingInvitesByProfileIdResults.nextToken) {
              logger.info("++ pagination - listMeetingInvitesByProfileIdResults.nextToken", listMeetingInvitesByProfileIdResults.nextToken);
              currentNextToken = listMeetingInvitesByProfileIdResults.nextToken;
              const nextPageToken = tokensByPageNumber.get(nextPageNumber());
              if (!nextPageToken) {
                const updatedTokensByPageNumber = new Map(tokensByPageNumber);
                updatedTokensByPageNumber.set(
                  nextPageNumber(),
                  currentNextToken,
                );
                setTokensByPageNumber(updatedTokensByPageNumber);
              }
              if (!hasNextPage && (page < totalCount)) {
                setHasNextPage(true);
              }
            }
            const productQuestions: ProductQuestionWithMeetingInvite[] = [];
            numberOfResults = listMeetingInvitesByProfileIdResults.items.length;
            for (let i = 0; i < listMeetingInvitesByProfileIdResults.items.length; i=i+1) {
              const meetingInvite = listMeetingInvitesByProfileIdResults.items[i] as MeetingInvite;
              logger.info("++ meetingInvite", meetingInvite);
              logger.info("++ meetingInvite.id", meetingInvite.id);
              const {
                productId,
              } = meetingInvite;
              if (!productId) {
                continue;
              }
              await dataProvider.getList(
                "productQuestions",
                {
                  pagination: { page: 1, perPage: 1 },
                  sort: { field: "listProductQuestionsByProductId", order: "" },
                  filter: {
                    listProductQuestionsByProductId: { // the name of the index
                      productId,
                    },
                  },
                },
              ).then(
                async (listOfProductQuestions) => {
                  logger.info("++ listOfProductQuestions.data", listOfProductQuestions.data);
                  if (listOfProductQuestions.data && listOfProductQuestions.data.length > 0) {
                    let productQuestion = listOfProductQuestions.data[0] as ProductQuestion;
                    await dataProvider.getList(
                      "questionResponses",
                      {
                        pagination: { page: 1, perPage: 1 },
                        sort: { field: "listQuestionResponsesByMeetingInviteId", order: "" },
                        filter: {
                          listQuestionResponsesByMeetingInviteId: { // the name of the index
                            meetingInviteId: meetingInvite.id,
                          },
                        },
                      },
                    ).then(
                      async (listOfQuestionResponses) => {
                        logger.info("++ listOfQuestionResponses.data", listOfQuestionResponses.data);
                        if (listOfQuestionResponses.data) {
                          if (listOfQuestionResponses.data.length === 0) {
                            // TODO create the QuestionResponse
                            await createOrUpdateQuestionResponse(
                              dataProvider,
                              meetingInvite,
                              "",
                            ).then(
                              (updatedQuestionResponse) => {
                                logger.info("createOrUpdateQuestionResponse", updatedQuestionResponse);
                              }
                            ).catch(
                              (err: any) => {
                                logger.error("error createOrUpdateQuestionResponse", err);
                              }
                            );
                          }
                          productQuestion = {
                            ...productQuestion,
                            ...{
                              responses: {
                                __typename: "ModelQuestionResponseConnection",
                                items: listOfQuestionResponses.data as QuestionResponse[],
                              }
                            }
                          };
                          let buyerTeamId: string | null | undefined = null;
                          let buyerTeam: Team | null | undefined = null;
                          let buyerTeamImage: TeamImage | null | undefined = null;
                          logger.info("meetingInvite.meetingInvitationType", meetingInvite.meetingInvitationType);
                          if (meetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER) {
                            buyerTeam = await getTeamByMeetingIdAndBuyerOrSeller(
                              meetingInvite.meetingId,
                              "buyer",
                            ).then(
                              (team) => {
                                return team;
                              }
                            ).catch(
                              (err: any) => {
                                logger.error("Error getting team", err);
                                return null;
                              }
                            );
                            logger.info("buyerTeam", buyerTeam);
                            if (buyerTeam && !buyerTeam.privacyEnabled) {
                              buyerTeamId = `${buyerTeam.id}`;
                              logger.info("+++ buyerTeamId", buyerTeamId);
                              buyerTeamImage = teamImagesMap.get(buyerTeamId);
                              if (!buyerTeamImage) {
                                buyerTeamImage = await getTeamImageByTeamId(buyerTeamId, dataProvider);
                                if (buyerTeamImage) {
                                  const updatedImagesMap = new Map(teamImagesMap);
                                  updatedImagesMap.set(buyerTeamId, buyerTeamImage);
                                  setTeamImagesMap(updatedImagesMap);
                                }
                              } else {
                                logger.info("+++ buyerTeamImage is cached");
                              }
                              logger.info("+++ buyerTeamImage.id", buyerTeamImage?.id);
                            }
                          }
                          const productQuestionWithMeetingInvite: ProductQuestionWithMeetingInvite = {
                            ...productQuestion,
                            ...{
                              meetingInvite,
                              buyerTeamId,
                              buyerTeam,
                              buyerTeamImage,
                              buyerPrivateEmails: [],
                            }
                          };
                          productQuestions.push(productQuestionWithMeetingInvite);
                        }
                      }
                    ).catch(
                      (err: Error) => {
                        logger.error("error querying QuestionResponses", err);
                      }
                    );
                  }
                }
              ).catch(
                (err: Error) => {
                  logger.error("error querying ProductQuestions", err);
                }
              ); 
            }
            setData(productQuestions);
            logger.info("+++ setData called", productQuestions);
            if (!isLoaded) {
              setIsLoaded(true);
              logger.info("+++ setIsLoaded called");
            }
          } else {
            if (!isLoaded) {
              setIsLoaded(true);
            }
            if (!isNoQuestions) {
              setIsNoQuestions(true);
            }
          }
          if ((numberOfResults === 0 || !currentNextToken)) {
            // setNextToken(null);
            logger.info('pagination - numberOfResults', numberOfResults);
            logger.info('pagination - currentNextToken', currentNextToken);
            setHasNextPage(false);
          }
        }
      ).catch(
        (err: Error) => {
          logger.error("error querying listOfMeetingInvites", err);
        }
      );
    }
  }, [currentProfile?.id, page, totalCount, saveCounter, clockSyncEnabled, cachedServerTimeDifference]);

  useEffect(() => {
    if (isLoaded) {
      if (isNoQuestions) {
        redirect("/");
      }
    }
  }, [isLoaded, isNoQuestions]);

  const transformQuestionResponse = async (data: UpdateQuestionResponseInput) => {
    logger.info("transform.data", data);
    const {
      providePersonalEmail,
      providePersonalPhoneNumber,
      textOnly,
    } = data;
    if (Object.keys(data).includes('__typename')) {
      // @ts-ignore
      delete data["__typename"];
    }
    const updateData: UpdateQuestionResponseInput = {
      ...data,
    };
    logger.info("transform.updateData", updateData);
    return updateData;
  };

  const onFailure = async (error: any) => {
    logger.error("onFailure.error", error);
    const errorMessage = "An error occurred";
    notify(errorMessage, { type: "error" });
    setIsFormDirty(false);
    setSaveCounter(saveCounter + 1);
    refresh();
    if (currentProfile) {
      await logEvent(
        currentProfile,
        "failed tp save notes on break page",
        {
          /* meeting: {
            id: data.meetingId,
            invite_id: data.meetingInviteId,
          }, */
          details: error,
        },
        'warn',
        cachedServerTimeDifference,
        userAgentData,
      );
    }
  };

  const onSuccess = async (onSuccessResponse: any) => {
    const {
      data,
    } = onSuccessResponse;
    logger.info("onSuccess response", onSuccessResponse);
    logger.info("onSuccess data", data);
    const successMessage = "Saved";
    notify(successMessage, { type: "success" });
    setIsFormDirty(false);
    setSaveCounter(saveCounter + 1);
    const {
      id,
      /* providePersonalEmail,
      providePersonalPhoneNumber,
      textOnly,
      followupMeetingRequested,
      contactRequestDateTime, */
      response,
      privateNotes,
      _version,
    } = data;
    logger.info("onSuccess _version", _version);
    const responseData = {
      id,
      /* providePersonalEmail,
      providePersonalPhoneNumber,
      textOnly,
      followupMeetingRequested,
      contactRequestDateTime, */
      response,
      privateNotes,
      _version,
    };
    logger.info("onSuccess responseData", responseData);
    logger.info("questionResponsesData", questionResponsesData);
    const updatedQuestionResponsesData = clone(questionResponsesData);
    logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
    updatedQuestionResponsesData.set(
      id, // @ts-ignore
      responseData,
    );
    setQuestionResponsesData(updatedQuestionResponsesData);
    refresh();
    if (currentProfile) {
      await logEvent(
        currentProfile,
        "notes saved on break page",
        {
          details: data,
        },
        'info',
        cachedServerTimeDifference,
        userAgentData,
      );
    }
  };

  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (questionResponseData: QuestionResponse) => {
      const data = await transformQuestionResponse(questionResponseData);
      logger.info("save data", data);
      const { // TODO only fields from form
        id,
        /* providePersonalEmail,
        providePersonalPhoneNumber,
        textOnly,
        followupMeetingRequested,
        contactRequestDateTime, */
        response,
        privateNotes,
        _version,
      } = data;
      logger.info("onSuccess _version", _version);
      const responseData = {
        id,
        /* providePersonalEmail,
        providePersonalPhoneNumber,
        textOnly,
        followupMeetingRequested,
        contactRequestDateTime, */
        response,
        privateNotes,
        _version,
      };
      logger.info("onSuccess responseData", responseData);
      logger.info("questionResponsesData", questionResponsesData);
      const updatedQuestionResponsesData = clone(questionResponsesData);
      logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
      updatedQuestionResponsesData.set(
        id, // @ts-ignore
        responseData,
      );
      setQuestionResponsesData(updatedQuestionResponsesData);
      await mutate(
        {
          type: "update",
          resource: "questionResponses",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess,
          onFailure,
        }
      );
    },
    [mutate],
  );

  useEffect(() => {
    logger.info("seconds remaining useEffect", { nextMeetingInvite });
    if (nextMeetingInvite) {
      logger.info("starting seconds remaining checkerInterval...");
      const checkerInterval = setInterval(
        () => {
          try {
            const nowTimestamp = getCurrentDateTime(cachedServerTimeDifference, clockSyncEnabled).toUnixInteger();
            const {
              startDateTime,
            } = nextMeetingInvite;
            const startTimestamp = awsDateTimeStringToDateTime(
              startDateTime,
            ).toUnixInteger();
            const secondsRemaining = Math.round(startTimestamp - nowTimestamp);
            if (secondsRemaining === 15) {
              redirect("/");
            }
          } catch(err) {
            logger.error("error calculating secondsRemaining", err);
          }
        },
        1000,
      );
      return () => {
        if (checkerInterval) {
          clearInterval(checkerInterval);
        }
      }
    }
  }, [nextMeetingInvite, clockSyncEnabled, cachedServerTimeDifference]);

  return (
    <>
      <Grid
        container
        spacing={3}
        direction={isSmall ? "column" : "row"}
      >
        {!isSmall && (
        <Grid item md={8}>
          <img src={inAppLogo ? inAppLogo.url : (defaultLogo ? defaultLogo.url : "/img/logo-connect.png")} height={isSmall ? "55" : "110"} />
        </Grid>
        )}
        <Grid item xs={12} sm={12} md={4} alignContent={isSmall ? undefined : "flex-end"}>
          {(nextMeetingInvite && currentProfile) ? 
          <MeetingCountdown
            {...meetingsState}
            nextMeetingInvite={nextMeetingInvite}
            currentProfile={currentProfile}
            useEndDateTime={false}
            clockSyncEnabled={clockSyncEnabled}
            cachedServerTimeDifference={cachedServerTimeDifference}
            inAppLogo={inAppLogo}
            defaultLogo={defaultLogo}
          /> : null
          }
        </Grid>
      </Grid>
      <div className={classes.tableRow}>
      {!isSmall && (
        <div className={classes.tableMenuColumn}>
          <ScheduledMeetingsMenu
            title={"Upcoming meetings"}
            meetings={scheduledMeetingInvites}
            isSeller={isSeller}
            isBuyer={isBuyer}
            showBackToMeetingsLink={true}
            clockSyncEnabled={clockSyncEnabled}
            cachedServerTimeDifference={cachedServerTimeDifference}
          />
        </div>
      )}
        <div className={classes.tableContentColumn}>
        {isLoaded ? (
          <>
          <Grid
            container
            spacing={isSmall ? 5 : 10}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={12} sm={12}>
              <Typography variant={isSmall ? undefined : "h5"} className={classes.boldText}>Physician profiles. We'll send you the notes and contact info after the meeting.</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={isSmall ? 5 : 10}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                    <TableRow key="nav-1">
                      <TableCell>
                        <Button
                          size={isSmall ? "small" : "large"}
                          onClick={() => getPreviousPage() }
                          label="Previous"
                          endIcon={<NavigateBeforeIcon />}
                          disabled={isFormDirty || !hasPreviousPage}
                        >
                          <NavigateBeforeIcon />
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {(totalCount) ? (
                          <Typography variant={isSmall ? undefined : "h4"} className={classes.boldText}>Page {page} of {totalCount}</Typography>
                        ) : (
                          <Typography variant={isSmall ? undefined : "h4"} className={classes.boldText}>&nbsp;</Typography>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          size={isSmall ? "small" : "large"}
                          onClick={() => getNextPage() }
                          label="Next"
                          endIcon={<NavigateNextIcon />}
                          disabled={isFormDirty || !hasNextPage}
                        >
                          <NavigateNextIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={12}>
              <TableContainer component={Paper}>
              {data.map((question, i) => (
                <Table size="small">
                  {question.responses && question.responses.items && question.responses.items.length > 0 && question.meetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER && (
                  <TableBody>
                      <SimpleShowLayout>
                        <TableRow key={`${question.id}-${i}-1`}>
                          <TableCell>
                            {isLoaded && question.buyerTeamImage && (
                              <AmplifyImageField
                                source="image"
                                title="title"
                                label=""
                                resource="teamImages"
                                basePath="/teamImages"
                                record={question.buyerTeamImage}
                              />
                            )}
                          </TableCell>
                          {!isSmall && (
                          <TableCell className={classes.tableCell}  >
                            {question.buyerTeam && (
                              <Typography className={classes.productName}>
                              {question.buyerTeam.name}
                              </Typography>
                            )}
                            {question.buyerTeam && (
                              <Typography>
                              {question.buyerTeam.description}
                              </Typography>
                            )}
                          </TableCell>
                          )}
                        </TableRow>
                      </SimpleShowLayout>
                    {isSmall && (
                    <TableRow key={`second-small-${question.id}-${i}`}>
                      <TableCell className={classes.tableCell}  >
                        {question.buyerTeam && (
                          <Typography className={classes.productName}>
                          {question.buyerTeam.name}
                          </Typography>
                        )}
                        {question.buyerTeam && (
                          <Typography>
                          {question.buyerTeam.description}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    )}
                    <TableRow key={`second-${question.id}-${i}`}>
                      <TableCell className={classes.tableCell} colSpan={2}>
                        <Edit // @ts-ignore
                          id={question.responses?.items[0].id} // @ts-ignore
                          record={question.responses?.items[0]}
                          resource="questionResponses"
                          basePath="/questionResponses"
                          title={<></>}
                          undoable={false}
                          onSuccess={onSuccess}
                          mutationMode="optimistic"
                          redirect={false}
                        >
                        <SimpleForm
                          toolbar={<NoDeleteToolbar />}
                          save={save}
                          redirect={false}
                          warnWhenUnsavedChanges
                          resetOptions={{"keepDirtyValues": true}}
                          resetoptions={{"keepDirtyValues": true}}
                        >
                        <Chip label="Note to Surgeon" color="primary" className={classes.chip} />
                        <TextInput
                          multiline
                          source="response"
                          label="Limit 500 characters and spaces"
                          minRows="4"
                          maxLength="500"
                          fullWidth
                          onChange={
                            (e) => {
                              logger.info("response event", e);
                              if (!isFormDirty) {
                                setIsFormDirty(true);
                              }
                              if (saveTimeout) {
                                try {
                                  clearTimeout(saveTimeout as NodeJS.Timeout);
                                } catch (err) {}
                              }
                              // @ts-ignore
                              const id = `${question.responses?.items[0].id}`;
                              let originalQuestionResponse = questionResponsesData.get(id);
                              if (originalQuestionResponse === undefined) {
                                logger.warn("1 originalQuestionResponse is undefined", originalQuestionResponse);
                                // @ts-ignore
                                originalQuestionResponse = {
                                  id, // @ts-ignore
                                  _version: question.responses?.items[0]?._version,
                                };
                              }
                              logger.info("originalQuestionResponse", originalQuestionResponse);
                              // @ts-ignore
                              const responseData: QuestionResponse = {
                                ...originalQuestionResponse,
                                response: e.target.value,
                              };
                              logger.info("newQuestionResponse", responseData);
                              logger.info("questionResponsesData", questionResponsesData);
                              const updatedQuestionResponsesData = clone(questionResponsesData);
                              logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
                              updatedQuestionResponsesData.set(
                                id,
                                responseData,
                              );
                              setQuestionResponsesData(updatedQuestionResponsesData);
                              const newSaveTimeout = setTimeout(
                                async () => {
                                  setSaveTimeout(0);
                                  logger.info("calling saved...", responseData);
                                  await mutate(
                                    {
                                      type: "update",
                                      resource: "questionResponses",
                                      payload: { data: responseData },
                                    },
                                    {
                                      returnPromise: true,
                                      onSuccess,
                                      onFailure,
                                    }
                                  );
                                },
                                3000
                              );
                              setSaveTimeout(newSaveTimeout);
                            }
                          }
                        />
                        <Chip label="Private Notes" color="primary" className={classes.chip} />
                        <TextInput
                          multiline
                          source="privateNotes"
                          label=""
                          minRows="4"
                          fullWidth
                          onChange={
                            (e) => {
                              logger.info("response event", e);
                              if (!isFormDirty) {
                                setIsFormDirty(true);
                              }
                              if (saveTimeout) {
                                try {
                                  clearTimeout(saveTimeout as NodeJS.Timeout);
                                } catch (err) {}
                              }
                              // @ts-ignore
                              const id = `${question.responses?.items[0].id}`;
                              let originalQuestionResponse = questionResponsesData.get(id);
                              if (originalQuestionResponse === undefined) {
                                logger.warn("2 originalQuestionResponse is undefined", originalQuestionResponse);
                                // @ts-ignore
                                originalQuestionResponse = {
                                  id, // @ts-ignore
                                  _version: question.responses?.items[0]?._version,
                                };
                              }
                              logger.info("originalQuestionResponse", originalQuestionResponse);
                              // @ts-ignore
                              const responseData: QuestionResponse = {
                                ...originalQuestionResponse,
                                privateNotes: e.target.value,
                              };
                              logger.info("newQuestionResponse", responseData);
                              logger.info("questionResponsesData", questionResponsesData);
                              const updatedQuestionResponsesData = clone(questionResponsesData);
                              logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
                              updatedQuestionResponsesData.set(
                                id,
                                responseData,
                              );
                              setQuestionResponsesData(updatedQuestionResponsesData);
                              const newSaveTimeout = setTimeout(
                                async () => {
                                  setSaveTimeout(0);
                                  logger.info("calling saved...", responseData);
                                  await mutate(
                                    {
                                      type: "update",
                                      resource: "questionResponses",
                                      payload: { data: responseData },
                                    },
                                    {
                                      returnPromise: true,
                                      onSuccess,
                                      onFailure,
                                    }
                                  );
                                },
                                3000
                              );
                              setSaveTimeout(newSaveTimeout);
                            }
                          }
                        />
                          </SimpleForm>
                        </Edit>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )}
                </Table>
              ))}
              </TableContainer>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                    <TableRow key="nav2">
                      <TableCell>
                        <Button
                          size={isSmall ? "small" : "large"}
                          onClick={() => getPreviousPage() }
                          label="Previous"
                          endIcon={<NavigateBeforeIcon />}
                          disabled={isFormDirty || !hasPreviousPage}
                        >
                          <NavigateBeforeIcon />
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {(totalCount) ? (
                          <Typography variant={isSmall ? undefined : "h4"} className={classes.boldText}>Page {page} of {totalCount}</Typography>
                        ) : (
                          <Typography variant={isSmall ? undefined : "h4"} className={classes.boldText}>&nbsp;</Typography>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          size={isSmall ? "small" : "large"}
                          onClick={() => getNextPage() }
                          label="Next"
                          endIcon={<NavigateNextIcon />}
                          disabled={isFormDirty || !hasNextPage}
                        >
                          <NavigateNextIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          </>
        ) : <LinearProgress />}
        </div>
      </div>
    </>
  );
};
