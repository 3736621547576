import { Auth, CognitoUser } from "@aws-amplify/auth";
import type { ClientMetaData } from "@aws-amplify/auth/lib-esm/types";
import { Cache, DataStore, Logger } from "aws-amplify";
import Cookies from 'universal-cookie';
import { UserIdentity } from "ra-core";
import { getProfile } from "../lib/helpers";
import { CognitoUserAmplifyWithAttributes } from "../types";

const logger = new Logger("AuthProvider");
export interface AuthProviderOptions {
  authGroups?: string[];
  enableAdminQueries?: boolean;
}

const defaultOptions = {
  authGroups: [],
  enableAdminQueries: false,
};

export class AuthProvider {
  public authGroups: string[];

  public constructor(options?: AuthProviderOptions) {
    this.authGroups = options?.authGroups || defaultOptions.authGroups;
  }

  public login = ({
    username,
    password,
    clientMetadata,
  }: Record<string, unknown>): Promise<CognitoUser | unknown> => {
    return Auth.signIn(
      username as string,
      password as string,
      clientMetadata as ClientMetaData
    ).then((cognitoUser) => {
      // NOTE: Amplify DataStore best practice: Clear offline data on sign-in.
      return DataStore.clear()
        .then(
          () => {
            logger.info("DataStore.clear() was successful on login.");
          }
        )
        .catch((err) => {
          logger.warn(err);
        }).finally(
          () => {
            return cognitoUser;
          }
        );
    });
  };

  /**
   * logout.
   *
   * @returns {Promise<any>} any
   */
  public logout = async (params=null): Promise<any> => {
    try {
      Cache.clear();
      if (params) {
        const {
          clearCookies,
        } = params;
        if (clearCookies === true) {
          const cookies = new Cookies(null, { path: '/' });
          logger.info('custom theme calling deleteCookies', cookies);
          cookies.remove("hasCustomTheme", { path: '/' });
          cookies.remove("customDarkPalette", { path: '/' });
          cookies.remove("customLightPalette", { path: '/' });
          cookies.remove("overrideDefaultLogo", { path: '/' });
          cookies.remove("overrideInAppLogo", { path: '/' });
        }
      }
    } catch (err) {
      logger.error("custom theme logout error", err);
    }
    // NOTE: Amplify DataStore best practice: Clear offline data on sign-out.
    return DataStore.clear()
    .then(
      () => {
        logger.info("DataStore.clear() was successful before logoff.");
      }
    )
    .catch((err) => {
      logger.warn(err);
    }).finally(
      () => {
        return Auth.signOut()
        .then(() => {
          logger.info("signed out");
        })
        .catch((err) => {
          logger.error(err);
          throw err;
        });
      }
    );
  };

  public checkAuth = async (): Promise<void> => {
    return Auth.currentAuthenticatedUser()
    .then(() => {})
    .catch(
      () => {
        throw new Error("Unauthorized");
      }
    );
    /* 
    const session = await Auth.currentSession();

    if (this.authGroups.length === 0) {
      return;
    }

    const userGroups = session.getAccessToken().decodePayload()[
      "cognito:groups"
    ];

    if (!userGroups) {
      throw new Error("Unauthorized");
    }

    for (const group of userGroups) {
      if (this.authGroups.includes(group)) {
        return;
      }
    } */
  };

  public checkError = (): Promise<void> => {
    return Promise.resolve();
  };

  public getPermissions = async (): Promise<string[]> => {
    const session = await Auth.currentSession();

    const groups = session.getAccessToken().decodePayload()["cognito:groups"];

    return groups ? Promise.resolve(groups) : Promise.reject();
  };

  public getIdentity = async (): Promise<UserIdentity> => {
    const user = await Auth.currentAuthenticatedUser() as CognitoUserAmplifyWithAttributes;
    if (user) {
      return getProfile(user.username).then(
        (profile) => {
          return {
            id: user.username,
            fullName: user.attributes.name,
            user,
            profile,
          };
        }
      );
    } else {
      return {
        id: "",
        fullName: "",
        user: null,
        profile: null,
      };
    }
  }
}
