import { defaultTheme } from "react-admin";
import type { RaThemeOptions } from "react-admin";
import { createTheme, makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import type { Theme } from "@material-ui/core";


const customThemeOptions: RaThemeOptions = {
  ...defaultTheme,
  palette: {
    primary: {
      light: "#439764",
      main: "#056839",
      dark: "#003c12",
      contrastText: "#fff",
    },
    secondary: {
      light: "#71e878",
      main: "#38b549",
      dark: "#00841a",
      contrastText: "#000",
    },
  },
  sidebar: {
    width: 96,
    closedWidth: 48,
  },
  overrides: {
    RaSidebar: {
      drawerPaper: {
        width: 96, // 48
      },
      fixed: {
        zIndex: 1200,
      },
    },
  },
};

export const customLightThemeOptions: RaThemeOptions = {
  ...defaultTheme,
  palette: {
    type: "light",
    primary: {
      light: "#439764",
      main: "#056839",
      dark: "#003c12",
      contrastText: "#fff",
    },
    secondary: {
      light: "#71e878",
      main: "#38b549",
      dark: "#00841a",
      contrastText: "#000",
    },
    background: {
      default: "#fcfcfe",
    },
  },
  sidebar: {
    width: 96,
    closedWidth: 48,
  },
  overrides: {
    RaSidebar: {
      drawerPaper: {
        width: 96, // 48
      },
      fixed: {
        zIndex: 1200,
      },
    },
  },
};

export const customDarkThemeOptions: RaThemeOptions = {
  ...defaultTheme,
  palette: {
    type: "dark",
    primary: {
      light: "#71e878",
      main: "#38b549",
      dark: "#00841a",
      contrastText: "#000",
    },
    secondary: {
      light: "#439764",
      main: "#056839",
      dark: "#003c12",
      contrastText: "#fff",
    },
  },
  sidebar: {
    width: 96,
    closedWidth: 48,
  },
  overrides: {
    RaSidebar: {
      drawerPaper: {
        width: 96, // 48
      },
      fixed: {
        zIndex: 1200,
      },
    },
  },
};

export const sellerBuyerStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      verticalAlign: "top",
    },
    productImage: {
      padding: theme.spacing(1),
    },
    boldText: {
      fontWeight: "bolder",
    },
    chip: {
      fontWeight: "bolder",
      textTransform: "uppercase",
    },
    productName: {
      fontWeight: "bolder",
      fontSize: "larger",
      color: theme.palette.primary.main,
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
      height: "100%",
    },
    tableMenuColumn: {
      display: "flex",
      flexDirection: "column",
      flex: "1",
      padding: "15px",
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      textDecorationColor: theme.palette.primary.contrastText,
    },
    tableContentColumn: {
      display: "flex",
      flexDirection: "column",
      flex: "4",
      padding: "15px",
    },
    bigButton: {
      padding: theme.spacing(1),
      fontWeight: "bolder",
      fontSize: "larger",
    },
  }),
);

export const customTheme: Theme = createTheme(customThemeOptions);

export const customLightTheme: Theme = createTheme(customLightThemeOptions);

export const customDarkTheme: Theme = createTheme(customDarkThemeOptions);
