import React, { useCallback, useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ReferenceField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  useRecordContext,
  Link,
  required,
  SelectInput,
  useNotify,
  useRedirect,
  useMutation,
} from "react-admin";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
} from "react-admin";
// @ts-ignore
import { ColorField, ColorInput } from 'react-admin-color-input';
import { Logger } from "@aws-amplify/core";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { CustomList, ListPagination } from "./CustomList";
import TopToolbar from "./TopToolbar";
import { THEME_STATUS_CHOICES, THEME_TYPE_CHOICES, ThemeStatus, ThemeType } from "../constants";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import { OrganizationTheme } from "../API";


const logger = new Logger("OrganizationTheme");
const defaultQuery = "listOrganizationThemes";
const validateRequired = [required()];


export const OrganizationThemeList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
      {...props}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <TextField
          source="organization.name"
          label="Organization name"
          sortable={false}
        />
        <TextField source="title" sortable={false} />
        <TextField source="themeType" sortable={false} />
        <TextField source="status" sortable={false} />
        <DateField showTime={true} source="activationStartDateTime" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const OrganizationThemeShow = (props: ShowProps) => {

  const ShowActions = (props: ShowProps) => {
    const {
      basePath,
      className,
      id,
    } = props;
    const record = useRecordContext();
    if (!record || !id) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        {record.organizationId && (<ShowButton
          component={Link}
          to={{
            pathname: `/organizations/${record.organizationId}/show`,
          }}
          label="Return to organization"
        />)}
        <EditButton basePath={basePath} label="Edit" record={{ id: id as string }} />
      </TopToolbar>
    );
  };

  return (
    <Show
      {...props}
      actions={<ShowActions {...props} />}
    >
      <SimpleShowLayout>
        <TextField source="id" label="ID" fullWidth />
        <TextField source="title" fullWidth />
        <TextField source="themeType" />
        <ColorField source="primaryLightColor" />
        <ColorField source="primaryMainColor" />
        <ColorField source="primaryDarkColor" />
        <ColorField source="primaryContrastTextColor" />
        <ColorField source="secondaryLightColor" />
        <ColorField source="secondaryMainColor" />
        <ColorField source="secondaryDarkColor" />
        <ColorField source="secondaryContrastTextColor" />
        <ColorField source="backgroundColor" />
        <TextField source="status" />
        <DateField showTime={true} source="activationStartDateTime" />
        <DateField showTime={true} source="activationEndDateTime" />
        <ReferenceField
          label="Company"
          source="organizationId"
          reference="organizations"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField showTime={true} source="createdAt" />
        <DateField showTime={true} source="updatedAt" />
      </SimpleShowLayout>
    </Show>
  );
}

export const OrganizationThemeEdit = (props: EditProps) => {

  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate] = useMutation();

  const onSuccess = (onsuccessData: { data: OrganizationTheme }) => {
    const {
      data,
    } = onsuccessData;
    let message = "OrganizationTheme updated";
    notify(message, { type: "success" });
    redirect(`/organizationThemes/${data.id}/show`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: OrganizationTheme) => {
      logger.log("Received update data", data);
      const validFields = [
        'id',
        'title',
        'themeType',
        'primaryLightColor',
        'primaryMainColor',
        'primaryDarkColor',
        'primaryContrastTextColor',
        'secondaryLightColor',
        'secondaryMainColor',
        'secondaryDarkColor',
        'secondaryContrastTextColor',
        'backgroundColor',
        'organizationId',
        'status',
        'activationStartDateTime',
        'activationEndDateTime',
        'owner',
        '_version',
      ];
      const dataKeys = Object.keys(data);
      for (let i = 0; i < dataKeys.length; i=i+1) {
        const dataKey = dataKeys[i];
        if (!validFields.includes(dataKey)) {
          // @ts-ignore
          delete data[dataKey];
          logger.warn(`^ Invalid field "${dataKey}" removed from update data`, data);
        }
      }
      await mutate(
        {
          type: "update",
          resource: "organizationThemes",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  const EditActions = (props: EditProps) => {
    const {
      className,
      basePath,
      id
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/organizations/${record.organizationId}/show`,
          }}
          label="Return to organization"
        />
        <ShowButton
          basePath={basePath}
          label="Show"
          record={{ id: id as string }}
        />
      </TopToolbar>
    );
  };

  return (
    <Edit
      {...props}
      actions={<EditActions {...props} />}
      undoable={false}
      mutationMode="optimistic"
    >
      <SimpleForm
        toolbar={<DeleteWithConfirmToolbar
          mutationMode="optimistic"
          undoable={false}
        />}
      >
        <TextInput source="id" label="ID" disabled />
        <TextInput
          source="title"
          isRequired
          fullWidth
          validate={validateRequired}
        />
       <SelectInput
          source="themeType"
          choices={THEME_TYPE_CHOICES}
          translateChoice={false}
          defaultValue={ThemeType.Light}
          validate={required("Theme type is required.")}
          isRequired
        />
        <ColorInput 
          source="primaryLightColor"
          validate={required("primary light color is required.")}
          isRequired  
        />
        <ColorInput
          source="primaryMainColor"
          validate={required("primary main color is required.")}
          isRequired  
        />
        <ColorInput
          source="primaryDarkColor"
          validate={required("primary dark color is required.")}
          isRequired    
        />
        <ColorInput
          source="primaryContrastTextColor"
          validate={required("primary contrast text color is required.")}
          isRequired  
        />
        <ColorInput
          source="secondaryLightColor"
          validate={required("secondary light color is required.")}
          isRequired    
        />
        <ColorInput
          source="secondaryMainColor"
          validate={required("secondary main color is required.")}
          isRequired    
        />
        <ColorInput
          source="secondaryDarkColor"
          validate={required("secondary dark color is required.")}
          isRequired    
        />
        <ColorInput
          source="secondaryContrastTextColor"
          validate={required("secondary contrast text color is required.")}
          isRequired    
        />
        <ColorInput
          source="backgroundColor"
          validate={required("background color is required.")}
          isRequired    
        />
        <SelectInput
          source="status"
          choices={THEME_STATUS_CHOICES}
          translateChoice={false}
          defaultValue={ThemeStatus.Inactive}
          validate={required("Theme status is required.")}
          isRequired
        />
        <MaterialDateTimeInput
          label="Activation Start Date"
          source="activationStartDateTime"
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
        />
        <MaterialDateTimeInput
          label="Activation End Date"
          source="activationEndDateTime"
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
        />
        <ReferenceInput
          source="organizationId"
          reference="organizations"
          label="Company"
          filterToQuery={(searchText: string) => ({
            listOrganizations: { name: searchText },
          })}
          perPage={2000}
          isRequired
          validate={validateRequired}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}

export const OrganizationThemeCreate = (props: CreateProps) => {

  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate] = useMutation();

  const onSuccess = (onsuccessData: { data: OrganizationTheme }) => {
    const {
      data,
    } = onsuccessData;
    let message = "OrganizationTheme created";
    notify(message, { type: "success" });
    redirect(`/organizations/${data.organizationId}/show`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: OrganizationTheme) => {
      logger.log("Received create data", data);
      const validFields = [
        'title',
        'themeType',
        'primaryLightColor',
        'primaryMainColor',
        'primaryDarkColor',
        'primaryContrastTextColor',
        'secondaryLightColor',
        'secondaryMainColor',
        'secondaryDarkColor',
        'secondaryContrastTextColor',
        'backgroundColor',
        'organizationId',
        'status',
        'activationStartDateTime',
        'activationEndDateTime',
        'owner',
      ];
      const dataKeys = Object.keys(data);
      for (let i = 0; i < dataKeys.length; i=i+1) {
        const dataKey = dataKeys[i];
        if (!validFields.includes(dataKey)) {
          // @ts-ignore
          delete data[dataKey];
          logger.warn(`^ Invalid field "${dataKey}" removed from create data`, data);
        }
      }
      await mutate(
        {
          type: "create",
          resource: "organizationThemes",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  const CreateActions = (props: CreateProps) => {
    const {
      className,
    } = props;
    const record = useRecordContext();
    if (!record || !record.organizationId) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/organizations/${record.organizationId}/show`,
          }}
          label="Return to organization"
        />
      </TopToolbar>
    );
  };

  return (
    <Create
      {...props}
      actions={<CreateActions {...props} />}
    >
      <SimpleForm
        save={save}
      >
      <TextInput
          source="title"
          isRequired
          fullWidth
          validate={validateRequired}
        />
       <SelectInput
          source="themeType"
          choices={THEME_TYPE_CHOICES}
          translateChoice={false}
          defaultChecked={true}
          defaultValue={ThemeType.Light}
          validate={required("Theme type is required.")}
          isRequired
        />
        <ColorInput 
          source="primaryLightColor"
          validate={required("primary light color is required.")}
          isRequired  
        />
        <ColorInput
          source="primaryMainColor"
          validate={required("primary main color is required.")}
          isRequired  
        />
        <ColorInput
          source="primaryDarkColor"
          validate={required("primary dark color is required.")}
          isRequired    
        />
        <ColorInput
          source="primaryContrastTextColor"
          validate={required("primary contrast text color is required.")}
          isRequired  
        />
        <ColorInput
          source="secondaryLightColor"
          validate={required("secondary light color is required.")}
          isRequired    
        />
        <ColorInput
          source="secondaryMainColor"
          validate={required("secondary main color is required.")}
          isRequired    
        />
        <ColorInput
          source="secondaryDarkColor"
          validate={required("secondary dark color is required.")}
          isRequired    
        />
        <ColorInput
          source="secondaryContrastTextColor"
          validate={required("secondary contrast text color is required.")}
          isRequired    
        />
        <ColorInput
          source="backgroundColor"
          validate={required("background color is required.")}
          isRequired    
        />
        <SelectInput
          source="status"
          choices={THEME_STATUS_CHOICES}
          translateChoice={false}
          defaultChecked={true}
          defaultValue={ThemeStatus.Inactive}
          validate={required("Theme status is required.")}
          isRequired
        />
        <MaterialDateTimeInput
          label="Activation Start Date"
          source="activationStartDateTime"
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
        />
        <MaterialDateTimeInput
          label="Activation End Date"
          source="activationEndDateTime"
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
        />
        <ReferenceInput
          source="organizationId"
          reference="organizations"
          label="Company"
          filterToQuery={(searchText: string) => ({
            listOrganizations: { name: searchText },
          })}
          perPage={2000}
          isRequired
          validate={validateRequired}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
