import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Edit,
  NullableBooleanInput,
  RecordContextProvider,
  ReferenceManyField,
  SimpleShowLayout,
  SingleFieldList,
  TextInput,
  useAuthenticated,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
  LinearProgress,
  DateTimeInput,
  SelectInput,
} from "react-admin";
// @ts-ignore
import { CompactForm, RaBox, RaGrid } from "ra-compact-ui";
import { API, Auth, Logger } from "aws-amplify";
import { Storage } from "@aws-amplify/storage";
import {
  Checkbox,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
  Theme,
  Tooltip,
  TableHead,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { DateTime } from "luxon";
import { graphqlOperation, type GraphQLResult } from "@aws-amplify/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import clone from "lodash/clone";
import * as Sentry from "@sentry/react";
// TODO use https://marmelab.com/react-admin/AutoSave.html
// import { AutoSave } from "@react-admin/ra-form-layout";
import MeetingCountdown from "../components/MeetingCountdown";
import { MeetingsStateContext } from "../App";
import { AmplifyImageField } from "../components/AmplifyImageField";
import type {
  ForwardEmail,
  ListMeetingInvitesByProfileIdQuery,
  Meeting,
  MeetingInvite,
  ProductQuestion,
  QuestionResponse,
  Team,
  TeamImage,
  // UpdateQuestionResponseInput,
} from "../API";
import {
  MeetingInvitationType,
  MeetingStatus,
  ModelSortDirection,
} from "../API";
import NoDeleteToolbar from "../components/NoDeleteToolbar";
import type {
  ProductQuestionWithMeetingInvite,
  ISellerFeedbackResponse,
} from "../types";
import {
  AWS_TIMESTAMP_AS_LUXON_FORMAT,
  CONTACT_REQUEST_TIMEFRAME_CHOICES,
  FEEDBACK_SUMMARY_CHOICES,
} from "../constants";
import { listMeetingInvitesByProfileId } from "../graphql/queries";
import { sellerBuyerStyles } from "../layout/theme";
import {
  createFeedbackAsap,
  downloadFileFromS3,
  getCurrentDateTime,
  getMeetingInvitesCount,
  getTeamByMeetingIdAndBuyerOrSeller,
  getTeamImageByTeamId,
  getTranscriptionJobByMeetingInviteId,
  logEvent,
  updateQuestionResponse,
  getMeetingFeedback,
  getForwardEmailsBySellerProfileIdAndProfileId,
  transformQuestionResponse,
  createOrUpdateQuestionResponse,
} from "../lib/helpers";
import ScheduledMeetingsMenu from "../components/ScheduledMeetingsMenu";
import { JobStatus } from "../models";
import { onUpdateTranscriptionJob } from "../graphql/subscriptions";
import { usePreferences } from "@react-admin/ra-preferences";
import BooleanCheckboxInput from "../components/BooleanCheckboxInput";

const logger = new Logger("Feedback");
const ReactSwal = withReactContent(Swal);

/**
 * Buyer and Seller Feedback
 *
 * @returns {JSX.Element}
 */
export const FeedbackPage = () => {
  useAuthenticated(); // redirects to login if not authenticated
  const redirect = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const classes = sellerBuyerStyles();
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ isFormDirty, setIsFormDirty ] = useState<boolean>(false);
  const [ saveTimeout, setSaveTimeout ] = useState<number | NodeJS.Timeout>(0);
  const [ saveCounter, setSaveCounter ] = useState<number>(0);
  const [ questionResponsesData, setQuestionResponsesData ] = useState<Map<string, QuestionResponse>>(new Map());
  const [ transcriptionDisabled, setTranscriptionDisabled ] = useState<Map<string, boolean>>(new Map());
  const [ transcriptionIsReady, setTranscriptionIsReady ] = useState<Map<string, boolean>>(new Map());
  const [ showContactRequestDateTime, setShowContactRequestDateTime ] = useState<Map<string, boolean>>(new Map());
  const [ requestedAsap, setRequestedAsap ] = useState<Map<string, string>>(new Map());
  const [ data, setData ] = useState<ProductQuestionWithMeetingInvite[]>([]);
  const [ page, setPage ] = useState(1);
  const [ totalCount, setTotalCount] = useState(0);
  const [ hasPreviousPage, setHasPreviousPage ] = useState(false);
  const [ hasNextPage, setHasNextPage ] = useState(false);
  const [ tokensByPageNumber, setTokensByPageNumber ] = useState<Map<string, string | null>>(new Map());
  const [ clockSyncEnabled ] = usePreferences("clockSyncEnabled", false);
  const [ cachedServerTimeDifference ] = usePreferences("cachedServerTimeDifference", 0);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const meetingsState = useContext(MeetingsStateContext);
  const {
    meetingInvitesState,
    nextMeetingInvite,
    isSeller,
    isBuyer,
    currentProfile,
    userAgentData,
    defaultLogo,
    inAppLogo,
    meetingDataIsLoaded,
  } = meetingsState;
  const scheduledMeetingInvites = meetingInvitesState.scheduledMeetings;
  const getNextPage = () => {
    setIsLoaded(false);
    setPage((page + 1) > totalCount ? 1 : page + 1);
  }
  const getPreviousPage = () => {
    setIsLoaded(false);
    setPage((page - 1) < 1 || (page - 1) > totalCount ? 1 : page - 1);
  }
  const nextPageNumber = () => `${page + 1}`;

  useEffect(() => {
    if (currentProfile) {
      const now = getCurrentDateTime(
        cachedServerTimeDifference,
        clockSyncEnabled,
      ).toUTC();
      const daysAgo = now.minus({ days: 5 });
      const startDateTimeRange = daysAgo.toFormat(
        AWS_TIMESTAMP_AS_LUXON_FORMAT
      );
      const endDateTimeRange = now.toFormat(
        AWS_TIMESTAMP_AS_LUXON_FORMAT
      );
      logger.info(
        "Feedback called...",
        { startDateTimeRange, endDateTimeRange, profileId: currentProfile.id, status: MeetingStatus.ENDED },
      );
      getMeetingInvitesCount(
        MeetingStatus.ENDED,
        startDateTimeRange,
        endDateTimeRange,
        currentProfile.id,
      ).then(
        (countResult) => {
          logger.info("getMeetingInvitesCount result", countResult);
          setTotalCount(countResult.count);
          if (countResult.count === 0) {
            logger.info("getMeetingInvitesCount redirecting to /.");
            redirect("/");
          }
        }
      ).catch(
        (err: Error) => {
          logger.error(
            "getMeetingInvitesCount error",
            err,
          );
        }
      );
    }
  }, [currentProfile, clockSyncEnabled, cachedServerTimeDifference ]);

  useEffect(() => { // NOTE: new query for meeting and questions response (notes)
    if (currentProfile && totalCount > 0) {
      const teamImagesMap = new Map();
      const now = getCurrentDateTime(
        cachedServerTimeDifference,
        clockSyncEnabled,
      ).toUTC();
      const daysAgo = now.minus({ days: 5 });
      const startDateTimeRange = daysAgo.toFormat(
        AWS_TIMESTAMP_AS_LUXON_FORMAT
      );
      const endDateTimeRange = now.toFormat(
        AWS_TIMESTAMP_AS_LUXON_FORMAT
      );
      const nextToken = tokensByPageNumber.get(`${page}`);
      logger.info('pagination - page', { page, startDateTimeRange, endDateTimeRange, nextToken, currentProfileId: currentProfile.id });
      logger.info(`pagination - token for page ${page}`, nextToken);
      (API.graphql({
        query: listMeetingInvitesByProfileId,
        variables: {
          limit: 1,
          profileId: currentProfile.id,
          startDateTime: {
            between: [
              startDateTimeRange,
              endDateTimeRange,
            ],
          },
          filter: {
            status: {
              eq: MeetingStatus.ENDED,
            }
          },
          sortDirection: ModelSortDirection.ASC,
          ...(nextToken && {
            nextToken,
          }),
        },
      }) as Promise<GraphQLResult>).then(
        async (queryResult) => {
          if (queryResult.errors || !queryResult.data) {
            logger.error("queryResult", queryResult);
            throw new Error("Data provider error");
          }
          let numberOfResults = 0;
          let currentNextToken: null | string = null;
          if (page === 1) {
            if (hasPreviousPage) {
              setHasPreviousPage(false);
            }
          } else {
            if (!hasPreviousPage) {
              setHasPreviousPage(true);
            }
          }
          if (hasNextPage && (totalCount === page || page > totalCount)) {
            setHasNextPage(false);
          }
          const listMeetingInvitesByProfileIdResults = (queryResult.data as ListMeetingInvitesByProfileIdQuery).listMeetingInvitesByProfileId;
          logger.info("listMeetingInvitesByProfileIdResults", listMeetingInvitesByProfileIdResults);
          if (listMeetingInvitesByProfileIdResults && listMeetingInvitesByProfileIdResults.items) {
            if (listMeetingInvitesByProfileIdResults.nextToken) {
              currentNextToken = listMeetingInvitesByProfileIdResults.nextToken;
              const nextPageToken = tokensByPageNumber.get(nextPageNumber());
              if (!nextPageToken) {
                const updatedTokensByPageNumber = new Map(tokensByPageNumber);
                updatedTokensByPageNumber.set(
                  nextPageNumber(),
                  currentNextToken,
                );
                setTokensByPageNumber(updatedTokensByPageNumber);
              }
              if (!hasNextPage && (page < totalCount)) {
                setHasNextPage(true);
              }
            }
            const productQuestions: ProductQuestionWithMeetingInvite[] = [];
            numberOfResults = listMeetingInvitesByProfileIdResults.items.length;
            logger.info(`numberOfResults = ${numberOfResults}`);
            for (let i = 0; i < listMeetingInvitesByProfileIdResults.items.length; i=i+1) {
              let attendingProfileIds: string[] = [];
              const meetingInvite = listMeetingInvitesByProfileIdResults.items[i] as MeetingInvite;
              logger.info("listMeetingInvitesByProfileIdResults meetingInvite", meetingInvite);
              const meeting = await dataProvider.getOne(
                "meetings",
                {
                  id: meetingInvite.meetingId,
                },
              ).then(
                (m) => {
                  logger.info("meeting results.", m);
                  if (m && m.data) {
                    const meetingResults = m.data as Meeting;
                    logger.info("meetingResults", meetingResults);
                    return meetingResults;
                  }
                  return null;
                }
              ).catch(
                (err) => {
                  logger.error("dataProvider.getOne err", err);
                  return null;
                }
              );

              const {
                productId,
                feedbackSummary,
              } = meetingInvite;
              if (!productId) {
                continue;
              }
              if (meeting && meeting.meetingAttendees) {
                attendingProfileIds = meeting.meetingAttendees.items.map(
                  (meetingAttendee) => meetingAttendee?.profileId || '',
                  [],
                );
              } else {
                logger.info('meeting.meetingAttendees is empty');
              }
              logger.info('attendingProfileIds', { attendingProfileIds });
              logger.info(`feedbackSummary ( ${meetingInvite.id} ) = ${feedbackSummary}`);
              let attendeeFeedback: ISellerFeedbackResponse[] = [];
              const buyerPrivateEmails: { name: string, email: string }[] = [];
              await getMeetingFeedback(meetingInvite.meetingId).then(
                (sellerFeedbackResponses) => {
                  logger.info("++ getMeetingFeedback sellerFeedbackResponses", sellerFeedbackResponses);
                  if (attendingProfileIds && attendingProfileIds.length > 0) {
                    attendeeFeedback = sellerFeedbackResponses.filter(
                      (feedbackResponse) => attendingProfileIds.includes(feedbackResponse.profileId)
                    );
                    /* for (let k = 0; k < attendeeFeedback.length; k++) {
                      const feedback = attendeeFeedback[k];
                      if (feedback.providePersonalEmail) {
                        buyerPrivateEmails.push(
                          {
                            name: feedback.contactName || feedback.contactEmail,
                            email: feedback.contactEmail,
                          }
                        );
                      }
                    } */
                  } else {
                    attendeeFeedback = sellerFeedbackResponses;
                  }
                  logger.info("++ getMeetingFeedback attendeeFeedback ", attendeeFeedback);
                }
              ).catch(
                (err: Error) => {
                  logger.error("getMeetingFeedback error querying getMeetingFeedback", err);
                }
              );
              // TODO this is a temporary hack
              /* if (
                [
                  'not_too_interested',
                  'not_at_all_interested',
                ].includes(feedbackSummary || '')
              ) {
                logger.info(`skipping ${meetingInvite.id}`);
                continue;
              } */
              logger.info("++ meetingInvite", meetingInvite);
              await (dataProvider.getList(
                "productQuestions",
                {
                  pagination: { page: 1, perPage: 1 },
                  sort: { field: "listProductQuestionsByProductId", order: "" },
                  filter: {
                    listProductQuestionsByProductId: { // the name of the index
                      productId,
                    },
                  },
                },
              ).then(
                async (listOfProductQuestions) => {
                  logger.info("++ listOfProductQuestions.data", listOfProductQuestions.data);
                  if (listOfProductQuestions.data && listOfProductQuestions.data.length > 0) {
                    let productQuestion = listOfProductQuestions.data[0] as ProductQuestion;
                    await dataProvider.getList(
                      "questionResponses",
                      {
                        pagination: { page: 1, perPage: 1 },
                        sort: { field: "listQuestionResponsesByMeetingInviteId", order: "" },
                        filter: {
                          listQuestionResponsesByMeetingInviteId: { // the name of the index
                            meetingInviteId: meetingInvite.id,
                          },
                        },
                      },
                    ).then(
                      async (listOfQuestionResponses) => {
                        let questionResponses: QuestionResponse[] = [];
                        logger.info("++ listOfQuestionResponses.data", listOfQuestionResponses.data);
                        if (listOfQuestionResponses.data && listOfQuestionResponses.data.length > 0) {
                          questionResponses = listOfQuestionResponses.data as QuestionResponse[];
                        } else if (!listOfQuestionResponses.data || listOfQuestionResponses.data.length === 0) {
                          logger.info("No question responses found for meeting invite", meetingInvite.id);
                          logger.info("creating am empty question response");
                          // NOTE: Store an empty product question response
                          //       This is the "notes" feature for buyers and sellers
                          await createOrUpdateQuestionResponse(
                            dataProvider,
                            meetingInvite,
                            "",
                            false,
                          ).then(
                            (updatedQuestionResponse) => {
                              logger.info("createOrUpdateQuestionResponse", updatedQuestionResponse);
                              if (updatedQuestionResponse) {
                                questionResponses = [ updatedQuestionResponse as QuestionResponse ];
                              }
                            }
                          ).catch(
                            (err) => {
                              logger.error("createOrUpdateQuestionResponse error", err);
                              Sentry.captureException(err);
                            }
                          );
                        }
                        productQuestion = {
                          ...productQuestion,
                          ...{
                            responses: {
                              __typename: "ModelQuestionResponseConnection",
                              items: questionResponses,
                            }
                          }
                        };
                        let buyerTeamId: string | null | undefined = null;
                        let buyerTeam: Team | null | undefined = null;
                        let buyerTeamImage: TeamImage | null | undefined = null;
                        let buyerForwardEmail: ForwardEmail | null | undefined = null;
                        if (meetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER) {
                          buyerTeam = await getTeamByMeetingIdAndBuyerOrSeller(
                            meetingInvite.meetingId,
                            "buyer",
                          ).then(
                            (team) => {
                              return team;
                            }
                          ).catch(
                            (err: any) => {
                              logger.error("Error getting team", err);
                              return null;
                            }
                          );
                          logger.info("buyerTeam", buyerTeam);
                          if (buyerTeam && !buyerTeam.privacyEnabled) {
                            buyerTeamId = buyerTeam.id;
                            buyerTeamImage = teamImagesMap.get(buyerTeam.id);
                            if (!buyerTeamImage) {
                              buyerTeamImage = await getTeamImageByTeamId(buyerTeam.id, dataProvider);
                              if (buyerTeamImage) {
                                teamImagesMap.set(buyerTeam.id, buyerTeamImage);
                              }
                            }
                            logger.info("buyerTeamImage", buyerTeamImage);
                            if (attendingProfileIds) {
                              // TODO get share email response for all buyers attendees
                              for (let i = 0; i < attendingProfileIds.length; i=i+1) {
                                const attendingProfileId = attendingProfileIds[i];
                                try {
                                  const forwardEmails = await getForwardEmailsBySellerProfileIdAndProfileId(currentProfile.id, attendingProfileId);
                                  if (forwardEmails.length > 0) {
                                    buyerForwardEmail = forwardEmails.pop();
                                    break;
                                  }
                                } catch(err) {
                                  logger.error("buyerForwardEmail error", err);
                                  Sentry.captureException(err);
                                }
                              }
                            }
                            logger.info("buyerTeamImage", buyerTeamImage);
                          }
                        }
                        if (
                          meetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE ||
                          (
                            meetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER
                          )
                        ) {
                          logger.info("++ buyerPrivateEmails", buyerPrivateEmails);
                          const productQuestionWithMeetingInvite: ProductQuestionWithMeetingInvite = {
                            ...productQuestion,
                            ...{
                              meetingInvite,
                              buyerTeamId,
                              buyerTeam,
                              buyerTeamImage,
                              buyerForwardEmail,
                              attendeeFeedback,
                              buyerPrivateEmails,
                            }
                          };
                          productQuestions.push(productQuestionWithMeetingInvite);
                        }
                      }
                    ).catch(
                      (err: Error) => {
                        logger.error("error querying QuestionResponses", err);
                      }
                    );
                  }
                }
              ).catch(
                (err: Error) => {
                  logger.error("error querying ProductQuestions", err);
                }
              ));
            }
            setData(productQuestions);
            if (!isLoaded) {
              setIsLoaded(true);
            }
            if (productQuestions.length === 0) {
              // setIsNoQuestions(true);
              logger.info("productQuestions.length is zero");
              redirect("/");
            }
          } else {
            logger.info("listMeetingInvitesByProfileIdResults is zero");
            redirect("/");
          }
          if ((numberOfResults === 0 || !currentNextToken)) {
            // setNextToken(null);
            logger.info('pagination - numberOfResults', numberOfResults);
            logger.info('pagination - currentNextToken', currentNextToken);
            setHasNextPage(false);
          }
        }
      ).catch(
        (err: Error) => {
          logger.error("error querying listOfMeetingInvites", err);
        }
      );
    }
  }, [currentProfile, page, totalCount, saveCounter, clockSyncEnabled, cachedServerTimeDifference]);

  const onFailure = async (error: any) => {
    logger.error("onFailure.error", error);
    const errorMessage = "An error occurred";
    notify(errorMessage, { type: "error" });
    setIsFormDirty(false);
    setSaveCounter(saveCounter + 1);
    refresh();
    if (currentProfile) {
      await logEvent(
        currentProfile,
        "failed tp save notes on break page",
        {
          /* meeting: {
            id: data.meetingId,
            invite_id: data.meetingInviteId,
          }, */
          details: error,
        },
        'warn',
        cachedServerTimeDifference,
        userAgentData,
      );
    }
  };

  const onSuccess = async (onSuccessResponse: any) => {
    const {
      data,
    } = onSuccessResponse;
    logger.info("onSuccess response", onSuccessResponse);
    logger.info("onSuccess data", data);
    const successMessage = "Saved";
    notify(successMessage, { type: "success" });
    setIsFormDirty(false);
    setSaveCounter(saveCounter + 1);
    const {
      id,
      providePersonalEmail,
      providePersonalPhoneNumber,
      textOnly,
      followupMeetingRequested,
      contactRequestDateTime,
      response,
      privateNotes,
      _version,
    } = data;
    logger.info("onSuccess _version", _version);
    const responseData = {
      id,
      providePersonalEmail,
      providePersonalPhoneNumber,
      textOnly,
      followupMeetingRequested,
      contactRequestDateTime,
      response,
      privateNotes,
      _version,
    };
    logger.info("onSuccess responseData", responseData);
    logger.info("questionResponsesData", questionResponsesData);
    const updatedQuestionResponsesData = clone(questionResponsesData);
    logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
    updatedQuestionResponsesData.set(
      id, // @ts-ignore
      responseData,
    );
    setQuestionResponsesData(updatedQuestionResponsesData);
    // refresh();
    if (currentProfile) {
      await logEvent(
        currentProfile,
        "notes saved on break page",
        {
          meeting: {
            id: data.meetingId,
            invite_id: data.meetingInviteId,
          },
          details: data,
        },
        'info',
        cachedServerTimeDifference,
        userAgentData,
      );
    }
  };

  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (responseData: QuestionResponse) => {
      const data = transformQuestionResponse(responseData);
      logger.info("save data", data);
      await updateQuestionResponse(data, responseData.id).then(
        (saveResponse) => {
          logger.info("save useCallback successful", saveResponse);
          onSuccess({ data: responseData });
        }
      ).catch(
        (err) => {
          logger.error("save useCallback failed", err);
          onFailure(err);
        }
      );
      /* await mutate(
        {
          type: "update",
          resource: "questionResponses",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess,
          onFailure,
        }
      ); */
    },
    [],
  );

  const downloadTranscriptionOrCreateTranscriptionJob = async (
    meetingInviteId: string,
  ) => {
    logger.info("got meetingInviteId", meetingInviteId);
    await getTranscriptionJobByMeetingInviteId(
      meetingInviteId,
      dataProvider,
    ).then(
      async (transcriptionJob) => {
        if (!transcriptionJob && currentProfile) {
          await Auth.currentUserCredentials().then(
            async (creds) => {
              logger.info("creds", creds);
              const nowFormatted = DateTime.utc().toFormat(AWS_TIMESTAMP_AS_LUXON_FORMAT);
              const input = {
                date: nowFormatted,
                status: JobStatus.PROCESSING,
                meetingInviteId,
                owner: currentProfile.userId,
                identityId: creds.identityId,
              };
              logger.info("TranscriptionJob input", input);
              await dataProvider.create(
                "transcriptionJobs",
                {
                  data: input,
                }
              )
              .then(
                (result) => {
                  logger.debug("TranscriptionJob creation result", result);
                  const updatedTranscriptionDisabled = new Map(transcriptionDisabled);
                  updatedTranscriptionDisabled.set(meetingInviteId, true);
                  setTranscriptionDisabled(updatedTranscriptionDisabled);
                  ReactSwal.fire(
                    {
                      // backdrop: false,
                      // returnFocus: false,
                      title: 'Please wait...',
                      icon: 'info',
                      showCloseButton: true,
                      timer: 1000 * 120,
                      timerProgressBar: false,
                      didOpen: () => {
                        ReactSwal.showLoading();
                      },
                      confirmButtonColor: "#056839",
                      cancelButtonColor: "#39B54A",
                    }
                  ).then((result) => {
                    logger.info("result.dismiss", result.dismiss);
                    if (result.dismiss === Swal.DismissReason.timer) {
                      logger.warn("Dialog dismissed by timer");
                    }
                  });
                }
              ).catch(
                (err) => {
                  logger.error("TranscriptionJob creation error", err);
                  notify(err.message, { type: "warning" });
                }
              );
            }
          )
        } else if (transcriptionJob)  {
          const { result } = transcriptionJob;
          logger.info("transcriptionJob result", result);
          if (result) {
            const {
              key,
            } = result;
            logger.info("transcriptionJob key", key);
            if (key) {
              downloadFileFromS3(key, Storage, "public");
              const updatedTranscriptionIsReady = new Map(transcriptionIsReady);
              updatedTranscriptionIsReady.set(meetingInviteId, true);
              setTranscriptionIsReady(updatedTranscriptionIsReady);
            } else {
              notify("Transcription is not available.", { type: "warning" });
            }
            const updatedTranscriptionDisabled = new Map(transcriptionDisabled);
            updatedTranscriptionDisabled.set(meetingInviteId, false);
            setTranscriptionDisabled(updatedTranscriptionDisabled);
            try {
              // NOTE: cancel alert
              ReactSwal.close();
            } catch(err: any) { }
          }
        }
      }
    ).catch(
      (err) => {
        logger.error('getTranscriptionJobByMeetingInviteId error', err);
        notify(err.message, { type: "warning" });
      }
    );
  };

  useEffect(
    () => {
      if (currentProfile?.userId) {
        const subscription = API.graphql(
          graphqlOperation(onUpdateTranscriptionJob, { owner: currentProfile?.userId })
        ).subscribe({
          next: ({ value }: any) => {
            logger.info("value.data.onUpdateTranscriptionJob", value.data.onUpdateTranscriptionJob);
            logger.info("value.data.onUpdateTranscriptionJob.id", value.data.onUpdateTranscriptionJob.id);
            const {
              id,
              meetingInviteId,
              result,
              status,
            } = value.data.onUpdateTranscriptionJob;
            logger.info("job id", id);
            logger.info("meetingInviteId", meetingInviteId);
            logger.info("result", result);
            logger.info("status", status);
            try {
              // NOTE: cancel alert
              ReactSwal.close();
            } catch(err: any) { }
            if (result && status === JobStatus.COMPLETED) {
              // notify("Job is complete.", { type: "success" });
              const {
                key,
              } = result;
              logger.info("key", key);
              if (key) {
                // NOTE: auto download will not work in some browsers
                // downloadFileFromS3(key, Storage, "public");
                ReactSwal.fire(
                  {
                    title: 'Transcription',
                    text: 'Complete!',
                    icon: 'success',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, download!',
                    confirmButtonColor: "#056839",
                    cancelButtonColor: "#39B54A",
                  }
                ).then((result) => {
                  if (result.isConfirmed) {
                    downloadFileFromS3(key, Storage, "public");
                  }
                });
                const updatedTranscriptionIsReady = new Map(transcriptionIsReady);
                updatedTranscriptionIsReady.set(meetingInviteId, true);
                setTranscriptionIsReady(updatedTranscriptionIsReady);
              } else {
                notify("Transcription is not available.", { type: "warning" });
              }
            } else {
              logger.info("status is not completed", status);
              notify(`Job status is ${status}`, { type: "warning" });
            }
            const updatedTranscriptionDisabled = new Map(transcriptionDisabled);
            updatedTranscriptionDisabled.set(meetingInviteId, false);
            setTranscriptionDisabled(updatedTranscriptionDisabled);
            dataProvider.publish(
              "resource/transcriptionJobs",
              {
                type: "updated",
                topic: "resource/transcriptionJobs",
                payload: { ids: [ value.data.onUpdateTranscriptionJob.id ]},
                date: new Date(),
              },
            );
          },
          error: (err: Error) => {
            logger.warn("onUpdateTranscriptionJob failed", err);
            notify(err.message, { type: "warning" });
          }
        });
        return () => {
          subscription.unsubscribe();
        }
      }
    },
    [ currentProfile?.userId ]
  );

  const followupMeetingRequestedValidation = (value: any, allValues: any, testValue: any) => {
    // logger.info('testValue', testValue);
    // logger.info('allValues.id', allValues.id);
    // logger.info('value', value);
    // const currentshowContactRequestDateTime = showContactRequestDateTime.get(allValues.id);
    const updatedShowContactRequestDateTime = new Map(showContactRequestDateTime);
    updatedShowContactRequestDateTime.set(allValues.id, value);
    setShowContactRequestDateTime(updatedShowContactRequestDateTime);
    return undefined;
  };
  return (
    <>
      <Grid
        container
        spacing={3}
        direction={isSmall ? "column" : "row"}
      >
        {!isSmall && (
        <Grid item md={8}>
          <img src={inAppLogo ? inAppLogo.url : (defaultLogo ? defaultLogo.url : "/img/logo-connect.png")} height={isSmall ? "55" : "110"} />
        </Grid>
        )}
        <Grid item xs={12} sm={12} md={4} alignContent={isSmall ? undefined : "flex-end"}>
          {(nextMeetingInvite && currentProfile) ?
          <MeetingCountdown
            {...meetingsState}
            nextMeetingInvite={nextMeetingInvite}
            currentProfile={currentProfile}
            useEndDateTime={false}
            clockSyncEnabled={clockSyncEnabled}
            cachedServerTimeDifference={cachedServerTimeDifference}
            inAppLogo={inAppLogo}
            defaultLogo={defaultLogo}
          /> : null
          }
        </Grid>
      </Grid>
      <div className={classes.tableRow}>
      {!isSmall && (
        <div className={classes.tableMenuColumn}>
          <ScheduledMeetingsMenu
            title={"Upcoming meetings"}
            meetings={scheduledMeetingInvites}
            isSeller={isSeller}
            isBuyer={isBuyer}
            showBackToMeetingsLink={true}
            clockSyncEnabled={clockSyncEnabled}
            cachedServerTimeDifference={cachedServerTimeDifference}
          />
        </div>
      )}
        <div className={classes.tableContentColumn}>
        {(isLoaded && meetingDataIsLoaded) ? (
          <>
          <Grid
            container
            spacing={isSmall ? 5 : 10}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={12} sm={12}>
            {(isSeller && false) && (
              <Typography variant={isSmall ? undefined : "h5"} className={classes.boldText}>Please navigate to "View Physician Profiles" (see top of green column on the left) and complete all of your notes to surgeons by 7 am eastern time tomorrow. Thank you for joining us! Please share any suggestions for improvement and mark your calendar for our next event on January 30-31.</Typography>
            )}
            {(isBuyer && hasNextPage) && (
              <Typography variant={isSmall ? undefined : "h5"} className={classes.boldText}>Almost done --- Please provide follow-up instructions for these products.</Typography>
            )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={isSmall ? 5 : 10}
            direction={isSmall ? "column" : "row"}
          >
            <Grid
              item
              xs={12}
              sm={12}
            >
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                    <TableRow key="nav-2">
                      <TableCell>
                        <Button
                          size={isSmall ? "small" : "large"}
                          onClick={() => getPreviousPage() }
                          label="Previous"
                          endIcon={<NavigateBeforeIcon />}
                          disabled={isFormDirty || !hasPreviousPage}
                        >
                          <NavigateBeforeIcon />
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {(totalCount) ? (
                          <Typography variant={isSmall ? undefined : "h4"} className={classes.boldText}>Page {page} of {totalCount}</Typography>
                        ) : (
                          <Typography variant={isSmall ? undefined : "h4"} className={classes.boldText}>&nbsp;</Typography>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          size={isSmall ? "small" : "large"}
                          onClick={() => getNextPage() }
                          label="Next"
                          endIcon={<NavigateNextIcon />}
                          disabled={isFormDirty || !hasNextPage}
                        >
                          <NavigateNextIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={12}>
              <TableContainer component={Paper}>
              {(isLoaded && meetingDataIsLoaded) && data.map((row, i) => (
                <Table size="small">
                  {row.responses && row.responses.items && row.responses.items.length > 0 && (
                  <TableBody>
                    {row.meetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE && isSmall && (
                    <>
                    <TableRow key={`${row.id}-${i}-1_5`}>
                      <TableCell className={classes.tableCell}>
                        <Typography className={classes.productName}>
                        {row.product.name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    </>
                    )}
                    {row.meetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE && !isSmall && (
                    <TableRow key={`${row.id}-${i}-1`}>
                      <TableCell>
                        <RecordContextProvider value={row.product}>
                          <SimpleShowLayout>
                            <ReferenceManyField
                              reference="productImages"
                              target="productId"
                              perPage={10}
                              label=""
                              filter={{ listProductImagesByProductId: {} }}
                            >
                              <SingleFieldList key={`${row.id}-${i}-img`}>
                                <AmplifyImageField
                                  source="image"
                                  title="title"
                                  label=""
                                />
                              </SingleFieldList>
                            </ReferenceManyField>
                          </SimpleShowLayout>
                        </RecordContextProvider>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography className={classes.productName}>
                        {row.product.name}
                        </Typography>
                        <Typography>
                        {row.product.description}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    )}
                   {row.meetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER && (
                    <SimpleShowLayout>
                      <TableRow key={`${row.id}-${i}-1`}>
                        {!isSmall && (
                        <TableCell>
                          {row.buyerTeamImage && (
                            <AmplifyImageField
                              source="image"
                              title="title"
                              label=""
                              resource="teamImages"
                              basePath="/teamImages"
                              record={row.buyerTeamImage}
                            />
                          )}
                        </TableCell>
                        )}
                        <TableCell className={classes.tableCell}  >
                          {row.buyerTeam && !row.buyerTeam.privacyEnabled && (
                            <Typography className={classes.productName}>
                            {row.buyerTeam.name}&nbsp;
                            {row.buyerForwardEmail && (
                              <a href={`mailto:${row.buyerForwardEmail.publicEmail}`}>{row.buyerForwardEmail.publicEmail}</a>
                            )}
                            </Typography>
                          )}
                          {!isSmall && row.buyerTeam && !row.buyerTeam.privacyEnabled && (
                            <Typography>
                            {row.buyerTeam.description}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                        {row.buyerPrivateEmails.map(
                          (buyerPrivateEmail, j) => (
                      <TableRow key={`${row.id}-${i}-${j}-1`}>
                        {!isSmall && (
                        <TableCell>
                          <Typography className={classes.tableCell}>
                            {buyerPrivateEmail.name}
                          </Typography>
                        </TableCell>
                        )}
                        <TableCell className={classes.tableCell}  >
                          <Typography className={classes.productName}>
                            <a href={`mailto:${buyerPrivateEmail.email}`}>{isSmall ? buyerPrivateEmail.name : buyerPrivateEmail.email}</a>
                          </Typography>
                        </TableCell>
                      </TableRow>
                        )
                      )}
                    </SimpleShowLayout>
                    )}
                    <TableRow key={`${row.id}-${i}-2`}>
                      <TableCell className={classes.tableCell} colSpan={2}>
                      {(row.meetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER) ? (
                        <>
                          <Chip label="Notes" color="primary" className={classes.chip} />
                          {row.meetingInvite.transcriptionEnabled &&
                           !row.meetingInvite.privacyEnabled &&
                           !transcriptionIsReady.get(row.meetingInvite.id) && (
                            <Button
                              label="Transcript"
                              size={isSmall ? "small" : "large"}
                              type="button"
                              disabled={transcriptionDisabled.get(row.meetingInvite.id) === true}
                              color="primary"
                              onClick={() => {
                                downloadTranscriptionOrCreateTranscriptionJob(
                                  row.meetingInvite.id
                                )
                              }}
                            />
                          )}
                          {row.meetingInvite.transcriptionEnabled &&
                           !row.meetingInvite.privacyEnabled &&
                           transcriptionIsReady.get(row.meetingInvite.id) && (
                            <Button
                              label="Download the Transcription"
                              size={isSmall ? "small" : "large"}
                              type="button"
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                downloadTranscriptionOrCreateTranscriptionJob(
                                  row.meetingInvite.id
                                )
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <Typography></Typography>
                      )}
                      {(row.meetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE && false) && (
                        <Grid
                          container
                          direction={isSmall ? "column" : "row"}
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant={isSmall ? undefined : "h5"} className={classes.boldText}
                              align="center"
                            >
                              Vendors automatically receive your basic contact info.<br/>Click below to grant this vendor better access for follow-up:
                            </Typography>

                          </Grid>
                        </Grid>
                      )}
                      {row.meetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE && (
                        <Edit // @ts-ignore
                          id={row.meetingInvite.id} // @ts-ignore
                          record={row.meetingInvite}
                          resource="meetingInvites"
                          basePath="/meetingInvites"
                          title={<></>}
                          mutationMode="optimistic"
                          undoable={false}
                          redirect={false}
                        >
                          <CompactForm
                            toolbar={null}
                            layoutComponents={[RaBox, RaGrid]}
                            save={save}
                            redirect={false}
                            warnWhenUnsavedChanges
                            resetOptions={{"keepDirtyValues": true}}
                            resetoptions={{"keepDirtyValues": true}}
                          >
                            <Grid
                              container
                              direction={isSmall ? "column" : "row"}
                              alignItems="stretch"
                              justifyContent="center"
                            >
                              <Grid item xs>
                                <Typography
                                  variant={isSmall ? undefined : "h5"} className={classes.boldText}
                                  align={isSmall ? "center" : "right"}
                                >
                                  Make or edit your selections:&nbsp;&nbsp;
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <SelectInput
                                  size="small"
                                  compact
                                  source="feedbackSummary"
                                  label="Interest Level"
                                  choices={FEEDBACK_SUMMARY_CHOICES}
                                  allowEmpty={true}
                                  resettable={true}
                                  translateChoice={false}
                                  value={row.meetingInvite.feedbackSummary}
                                  onChange={
                                    (selectedValue) => {
                                      let newFeedbackSummary = "";
                                      if (selectedValue && selectedValue.target) {
                                        logger.info(
                                          "feedbackSummary selectedValue", selectedValue
                                        );
                                        newFeedbackSummary = `${selectedValue.target.value}`;
                                      }
                                      logger.info(
                                        "newFeedbackSummary", newFeedbackSummary
                                      );
                                      if (row.meetingInvite) {
                                        dataProvider.getOne(
                                          "meetingInvites",
                                          {
                                            id: row.meetingInvite.id,
                                          },
                                        ).then(
                                          (m) => {
                                            logger.info("meetingInvite results.", m);
                                            if (m && m.data && m.data.owner === currentProfile?.userId) {
                                              const currentMeetingInvite = m.data as MeetingInvite;
                                              if (
                                                currentMeetingInvite.feedbackSummary !== newFeedbackSummary
                                              ) {
                                                const updatedMeetingInvite = JSON.parse(JSON.stringify(currentMeetingInvite));
                                                updatedMeetingInvite.feedbackSummary = newFeedbackSummary;
                                                logger.info(`setting feedbackSummary for meetingInvite ${row.meetingInvite.id}`, updatedMeetingInvite);
                                                // NOTE: update MeetingInvite and set feedbackSummary
                                                
                                                /* let  updateMeetingBreakRestInput: UpdateMeetingBreakRestInput  = { feedbackSummary: updatedMeetingInvite.feedbackSummary };
                                                logger.info("updateMeetingBreakRestInput 1", updateMeetingBreakRestInput);
                                                updateMeetingBreak(updateMeetingBreakRestInput, updatedMeetingInvite.id).then(
                                                  (meetingBreakUpdateResults) => {
                                                    logger.info("meetingBreakUpdateResults", meetingBreakUpdateResults);
                                                    notify("Saved", { type: "success" });
                                                  }
                                                ).catch(
                                                  (err) => {
                                                    logger.error("failed to update meetingInvite", err);
                                                    notify(err.message, { type: "warning" });
                                                  }
                                                ); */
  
                                                dataProvider.update(
                                                  "meetingInvites",
                                                  {
                                                    id: updatedMeetingInvite.id,
                                                    data: {
                                                      id: updatedMeetingInvite.id,
                                                      feedbackSummary: updatedMeetingInvite.feedbackSummary,
                                                      _version: updatedMeetingInvite._version
                                                    },
                                                    previousData: {
                                                      id: currentMeetingInvite.id,
                                                      feedbackSummary: currentMeetingInvite.feedbackSummary,
                                                    },
                                                  }
                                                ).then(
                                                  (meetingInviteUpdateResults) => {
                                                    logger.info("meetingInviteUpdateResults", meetingInviteUpdateResults);
                                                    notify("Saved", { type: "success" });
                                                  }
                                                ).catch(
                                                  (err) => {
                                                    logger.error("failed to update meetingInvite", err);
                                                    notify(err.message, { type: "warning" });
                                                  }
                                                );
                                                // setMeetingInvite(updatedMeetingInvite);
                                                refresh();
                                                logger.info("updatedMeetingInvite", updatedMeetingInvite);
                                              } else {
                                                logger.info("meetingInvite.feedbackSummary is unchanged.")
                                              }
                                            }
                                          }
                                        );
                                      }
                                    }
                                  }
                                />
                              </Grid>
                              <Grid item xs>
                                <SelectInput
                                  size="small"
                                  compact
                                  source="contactRequestTimeframe"
                                  label="Time frame"
                                  choices={CONTACT_REQUEST_TIMEFRAME_CHOICES}
                                  allowEmpty={true}
                                  resettable={true}
                                  translateChoice={false}
                                  onChange={
                                    async (selectedValue) => {
                                      logger.info(
                                        "contactRequestTimeframe selectedValue", selectedValue
                                      );
                                      let newContactRequestTimeframe = "";
                                      if (selectedValue && selectedValue.target) {
                                        newContactRequestTimeframe = `${selectedValue.target.value}`;
                                      }
                                      logger.info(
                                        "newContactRequestTimeframe", newContactRequestTimeframe
                                      );
                                      if (row.meetingInvite) {
                                        await dataProvider.getOne(
                                          "meetingInvites",
                                          {
                                            id: row.meetingInvite.id,
                                          },
                                        ).then(
                                          async (m) => {
                                            logger.info("meetingInvite results.", m);
                                            if (m && m.data && m.data.owner === currentProfile?.userId) {
                                              const currentMeetingInvite = m.data as MeetingInvite;
                                              if (
                                                currentMeetingInvite.contactRequestTimeframe !== newContactRequestTimeframe
                                              ) {
                                                const updatedMeetingInvite = JSON.parse(JSON.stringify(currentMeetingInvite));
                                                updatedMeetingInvite.contactRequestTimeframe = newContactRequestTimeframe;
                                                logger.info(`setting contactRequestTimeframe for meetingInvite ${row.meetingInvite.id}`, updatedMeetingInvite);
                                                // NOTE: update MeetingInvite and set contactRequestTimeframe
                                                await dataProvider.update(
                                                  "meetingInvites",
                                                  {
                                                    id: updatedMeetingInvite.id,
                                                    data: {
                                                      id: updatedMeetingInvite.id,
                                                      contactRequestTimeframe: updatedMeetingInvite.contactRequestTimeframe,
                                                      _version: updatedMeetingInvite._version
                                                    },
                                                    previousData: {
                                                      id: currentMeetingInvite.id,
                                                      contactRequestTimeframe: currentMeetingInvite.contactRequestTimeframe,
                                                    },
                                                  }
                                                ).then(
                                                  (meetingInviteUpdateResults) => {
                                                    logger.info("meetingInviteUpdateResults", meetingInviteUpdateResults);
                                                    notify("Saved", { type: "success" });
                                                    setTimeout(
                                                      () => {
                                                        if (saveTimeout) {
                                                          try {
                                                            clearTimeout(saveTimeout as NodeJS.Timeout);
                                                          } catch (err) {}
                                                        }
                                                      },
                                                      1000,
                                                    );
                                                  }
                                                ).catch(
                                                  (err) => {
                                                    logger.error("failed to update meetingInvite", err);
                                                    notify(err.message, { type: "warning" });
                                                  }
                                                );
                                                // setMeetingInvite(updatedMeetingInvite);
                                                logger.info("updatedMeetingInvite", updatedMeetingInvite);
                                                refresh();
                                              } else {
                                                logger.info("meetingInvite.contactRequestTimeframe is unchanged.")
                                              }
                                            }
                                          }
                                        );
                                      }
                                    }
                                  }
                                />
                              </Grid>
                            </Grid>
                          </CompactForm>
                        </Edit>
                      )}
                        <Edit // @ts-ignore
                          id={row.responses?.items[0].id} // @ts-ignore
                          record={row.responses?.items[0]}
                          resource="questionResponses"
                          basePath="/questionResponses"
                          title={<></>}
                          mutationMode="optimistic"
                          redirect={false}
                        >
                          <CompactForm
                            toolbar={<NoDeleteToolbar />}
                            layoutComponents={[RaBox, RaGrid]}
                            save={save}
                            redirect={false}
                            warnWhenUnsavedChanges
                            resetOptions={{"keepDirtyValues": true}}
                            resetoptions={{"keepDirtyValues": true}}
                          >
                            {row.meetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE && (
                            <Grid
                              container
                              direction={isSmall ? "column" : "row"}
                              justifyContent="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item xs={12} sm={6}>
                                <Typography
                                  variant={isSmall ? undefined : "h5"} className={classes.boldText}
                                  align="center"
                                >
                                  Vendors automatically receive your basic contact info.<br/>Click below to grant this vendor better access for follow-up:
                                </Typography>

                              </Grid>
                            </Grid>
                            )}
                            {row.meetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE && (
                            <Grid
                              container
                              direction={isSmall ? "column" : "row"}
                              alignItems="stretch"
                              justifyContent="center"
                            >
                              <Grid item xs>
                                <Tooltip title="click to toggle">
                                  <BooleanCheckboxInput
                                    source="providePersonalEmail"
                                    label="Provide Private Email"
                                    onChange={
                                      (providePersonalEmail) => {
                                        logger.info("providePersonalEmail", providePersonalEmail);
                                        if (!isFormDirty) {
                                          setIsFormDirty(true);
                                        }
                                        if (saveTimeout) {
                                          try {
                                            clearTimeout(saveTimeout as NodeJS.Timeout);
                                          } catch (err) {}
                                        }
                                        // @ts-ignore
                                        const id = `${row.responses?.items[0].id}`;
                                        let originalQuestionResponse = questionResponsesData.get(id);
                                        if (originalQuestionResponse === undefined) {
                                          logger.warn("1 originalQuestionResponse is undefined", originalQuestionResponse);
                                          // @ts-ignore
                                          originalQuestionResponse = {
                                            id,
                                            /* providePersonalEmail,
                                            providePersonalPhoneNumber,
                                            textOnly,
                                            followupMeetingRequested,
                                            contactRequestDateTime,
                                            response,
                                            privateNotes, */ // @ts-ignore
                                            _version: row.responses?.items[0]?._version,
                                          };
                                        }
                                        logger.info("originalQuestionResponse", originalQuestionResponse);
                                        // @ts-ignore
                                        const responseData: QuestionResponse = {
                                          ...originalQuestionResponse,
                                          id,
                                          providePersonalEmail,
                                        };
                                        logger.info("newQuestionResponse", responseData);
                                        logger.info("questionResponsesData", questionResponsesData);
                                        const updatedQuestionResponsesData = clone(questionResponsesData);
                                        logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
                                        updatedQuestionResponsesData.set(
                                          id,
                                          responseData,
                                        );
                                        setQuestionResponsesData(updatedQuestionResponsesData);
                                        const newSaveTimeout = setTimeout(
                                          async () => {
                                            setSaveTimeout(0);
                                            logger.info("calling saved...", responseData);
                                            await updateQuestionResponse(
                                              transformQuestionResponse(responseData),
                                              responseData.id
                                            ).then(
                                              (saveResponse) => {
                                                logger.info("save useCallback successful", saveResponse);
                                                onSuccess({ data: responseData });
                                              }
                                            ).catch(
                                              (err) => {
                                                logger.error("save useCallback failed", err);
                                                onFailure(err);
                                              }
                                            );
                                            /* await mutate(
                                              {
                                                type: "update",
                                                resource: "questionResponses",
                                                payload: { data: responseData },
                                              },
                                              {
                                                returnPromise: true,
                                                onSuccess,
                                                onFailure,
                                              }
                                            ); */
                                          },
                                          3000
                                        );
                                        setSaveTimeout(newSaveTimeout);
                                      }
                                    }
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs>
                                <Tooltip title="click to toggle">
                                  <BooleanCheckboxInput
                                    source="providePersonalPhoneNumber"
                                    label="Provide Private Cell"
                                    onChange={
                                      (providePersonalPhoneNumber) => {
                                        logger.info("providePersonalPhoneNumber", providePersonalPhoneNumber);
                                        if (!isFormDirty) {
                                          setIsFormDirty(true);
                                        }
                                        if (saveTimeout) {
                                          try {
                                            clearTimeout(saveTimeout as NodeJS.Timeout);
                                          } catch (err) {}
                                        }
                                        // @ts-ignore
                                        const id = `${row.responses?.items[0].id}`;
                                        let originalQuestionResponse = questionResponsesData.get(id);
                                        if (originalQuestionResponse === undefined) {
                                          logger.warn("2 originalQuestionResponse is undefined", originalQuestionResponse);
                                          // @ts-ignore
                                          originalQuestionResponse = {
                                            id,
                                            /* providePersonalEmail,
                                            providePersonalPhoneNumber,
                                            textOnly,
                                            followupMeetingRequested,
                                            contactRequestDateTime,
                                            response,
                                            privateNotes, */ // @ts-ignore
                                            _version: row.responses?.items[0]?._version,
                                          };
                                        }
                                        logger.info("originalQuestionResponse", originalQuestionResponse);
                                        // @ts-ignore
                                        const responseData: QuestionResponse = {
                                          ...originalQuestionResponse,
                                          providePersonalPhoneNumber,
                                        };
                                        logger.info("newQuestionResponse", responseData);
                                        logger.info("questionResponsesData", questionResponsesData);
                                        const updatedQuestionResponsesData = clone(questionResponsesData);
                                        logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
                                        updatedQuestionResponsesData.set(
                                          id,
                                          responseData,
                                        );
                                        setQuestionResponsesData(updatedQuestionResponsesData);
                                        const newSaveTimeout = setTimeout(
                                          async () => {
                                            setSaveTimeout(0);
                                            logger.info("calling saved...", responseData);
                                            await updateQuestionResponse(
                                              transformQuestionResponse(responseData),
                                              responseData.id
                                            ).then(
                                              (saveResponse) => {
                                                logger.info("save useCallback successful", saveResponse);
                                                onSuccess({ data: responseData });
                                              }
                                            ).catch(
                                              (err) => {
                                                logger.error("save useCallback failed", err);
                                                onFailure(err);
                                              }
                                            );
                                            /* await mutate(
                                              {
                                                type: "update",
                                                resource: "questionResponses",
                                                payload: { data: responseData },
                                              },
                                              {
                                                returnPromise: true,
                                                onSuccess,
                                                onFailure,
                                              }
                                            ); */
                                          },
                                          3000
                                        );
                                        setSaveTimeout(newSaveTimeout);
                                      }
                                    }
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs>
                                <Tooltip title="click to toggle">
                                  <BooleanCheckboxInput
                                    source="textOnly"
                                    label="Text Only Please"
                                    onChange={
                                      (textOnly) => {
                                        logger.info("textOnly", textOnly);
                                        if (!isFormDirty) {
                                          setIsFormDirty(true);
                                        }
                                        if (saveTimeout) {
                                          try {
                                            clearTimeout(saveTimeout as NodeJS.Timeout);
                                          } catch (err) {}
                                        }
                                        // @ts-ignore
                                        const id = `${row.responses?.items[0].id}`;
                                        let originalQuestionResponse = questionResponsesData.get(id);
                                        if (originalQuestionResponse === undefined) {
                                          logger.warn("3 originalQuestionResponse is undefined", originalQuestionResponse);
                                          // @ts-ignore
                                          originalQuestionResponse = {
                                            id,
                                            /* providePersonalEmail,
                                            providePersonalPhoneNumber,
                                            textOnly,
                                            followupMeetingRequested,
                                            contactRequestDateTime,
                                            response,
                                            privateNotes, */ // @ts-ignore
                                            _version: row.responses?.items[0]?._version,
                                          };
                                        }
                                        logger.info("originalQuestionResponse", originalQuestionResponse);
                                        // @ts-ignore
                                        const responseData: QuestionResponse = {
                                          ...originalQuestionResponse,
                                          textOnly,
                                        };
                                        logger.info("newQuestionResponse", responseData);
                                        logger.info("questionResponsesData", questionResponsesData);
                                        const updatedQuestionResponsesData = clone(questionResponsesData);
                                        logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
                                        updatedQuestionResponsesData.set(
                                          id,
                                          responseData,
                                        );
                                        setQuestionResponsesData(updatedQuestionResponsesData);
                                        const newSaveTimeout = setTimeout(
                                          async () => {
                                            setSaveTimeout(0);
                                            logger.info("calling saved...", responseData);
                                            await updateQuestionResponse(
                                              transformQuestionResponse(responseData),
                                              responseData.id
                                            ).then(
                                              (saveResponse) => {
                                                logger.info("save useCallback successful", saveResponse);
                                                onSuccess({ data: responseData });
                                              }
                                            ).catch(
                                              (err) => {
                                                logger.error("save useCallback failed", err);
                                                onFailure(err);
                                              }
                                            );
                                            /* await mutate(
                                              {
                                                type: "update",
                                                resource: "questionResponses",
                                                payload: { data: responseData },
                                              },
                                              {
                                                returnPromise: true,
                                                onSuccess,
                                                onFailure,
                                              }
                                            ); */
                                          },
                                          3000
                                        );
                                        setSaveTimeout(newSaveTimeout);
                                      }
                                    }
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                            )}
                            {row.meetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE && (
                            <Grid
                              container
                              direction={isSmall ? "column" : "row"}
                            >
                              <Grid item xs>
                                <Typography
                                  variant={isSmall ? undefined : "h5"} className={classes.boldText}
                                  align="center"
                                >
                                  Would a follow-up meeting be appropriate?
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <NullableBooleanInput
                                  label="Yes/no"
                                  source="followupMeetingRequested"
                                  nullLabel="Yes/no"
                                  translateChoice={false}
                                  validate={followupMeetingRequestedValidation}
                                  onChange={
                                    (e) => {
                                      const followupMeetingRequested = e.target.value === (undefined || null) ? null : (e.target.value === "true" ? true : false);
                                      logger.info("followupMeetingRequested", followupMeetingRequested);
                                      if (!isFormDirty) {
                                        setIsFormDirty(true);
                                      }
                                      if (saveTimeout) {
                                        try {
                                          clearTimeout(saveTimeout as NodeJS.Timeout);
                                        } catch (err) {}
                                      }
                                      // @ts-ignore
                                      const id = `${row.responses?.items[0].id}`;
                                      let originalQuestionResponse = questionResponsesData.get(id);
                                      if (originalQuestionResponse === undefined) {
                                        logger.warn("4 originalQuestionResponse is undefined", originalQuestionResponse);
                                        // @ts-ignore
                                        originalQuestionResponse = {
                                          id,
                                          /* providePersonalEmail,
                                          providePersonalPhoneNumber,
                                          textOnly,
                                          followupMeetingRequested,
                                          contactRequestDateTime,
                                          response,
                                          privateNotes, */ // @ts-ignore
                                          _version: row.responses?.items[0]?._version,
                                        };
                                      }
                                      logger.info("originalQuestionResponse", originalQuestionResponse);
                                      // @ts-ignore
                                      const responseData: QuestionResponse = {
                                        ...originalQuestionResponse,
                                        followupMeetingRequested,
                                      };
                                      logger.info("newQuestionResponse", responseData);
                                      logger.info("questionResponsesData", questionResponsesData);
                                      const updatedQuestionResponsesData = clone(questionResponsesData);
                                      logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
                                      updatedQuestionResponsesData.set(
                                        id,
                                        responseData,
                                      );
                                      setQuestionResponsesData(updatedQuestionResponsesData);
                                      const newSaveTimeout = setTimeout(
                                        async () => {
                                          setSaveTimeout(0);
                                          logger.info("calling saved...", responseData);
                                          await updateQuestionResponse(
                                            transformQuestionResponse(responseData),
                                            responseData.id
                                          ).then(
                                            (saveResponse) => {
                                              logger.info("save useCallback successful", saveResponse);
                                              onSuccess({ data: responseData });
                                            }
                                          ).catch(
                                            (err) => {
                                              logger.error("save useCallback failed", err);
                                              onFailure(err);
                                            }
                                          );
                                          /* await mutate(
                                            {
                                              type: "update",
                                              resource: "questionResponses",
                                              payload: { data: responseData },
                                            },
                                            {
                                              returnPromise: true,
                                              onSuccess,
                                              onFailure,
                                            }
                                          ); */
                                        },
                                        3000
                                      );
                                      setSaveTimeout(newSaveTimeout);
                                    }
                                  }
                                />
                              </Grid>
                            </Grid>
                            )}
                            {row.meetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE && showContactRequestDateTime.get(`${row.responses?.items[0]?.id}`) && (
                            <Grid
                              container
                              direction={isSmall ? "column" : "row"}
                            >
                              <Grid
                                alignContent="center"
                                alignItems="center"
                                item xs>
                                <Button
                                  label="ASAP"
                                  size="small"
                                  startIcon={<CalendarTodayIcon />}
                                  disabled={requestedAsap.get(`${row.meetingInvite.id}`) === 'asap'}
                                  color="secondary"
                                  variant="contained"
                                  onClick={() => {
                                    logger.info(`setting asap meeting invite ID ${row.meetingInvite.id}`);
                                    createFeedbackAsap({
                                      meetingInviteId: `${row.meetingInvite.id}`
                                    }).then(
                                      (createFeedbackAsapResult) => {
                                        logger.info("createFeedbackAsapResult", createFeedbackAsapResult);
                                        const updatedRequestedAsap = new Map(requestedAsap);
                                        updatedRequestedAsap.set(`${row.meetingInvite.id}`, 'asap');
                                        setRequestedAsap(updatedRequestedAsap);
                                        const alertMessage = "A Physician Direct staff member will be in touch shortly to arrange a follow-up meeting.";
                                        ReactSwal.fire({
                                          title: "Saved",
                                          text: alertMessage,
                                          icon: "success",
                                          showCancelButton: false,
                                          confirmButtonText: "Continue",
                                          confirmButtonColor: "#056839",
                                        }).then(async (result: { isConfirmed: boolean }) => {});
                                      }
                                    ).catch(
                                      (err) => {
                                        logger.error(err);
                                        notify(`${err}`, { type: "error" });
                                      }
                                    );
                                  }}
                                />
                                <br/>
                                <Typography>
                                  Next 1-2 days
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <Typography variant={isSmall ? undefined : "h4"} className={classes.boldText}>
                                  OR
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <DateTimeInput
                                  size="small"
                                  source="contactRequestDateTime" // @ts-ignore
                                  // options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
                                  label="Propose a Date/Time"
                                  onChange={
                                    (e) => {
                                      logger.info("contactRequestDateTime e", e);
                                      if (!isFormDirty) {
                                        setIsFormDirty(true);
                                      }
                                      if (saveTimeout) {
                                        try {
                                          clearTimeout(saveTimeout as NodeJS.Timeout);
                                        } catch (err) {}
                                      }
                                      // @ts-ignore
                                      const id = `${row.responses?.items[0].id}`;
                                      let originalQuestionResponse = questionResponsesData.get(id);
                                      if (originalQuestionResponse === undefined) {
                                        logger.warn("5 originalQuestionResponse is undefined", originalQuestionResponse);
                                        // @ts-ignore
                                        originalQuestionResponse = {
                                          id,
                                          /* providePersonalEmail,
                                          providePersonalPhoneNumber,
                                          textOnly,
                                          followupMeetingRequested,
                                          contactRequestDateTime,
                                          response,
                                          privateNotes, */ // @ts-ignore
                                          _version: row.responses?.items[0]?._version,
                                        };
                                      }
                                      logger.info("originalQuestionResponse", originalQuestionResponse);
                                      // @ts-ignore
                                      const responseData: QuestionResponse = {
                                        ...originalQuestionResponse,
                                        contactRequestDateTime: Date.parse(e.target.value) ? DateTime.fromMillis(Date.parse(e.target.value)).toUTC().toFormat(AWS_TIMESTAMP_AS_LUXON_FORMAT) : null,
                                      };
                                      logger.info("newQuestionResponse", responseData);
                                      logger.info("questionResponsesData", questionResponsesData);
                                      const updatedQuestionResponsesData = clone(questionResponsesData);
                                      logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
                                      updatedQuestionResponsesData.set(
                                        id,
                                        responseData,
                                      );
                                      setQuestionResponsesData(updatedQuestionResponsesData);
                                      const newSaveTimeout = setTimeout(
                                        async () => {
                                          setSaveTimeout(0);
                                          logger.info("calling saved...", responseData);
                                          await updateQuestionResponse(
                                            transformQuestionResponse(responseData),
                                            responseData.id
                                          ).then(
                                            (saveResponse) => {
                                              logger.info("save useCallback successful", saveResponse);
                                              onSuccess({ data: responseData });
                                            }
                                          ).catch(
                                            (err) => {
                                              logger.error("save useCallback failed", err);
                                              onFailure(err);
                                            }
                                          );
                                          /* await mutate(
                                            {
                                              type: "update",
                                              resource: "questionResponses",
                                              payload: { data: responseData },
                                            },
                                            {
                                              returnPromise: true,
                                              onSuccess,
                                              onFailure,
                                            }
                                          ); */
                                        },
                                        3000
                                      );
                                      setSaveTimeout(newSaveTimeout);
                                    }
                                  }
                                />
                              </Grid>
                              <Grid item xs>
                                <Typography variant={isSmall ? undefined : "h4"} className={classes.boldText}>
                                  OR
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <List
                                  dense
                                  component="div"
                                  role="list"
                                >
                                  <ListItem key={`04-${i}`} role="listitem">
                                    <ListItemText id="vendorMakeArrangement" primary="Vendor should make contact to arrange our next meeting." />
                                    <ListItemIcon>
                                      <Checkbox />
                                    </ListItemIcon>
                                  </ListItem>
                                </List>
                              </Grid>
                            </Grid>
                            )}
                            <Grid
                              container
                              direction={isSmall ? "column" : "row"}
                              justifyContent="flex-start"
                              alignItems="stretch"
                              spacing={2}
                            >
                              <Grid item xs>
                                <TextInput
                                  multiline
                                  source="response"
                                  label={row.meetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE ? "Additional follow-up instructions" : "Note to Surgeon (Limit 500 characters and spaces)"}
                                  minRows="4"
                                  maxLength="500"
                                  fullWidth
                                  onChange={
                                    (e) => {
                                      logger.info("response event", e);
                                      if (!isFormDirty) {
                                        setIsFormDirty(true);
                                      }
                                      if (saveTimeout) {
                                        try {
                                          clearTimeout(saveTimeout as NodeJS.Timeout);
                                        } catch (err) {}
                                      }
                                      // @ts-ignore
                                      const id = `${row.responses?.items[0].id}`;
                                      let originalQuestionResponse = questionResponsesData.get(id);
                                      if (originalQuestionResponse === undefined) {
                                        logger.warn("6 originalQuestionResponse is undefined", originalQuestionResponse);
                                        // @ts-ignore
                                        originalQuestionResponse = {
                                          id,
                                          /* providePersonalEmail,
                                          providePersonalPhoneNumber,
                                          textOnly,
                                          followupMeetingRequested,
                                          contactRequestDateTime,
                                          response,
                                          privateNotes, */ // @ts-ignore
                                          _version: row.responses?.items[0]?._version,
                                        };
                                      }
                                      logger.info("originalQuestionResponse", originalQuestionResponse);
                                      // @ts-ignore
                                      const responseData: QuestionResponse = {
                                        ...originalQuestionResponse,
                                        response: e.target.value,
                                      };
                                      logger.info("newQuestionResponse", responseData);
                                      logger.info("questionResponsesData", questionResponsesData);
                                      const updatedQuestionResponsesData = clone(questionResponsesData);
                                      logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
                                      updatedQuestionResponsesData.set(
                                        id,
                                        responseData,
                                      );
                                      setQuestionResponsesData(updatedQuestionResponsesData);
                                      const newSaveTimeout = setTimeout(
                                        async () => {
                                          setSaveTimeout(0);
                                          logger.info("calling saved...", responseData);
                                          await updateQuestionResponse(
                                            transformQuestionResponse(responseData),
                                            responseData.id
                                          ).then(
                                            (saveResponse) => {
                                              logger.info("save useCallback successful", saveResponse);
                                              onSuccess({ data: responseData });
                                            }
                                          ).catch(
                                            (err) => {
                                              logger.error("save useCallback failed", err);
                                              onFailure(err);
                                            }
                                          );
                                          /* await mutate(
                                            {
                                              type: "update",
                                              resource: "questionResponses",
                                              payload: { data: responseData },
                                            },
                                            {
                                              returnPromise: true,
                                              onSuccess,
                                              onFailure,
                                            }
                                          ); */
                                        },
                                        3000
                                      );
                                      setSaveTimeout(newSaveTimeout);
                                    }
                                  }
                                />
                              </Grid>
                            </Grid>
                            {(row.meetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER) && (
                            <Grid
                              container
                              direction={isSmall ? "column" : "row"}
                              justifyContent="flex-start"
                              alignItems="stretch"
                              spacing={2}
                            >
                              <Grid item xs>
                                <TextInput
                                  multiline
                                  source="privateNotes"
                                  label={"Private Notes"}
                                  minRows="4"
                                  fullWidth
                                  onChange={
                                    (e) => {
                                      logger.info("response event", e);
                                      if (!isFormDirty) {
                                        setIsFormDirty(true);
                                      }
                                      if (saveTimeout) {
                                        try {
                                          clearTimeout(saveTimeout as NodeJS.Timeout);
                                        } catch (err) {}
                                      }
                                      // @ts-ignore
                                      const id = `${row.responses?.items[0].id}`;
                                      let originalQuestionResponse = questionResponsesData.get(id);
                                      if (originalQuestionResponse === undefined) {
                                        logger.warn("7 originalQuestionResponse is undefined", originalQuestionResponse);
                                        // @ts-ignore
                                        originalQuestionResponse = {
                                          id,
                                          /* providePersonalEmail,
                                          providePersonalPhoneNumber,
                                          textOnly,
                                          followupMeetingRequested,
                                          contactRequestDateTime,
                                          response,
                                          privateNotes, */ // @ts-ignore
                                          _version: row.responses?.items[0]?._version,
                                        };
                                      }
                                      logger.info("originalQuestionResponse", originalQuestionResponse);
                                      // @ts-ignore
                                      const responseData: QuestionResponse = {
                                        ...originalQuestionResponse,
                                        privateNotes: e.target.value,
                                      };

                                      logger.info("newQuestionResponse", responseData);
                                      logger.info("questionResponsesData", questionResponsesData);
                                      const updatedQuestionResponsesData = clone(questionResponsesData);
                                      logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
                                      updatedQuestionResponsesData.set(
                                        id,
                                        responseData,
                                      );
                                      setQuestionResponsesData(updatedQuestionResponsesData);
                                      const newSaveTimeout = setTimeout(
                                        async () => {
                                          setSaveTimeout(0);
                                          logger.info("calling saved...", responseData);
                                          await updateQuestionResponse(
                                            transformQuestionResponse(responseData),
                                            responseData.id
                                          ).then(
                                            (saveResponse) => {
                                              logger.info("save useCallback successful", saveResponse);
                                              onSuccess({ data: responseData });
                                            }
                                          ).catch(
                                            (err) => {
                                              logger.error("save useCallback failed", err);
                                              onFailure(err);
                                            }
                                          );
                                          /* await mutate(
                                            {
                                              type: "update",
                                              resource: "questionResponses",
                                              payload: { data: responseData },
                                            },
                                            {
                                              returnPromise: true,
                                              onSuccess,
                                              onFailure,
                                            }
                                          ); */
                                        },
                                        3000
                                      );
                                      setSaveTimeout(newSaveTimeout);
                                    }
                                  }
                                />
                              </Grid>
                            </Grid>
                            )}
                          </CompactForm>
                        </Edit>
                      </TableCell>
                    </TableRow>
                    {(row.meetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER && row.attendeeFeedback) && (

                    <TableRow key={`${row.id}-${i}-3`}>
                      <TableCell className={classes.tableCell} colSpan={2}>
                        <Table>
                          <TableHead>
                            <TableRow key={`${row.id}-${i}-3-header-1`}>
                              <TableCell colSpan={4}>
                                Feedback Responses
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead>
                            <TableRow key={`${row.id}-${i}-3-header-2`}>
                              <TableCell>
                                Name
                              </TableCell>
                              <TableCell>
                                Summary
                              </TableCell>
                              <TableCell>
                                Timeframe
                              </TableCell>
                              <TableCell>
                                Requested date
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          {(row.attendeeFeedback || []).map((feedbackRow, j) => (
                            <TableRow key={`${feedbackRow.questionResponseId}-${j}`}>
                              {feedbackRow.providePersonalEmail && (
                              <TableCell>
                                <a href={`mailto:${feedbackRow.contactEmail}`}>{feedbackRow.contactName}</a>
                              </TableCell>
                              )}
                              {!feedbackRow.providePersonalEmail && (
                              <TableCell>{`${feedbackRow.contactName}`}</TableCell>
                              )}
                              <TableCell>{`${feedbackRow.feedbackSummary}`}</TableCell>
                              <TableCell>{`${feedbackRow.contactRequestTimeframe}`}</TableCell>
                              <TableCell>{`${feedbackRow.contactRequestDateTime}`}</TableCell>
                            </TableRow>
                          ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                    )}
                  </TableBody>
                  )}
                </Table>
              ))}
              </TableContainer>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                    <TableRow key="nav-1">
                      <TableCell>
                        <Button
                          size={isSmall ? "small" : "large"}
                          onClick={() => getPreviousPage() }
                          label="Previous"
                          endIcon={<NavigateBeforeIcon />}
                          disabled={isFormDirty || !hasPreviousPage}
                        >
                          <NavigateBeforeIcon />
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {(totalCount) ? (
                          <Typography variant={isSmall ? undefined : "h4"} className={classes.boldText}>Page {page} of {totalCount}</Typography>
                        ) : (
                          <Typography variant={isSmall ? undefined : "h4"} className={classes.boldText}>&nbsp;</Typography>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          size={isSmall ? "small" : "large"}
                          onClick={() => getNextPage() }
                          label="Next"
                          endIcon={<NavigateNextIcon />}
                          disabled={isFormDirty || !hasNextPage}
                        >
                          <NavigateNextIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          </>
        ) : <LinearProgress />}
        </div>
      </div>
    </>
  );
};
