import React, { useContext, useEffect } from "react";
import { Logger } from "aws-amplify";
import { useRedirect } from "react-admin";
import { useParams } from "react-router-dom";
import { logEvent, endMeeting } from "../lib/helpers";
import { MeetingsStateContext } from "../App";
import { usePreferences } from "@react-admin/ra-preferences";

const logger = new Logger("ExitMeetingPage");

function ExitMeetingPage() {

  const params: any = useParams();
  const redirect = useRedirect();
  const meetingsState = useContext(MeetingsStateContext);
  const [ cachedServerTimeDifference ] = usePreferences("cachedServerTimeDifference", 0);
  const {
    currentProfile,
    userAgentData,
  } = meetingsState;

  useEffect(() => {
    logger.info("exitMeeting params.meetingInviteId", params.meetingInviteId);
    let redirectUrl = "/";
    if (params.meetingInviteId) {
      redirectUrl = `/break/${params.meetingInviteId}`;
      endMeeting(params.meetingInviteId).then(
        async (exitData) => {
          logger.info("exitData", exitData);
          if (currentProfile) {
            await logEvent(
              currentProfile,
              'exit meeting called',
              {
                details: exitData,
              },
              'info',
              cachedServerTimeDifference,
              userAgentData,
            );
          }
          if (exitData) {
            const {
              productId
            } = exitData;
            if (!productId) {
              redirectUrl = '/';
            }
          }
          setTimeout(
            () => {
              try {
                redirect(redirectUrl);
              } catch (err) {
                logger.error("redirect error", err);
                redirect('/');
              }
            },
            1000 * 5,
          );
        }
      ).catch(
        async (err) => {
          logger.error("endMeeting error", err);
          if (currentProfile) {
            await logEvent(
              currentProfile,
              'exit meeting error',
              {},
              'error',
              cachedServerTimeDifference,
              userAgentData,
            );
          }
          redirect(redirectUrl);
        }
      );
    } else {
      redirect(redirectUrl);
    }
  }, [params.meetingInviteId]);

  return (
    <></>
  );
}

export default ExitMeetingPage;