// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const JobStatus = {
  "PENDING": "PENDING",
  "PROCESSING": "PROCESSING",
  "FAILED": "FAILED",
  "CANCELLED": "CANCELLED",
  "COMPLETED": "COMPLETED"
};

const MeetingInvitationType = {
  "INVITEE": "INVITEE",
  "ORGANIZER": "ORGANIZER"
};

const MeetingRoomSessionStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const MeetingRoomStatus = {
  "CLOSED": "CLOSED",
  "OPEN": "OPEN"
};

const MeetingStatus = {
  "SCHEDULED": "SCHEDULED",
  "CANCELLED": "CANCELLED",
  "ERROR": "ERROR",
  "PENDING": "PENDING",
  "READY": "READY",
  "STARTED": "STARTED",
  "ENDED": "ENDED",
  "EXPIRED": "EXPIRED"
};

const TeamRelationship = {
  "LEADER": "LEADER",
  "MEMBER": "MEMBER",
  "MANAGER": "MANAGER",
  "GUEST": "GUEST"
};

const TeamMeetingRelationshipType = {
  "ORGANIZER": "ORGANIZER",
  "INVITEE": "INVITEE"
};

const ThemeStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const ThemeType = {
  "DARK": "DARK",
  "LIGHT": "LIGHT"
};

const { ProductQuestion, QuestionResponse, ProductImage, Product, OrganizationLocation, OrganizationLogo, OrganizationTheme, Organization, ProfileLocation, ProfileImage, ForwardEmail, Profile, ProfileDevice, TeamImage, Team, TeamMemberRelationship, MeetingRoomProvider, TeamMeetingRelationship, MeetingAttendee, Meeting, MeetingInvite, MeetingRoom, MeetingRoomEventAuditLog, ExportJob, ExportJobAuditLog, ImportJob, ImportJobAuditLog, Notification, Transcription, TranscriptionJob, TranscriptionJobAuditLog, TranscriptionAdminJob, TranscriptionAdminJobAuditLog, TranscriptionEventAuditLog, S3Object } = initSchema(schema);

export {
  ProductQuestion,
  QuestionResponse,
  ProductImage,
  Product,
  OrganizationLocation,
  OrganizationLogo,
  OrganizationTheme,
  Organization,
  ProfileLocation,
  ProfileImage,
  ForwardEmail,
  Profile,
  ProfileDevice,
  TeamImage,
  Team,
  TeamMemberRelationship,
  MeetingRoomProvider,
  TeamMeetingRelationship,
  MeetingAttendee,
  Meeting,
  MeetingInvite,
  MeetingRoom,
  MeetingRoomEventAuditLog,
  ExportJob,
  ExportJobAuditLog,
  ImportJob,
  ImportJobAuditLog,
  Notification,
  Transcription,
  TranscriptionJob,
  TranscriptionJobAuditLog,
  TranscriptionAdminJob,
  TranscriptionAdminJobAuditLog,
  TranscriptionEventAuditLog,
  JobStatus,
  MeetingInvitationType,
  MeetingRoomSessionStatus,
  MeetingRoomStatus,
  MeetingStatus,
  TeamRelationship,
  TeamMeetingRelationshipType,
  ThemeStatus,
  ThemeType,
  S3Object
};