import React, { useCallback, useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ReferenceField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  useRecordContext,
  Link,
  required,
  BooleanInput,
  NumberField,
  NumberInput,
  SelectInput,
  BooleanField,
  useNotify,
  useRedirect,
  useMutation,
} from "react-admin";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
} from "react-admin";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { CustomList, ListPagination } from "./CustomList";
import TopToolbar from "./TopToolbar";
import { AmplifyImageField } from "./AmplifyImageField";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import { THEME_STATUS_CHOICES, ThemeStatus } from "../constants";
import { Logger } from "@aws-amplify/core";
import { OrganizationLogo } from "../API";
import { AmplifyImageInput } from "./AmplifyImageInput";

const defaultQuery = "listOrganizationLogos";
const validateRequired = [required()];
const logger = new Logger("OrganizationLogo");
const storageOptions = {
  level: "public",
};

export const OrganizationLogoList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
      {...props}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <TextField
          source="organization.name"
          label="Organization name"
          sortable={false}
        />
        <TextField source="title" sortable={false} />
        <TextField source="status" sortable={false} />
        <DateField showTime={true} source="activationStartDateTime" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const OrganizationLogoShow = (props: ShowProps) => {

  const ShowActions = (props: ShowProps) => {
    const {
      basePath,
      className,
      id,
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/organizations/${record.organizationId}/show`,
          }}
          label="Return to organization"
        />
        <EditButton basePath={basePath} label="Edit" record={{ id: id as string }} />
      </TopToolbar>
    );
  };

  return (
    <Show
      {...props}
      actions={<ShowActions {...props} />}
    >
      <SimpleShowLayout>
        <TextField source="id" label="ID" fullWidth />
        <TextField source="title" fullWidth />
        <TextField source="alt" fullWidth />
        <NumberField source="width" />
        <NumberField source="height" />
        <AmplifyImageField source="image" title="title" storageOptions={storageOptions} />
        <TextField source="status" />
        <DateField showTime={true} source="activationStartDateTime" />
        <DateField showTime={true} source="activationEndDateTime" />
        <BooleanField
          source="isDefault"
          label="Is default?"
          defaultValue="No"
        />
        <BooleanField
          source="isInApp"
          label="Is in app?"
          defaultValue="No"
        />
        <ReferenceField
          label="Company"
          source="organizationId"
          reference="organizations"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField showTime={true} source="createdAt" />
        <DateField showTime={true} source="updatedAt" />
      </SimpleShowLayout>
    </Show>
  );
}

export const OrganizationLogoEdit = (props: EditProps) => {

  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate] = useMutation();

  const onSuccess = (onsuccessData: { data: OrganizationLogo }) => {
    const {
      data,
    } = onsuccessData;
    let message = "OrganizationLogo updated";
    notify(message, { type: "success" });
    redirect(`/organizationLogos/${data.id}/show`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: OrganizationLogo) => {
      logger.log("Received update data", data);
      const validFields = [
        'id',
        'title',
        'alt',
        'width',
        'height',
        'image',
        'isDefault',
        'isInApp',
        'organizationId',
        'status',
        'activationStartDateTime',
        'activationEndDateTime',
        'owner',
        '_version',
      ];
      const validImageFields = [
        'bucket',
        'key',
        'region',
      ];
      const dataKeys = Object.keys(data);
      for (let i = 0; i < dataKeys.length; i=i+1) {
        const dataKey = dataKeys[i];
        if (!validFields.includes(dataKey)) {
          // @ts-ignore
          delete data[dataKey];
          logger.warn(`^ Invalid field "${dataKey}" removed from update data`, data);
        } else if (dataKey === 'image') {
          const imageDataKeys = Object.keys(data[dataKey]);
          for (let x = 0; x < imageDataKeys.length; x=x+1) {
            const imageDataKey = imageDataKeys[x];
            if (!validImageFields.includes(imageDataKey)) {
              // @ts-ignore
              delete data[dataKey][imageDataKey];
            }
          }
        }
      }
      logger.info("logo data", data);
      await mutate(
        {
          type: "update",
          resource: "organizationLogos",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  const EditActions = (props: EditProps) => {
    const {
      className,
      basePath,
      id
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/organizations/${record.organizationId}/show`,
          }}
          label="Return to organization"
        />
        <ShowButton
          basePath={basePath}
          label="Show"
          record={{ id: id as string }}
        />
      </TopToolbar>
    );
  };

  return (
    <Edit
      {...props}
      actions={<EditActions {...props} />}
      undoable={false}
      mutationMode="optimistic"
    >
      <SimpleForm
        toolbar={<DeleteWithConfirmToolbar
          mutationMode="optimistic"
          undoable={false}
        />}
        save={save}
      >
        <TextInput source="id" label="ID" disabled />
        <TextInput
          source="title"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput
          source="alt"
          fullWidth
        />
        <NumberInput source="width" />
        <NumberInput source="height" />
        <AmplifyImageInput
          source="image"
          title="title"
          storageOptions={storageOptions}
          isRequired
          validate={validateRequired}
          accept="image/*"
        />
        <SelectInput
          source="status"
          choices={THEME_STATUS_CHOICES}
          translateChoice={false}
          defaultValue={ThemeStatus.Inactive}
          validate={required("Theme status is required.")}
          isRequired
        />
        <MaterialDateTimeInput
          label="Activation Start Date"
          source="activationStartDateTime"
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
        />
        <MaterialDateTimeInput
          label="Activation End Date"
          source="activationEndDateTime"
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
        />
        <BooleanInput
          source="isDefault"
          label="Is default?"
          defaultValue={false}
        />
        <BooleanInput
          source="isInApp"
          label="Is in app?"
          defaultValue={false}
        />
        <ReferenceInput
          source="organizationId"
          reference="organizations"
          label="Company"
          filterToQuery={(searchText: string) => ({
            listOrganizations: { name: searchText },
          })}
          perPage={2000}
          isRequired
          validate={validateRequired}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}

export const OrganizationLogoCreate = (props: CreateProps) => {

  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate] = useMutation();

  const onSuccess = (onsuccessData: { data: OrganizationLogo }) => {
    const {
      data,
    } = onsuccessData;
    let message = "OrganizationLogo created";
    notify(message, { type: "success" });
    redirect(`/organizations/${data.organizationId}/show`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: OrganizationLogo) => {
      logger.log("Received create data", data);
      const validFields = [
        'title',
        'alt',
        'width',
        'height',
        'image',
        'isDefault',
        'isInApp',
        'organizationId',
        'status',
        'activationStartDateTime',
        'activationEndDateTime',
        'owner',
      ];
      const dataKeys = Object.keys(data);
      for (let i = 0; i < dataKeys.length; i=i+1) {
        const dataKey = dataKeys[i];
        if (!validFields.includes(dataKey)) {
          // @ts-ignore
          delete data[dataKey];
          logger.warn(`^ Invalid field "${dataKey}" removed from create data`, data);
        }
      }
      await mutate(
        {
          type: "create",
          resource: "organizationLogos",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  const CreateActions = (props: CreateProps) => {
    const {
      className,
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/organizations/${record.organizationId}/show`,
          }}
          label="Return to organization"
        />
      </TopToolbar>
    );
  };

  return (
    <Create
      {...props}
      actions={<CreateActions {...props} />}
    >
      <SimpleForm
        save={save}
      >
      <TextInput
          source="title"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput
          source="alt"
          fullWidth
        />
        <NumberInput source="width" />
        <NumberInput source="height" />
        <AmplifyImageInput
          source="image"
          title="title"
          storageOptions={storageOptions}
          isRequired
          validate={validateRequired}
          accept="image/*"
        />
        <SelectInput
          source="status"
          choices={THEME_STATUS_CHOICES}
          translateChoice={false}
          defaultChecked={true}
          defaultValue={ThemeStatus.Inactive}
          validate={required("Theme status is required.")}
          isRequired
        />
        <MaterialDateTimeInput
          label="Activation Start Date"
          source="activationStartDateTime"
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
        />
        <MaterialDateTimeInput
          label="Activation End Date"
          source="activationEndDateTime"
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
        />
        <BooleanInput
          source="isDefault"
          label="Is default?"
          defaultValue={false}
        />
        <BooleanInput
          source="isInApp"
          label="Is in app?"
          defaultValue={false}
        />
        <ReferenceInput
          source="organizationId"
          reference="organizations"
          label="Company"
          filterToQuery={(searchText: string) => ({
            listOrganizations: { name: searchText },
          })}
          perPage={2000}
          isRequired
          validate={validateRequired}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
