import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Edit,
  LinearProgress,
  RadioButtonGroupInput,
  RecordContextProvider,
  ReferenceManyField,
  SaveButton,
  setAutomaticRefresh,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextInput,
  ToolbarProps,
  useAuthenticated,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect,
} from "react-admin";
import { Logger } from "aws-amplify";
import type {
  MeetingInvite,
  QuestionResponse,
  TeamImage,
  UpdateQuestionResponseInput,
} from "../API";
import {
  MeetingStatus,
  MeetingRoomSessionStatus,
  MeetingInvitationType,
} from "../API";
import {
  Grid,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  useMediaQuery,
  Theme,
  Tooltip,
} from "@material-ui/core";
import CameraFrontIcon from "@material-ui/icons/CameraFront";
import MeetingCountdown from "../components/MeetingCountdown";
import GenericCountdown from "../components/GenericCountdown";
import type {
  IMeetingProductInformation,
  UpdateMeetingBreakRestInput,
} from "../types";
import { MeetingsStateContext } from "../App";
import ScheduledMeetingsMenu from "../components/ScheduledMeetingsMenu";
import { AmplifyImageField } from "../components/AmplifyImageField";
import { Link, useParams } from "react-router-dom";
import DoneIcon from '@material-ui/icons/Done';
import {
  getMeetingProductInformation,
  getTeamImageByTeamId,
  createOrUpdateQuestionResponse,
  awsDateTimeStringToDateTime,
  getCurrentDateTime,
  logEvent,
  updateQuestionResponse,
  updateMeetingBreak,
  transformQuestionResponse,
} from "../lib/helpers";
import NoDeleteToolbar from "../components/NoDeleteToolbar";
import { sellerBuyerStyles } from "../layout/theme";
import { useDispatch } from "react-redux";
import clone from "lodash/clone";
import {
  CONTACT_REQUEST_TIMEFRAME_CHOICES,
  FEEDBACK_SUMMARY_CHOICES,
} from "../constants";
import { usePreferences } from "@react-admin/ra-preferences";

const logger = new Logger("MeetingBreak");

function isInterested(m: MeetingInvite): boolean {
  if (!m.feedbackSummary) {
    return false;
  }
  return [
    'very_interested',
    'somewhat_interested',
  ].includes(m.feedbackSummary);
}

/**
 * Seller Meeting Break
 *
 * @returns {JSX.Element}
 */
export const MeetingBreakPage = () => {

  useAuthenticated(); // NOTE: redirects to login if not authenticated
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const meetingsState = useContext(MeetingsStateContext);
  const redirect = useRedirect();
  const dispatch = useDispatch();
  // const [ mutate ] = useMutation();
  // const refresh = useRefresh();
  dispatch(setAutomaticRefresh(false));
  const {
    meetingInvitesState,
    nextMeetingInvite,
    isSeller,
    isBuyer,
    currentProfile,
    userAgentData,
    defaultLogo,
    inAppLogo,
  } = meetingsState;

  const params: any = useParams();
  const classes = sellerBuyerStyles();
  const [ questionResponsesData, setQuestionResponsesData ] = useState<Map<string, QuestionResponse>>(new Map());
  const [ isFormDirty, setIsFormDirty ] = useState<boolean>(false);
  const [ saveTimeout, setSaveTimeout ] = useState<number | NodeJS.Timeout>(0);
  /// const [ saveCounter, setSaveCounter ] = useState<number>(0);
  const [ step, setStep ] = useState<number>(1); // setStep(2);
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ meetingInviteId, setMeetingInviteId ] = useState<string>(`${params.meetingInviteId}`);
  const [ meetingInvite, setMeetingInvite ] = useState<MeetingInvite | null>(null);
  const meetingInviteRef = useRef<MeetingInvite | null>(null);
  const [ buyerTeamImage, setBuyerTeamImage ] = useState<TeamImage | null>(null);
  const [ feedbackRedirectDelay, setFeedbackRedirectDelay ] = useState<Date>(new Date((new Date).getTime() + (60 * 1000))); // plus 1 minute
  // const [ questionResponse, setQuestionResponse ] = useState<QuestionResponse | null>(null);
  const questionResponseRef = useRef<QuestionResponse | null>(null);
  const [ meetingProductInformation, setMeetingProductInformation ] = useState<IMeetingProductInformation | null>(null);
  const [ showContactRequestTimeframe, setShowContactRequestTimeframe ] = useState<boolean>(false);
  const [ clockSyncEnabled ] = usePreferences("clockSyncEnabled", false);
  const [ cachedServerTimeDifference ] = usePreferences("cachedServerTimeDifference", 0);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const editProps = {
    id: meetingInviteId,
    record: meetingInviteRef.current,
    resource: "meetingInvites",
    basePath: "/meetingInvites",
  };

  const onFailure = async (error: any) => {
    logger.error("onFailure.error", error);
    const errorMessage = "An error occurred";
    notify(errorMessage, { type: "error" });
    setIsFormDirty(false);
    // setSaveCounter(saveCounter + 1);
    // refresh();
    if (currentProfile && meetingInviteRef.current) {
      await logEvent(
        currentProfile,
        "failed tp save notes on break page",
        {
          meeting: {
            id: meetingInviteRef.current.meetingId,
            invite_id: meetingInviteId,
          },
          details: error,
        },
        'warn',
        cachedServerTimeDifference,
        userAgentData,
      );
    }
  };

  const onSuccess = async (onSuccessResponse: any) => {
    const {
      data,
    } = onSuccessResponse;
    logger.info("onSuccess response", onSuccessResponse);
    logger.info("onSuccess data", data);
    const successMessage = "Saved";
    notify(successMessage, { type: "success" });
    setIsFormDirty(false);
    // setSaveCounter(saveCounter + 1);
    const {
      id,
      /* providePersonalEmail,
      providePersonalPhoneNumber,
      textOnly,
      followupMeetingRequested,
      contactRequestDateTime, */
      response,
      privateNotes,
      _version,
    } = data;
    logger.info("onSuccess _version", _version);
    const responseData = {
      id,
      /* providePersonalEmail,
      providePersonalPhoneNumber,
      textOnly,
      followupMeetingRequested,
      contactRequestDateTime, */
      response,
      privateNotes,
      _version,
    };
    logger.info("onSuccess responseData", responseData);
    logger.info("questionResponsesData", questionResponsesData);
    const updatedQuestionResponsesData = clone(questionResponsesData);
    logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
    updatedQuestionResponsesData.set(
      id, // @ts-ignore
      responseData,
    );
    setQuestionResponsesData(updatedQuestionResponsesData);
    // refresh();
    if (currentProfile && meetingInviteRef.current) {
      await logEvent(
        currentProfile,
        "notes saved on break page",
        {
          meeting: {
            id: meetingInviteRef.current.meetingId,
            invite_id: meetingInviteId,
          },
          details: data,
        },
        'info',
        cachedServerTimeDifference,
        userAgentData,
      );
    }
  };

  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback( // TODO only fields from form
    async (questionResponseData: QuestionResponse) => {
      const data = transformQuestionResponse(questionResponseData);
      logger.info("save data", data);
      const {
        id,
        /* providePersonalEmail,
        providePersonalPhoneNumber,
        textOnly,
        followupMeetingRequested,
        contactRequestDateTime, */
        response,
        privateNotes,
        _version,
      } = data;
      logger.info("onSuccess _version", _version);
      const responseData = {
        id,
        /* providePersonalEmail,
        providePersonalPhoneNumber,
        textOnly,
        followupMeetingRequested,
        contactRequestDateTime, */
        response,
        privateNotes,
        _version,
      };
      logger.info("onSuccess responseData", responseData);
      logger.info("questionResponsesData", questionResponsesData);
      const updatedQuestionResponsesData = clone(questionResponsesData);
      logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
      updatedQuestionResponsesData.set(
        `${id}`, // @ts-ignore
        responseData,
      );
      setQuestionResponsesData(updatedQuestionResponsesData);

      await updateQuestionResponse(responseData, `${id}`).then(
        (saveResponse) => {
          logger.info("save useCallback successful", saveResponse);
          onSuccess({ data: responseData });
        }
      ).catch(
        (err) => {
          logger.error("save useCallback failed", err);
          onFailure(err);
        }
      );

      /* await mutate(
        {
          type: "update",
          resource: "questionResponses",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess,
          onFailure,
        }
      ); */
    },
    [updateQuestionResponse],
  );

  const SellerEditToolbar = (props: ToolbarProps) => {
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <NoDeleteToolbar {...props}>
        <Button
          icon={<DoneIcon />}
          style={{ marginRight: 20 }}
          variant = 'contained'
          type="button"
          label="Done"
          disabled={isFormDirty}
          onClick={
            () => {
              if (!nextMeetingInvite && scheduledMeetingInvites.length === 0) {
                redirect("/feedback");
              } else {
                redirect("/");
              }
            }
          }
        />
        <SaveButton
          label="Save"
        />
      </NoDeleteToolbar>
    );
  };

  useEffect(() => { // NOTE: get the meeting invite information
    if (currentProfile) {
      logger.info("meetingInviteId", meetingInviteId);
      dataProvider.getOne(
        "meetingInvites",
        {
          id: meetingInviteId,
        },
      ).then(
        (m) => {
          logger.info("meetingInvites results", { m, currentProfile });
          if (m && m.data && m.data.owner === currentProfile.userId) {
            const currentMeetingInvite = m.data as MeetingInvite;
            const {
              status,
              sessionStatus,
              // productId,
            } = currentMeetingInvite;

            let updateRequired = false;
            let updatedData: any = {
              id: currentMeetingInvite.id,
              _version: currentMeetingInvite._version,
            }
            let previousData: any = {
              id: currentMeetingInvite.id,
            };
            if (
              currentMeetingInvite.isReadinessTest // ||
              // status !== MeetingStatus.ENDED ||
              // sessionStatus !== MeetingRoomSessionStatus.INACTIVE
            ) { // NOTE: end meeting if a readiness test
              updatedData = {
                ...updatedData,
                status: MeetingStatus.ENDED,
                sessionStatus: MeetingRoomSessionStatus.INACTIVE,
              };
              previousData = {
                ...previousData,
                status,
                sessionStatus,
              };
              updateRequired = true;
            }

            if (updateRequired) {
              const updatedMeetingInvite = JSON.parse(JSON.stringify(currentMeetingInvite));
              logger.info(`updating meetingInvite ${currentMeetingInvite.id}`);
              let  updateMeetingBreakRestInput: UpdateMeetingBreakRestInput  = { };
              if (status) {
                updatedMeetingInvite.status = status;
                updateMeetingBreakRestInput = {
                  // @ts-ignore
                  status,
                };
              }
              if (sessionStatus) {
                updatedMeetingInvite.sessionStatus = sessionStatus;
                updateMeetingBreakRestInput = {
                  ...updateMeetingBreakRestInput,
                  sessionStatus,
                };
              }
              logger.info("updateMeetingBreakRestInput", updateMeetingBreakRestInput);
              updateMeetingBreak(updateMeetingBreakRestInput, currentMeetingInvite.id).then(
                (meetingBreakUpdateResults) => {
                  logger.info("meetingBreakUpdateResults 0", meetingBreakUpdateResults);
                  setMeetingInvite(updatedMeetingInvite);
                  meetingInviteRef.current = updatedMeetingInvite;
                  if (isSmall && step === 1 && meetingBreakUpdateResults.feedbackSummary) { // isInterested(updatedMeetingInvite)
                    setStep(2);
                  }
                  if (
                    meetingBreakUpdateResults.meetingInvitationType === MeetingInvitationType.INVITEE &&
                    isInterested(meetingBreakUpdateResults)
                  ) {
                    setShowContactRequestTimeframe(true);
                  }
                }
              ).catch(
                (err) => {
                  logger.error("failed to update meetingBreak", err);
                  setMeetingInvite(currentMeetingInvite);
                  meetingInviteRef.current = currentMeetingInvite;
                  if (currentMeetingInvite.feedbackSummary) {
                    setStep(2);
                  }
                  if (
                    currentMeetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE &&
                    isInterested(currentMeetingInvite)
                  ) {
                    setShowContactRequestTimeframe(true);
                  }
                }
              );
            } else {
              setMeetingInvite(currentMeetingInvite);
              meetingInviteRef.current = currentMeetingInvite;
              if (currentMeetingInvite.feedbackSummary) {
                setStep(2);
              }
              if (
                currentMeetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE &&
                isInterested(currentMeetingInvite)
              ) {
                setShowContactRequestTimeframe(true);
              }
            }
            // NOTE: if buyer is interested show contact request info
            if (
              currentMeetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE
            ) {
              if (isInterested(currentMeetingInvite)) {
                setShowContactRequestTimeframe(true);
              }
              if (currentMeetingInvite.feedbackSummary) {
                setStep(2);
              }
            }
          } else {
            if (m && m.data && m.data.owner !== currentProfile.userId) {
              logger.warn("meetingInvite does not belong to the user.", { meetingInvite: m.data, currentProfile });
              redirect('/');
            } else {
              logger.warn("meetingInvite returned empty result.", { m, currentProfile });
            }
          }
        }
      );
    }
  }, [currentProfile]);

  useEffect(() => { // NOTE: get the product information for the meeting invite
    if (meetingInvite) {
      if (meetingInvite.productId) {
        getMeetingProductInformation(
          meetingInvite,
          dataProvider,
        ).then(
          (productInformation) => {
            logger.info("meetingProductInformation", productInformation);
            setMeetingProductInformation(productInformation);
          }
        ).catch(
          (err) => {
            logger.error("error getMeetingProductInformation", err);
          }
        );
        // NOTE: Store an empty product question response
        //       This is the "notes" feature for buyers and sellers
        createOrUpdateQuestionResponse(
          dataProvider,
          meetingInvite,
          "",
          false,
        ).then(
          (updatedQuestionResponse) => {
            logger.info("updatedQuestionResponse", updatedQuestionResponse);
            // setQuestionResponse(updatedQuestionResponse as QuestionResponse);
            questionResponseRef.current = updatedQuestionResponse as QuestionResponse;
          }
        ).catch(
          (err) => {
            logger.error("error updatingQuestionResponse", err);
          }
        ).finally(
          () => {
            logger.info("loaded with product");
            if (!isLoaded) {
              setIsLoaded(true);
            }
          }
        );
      } else {
        logger.info("loaded with no product");
        if (!isLoaded) {
          setIsLoaded(true);
        }
      }
    }
  }, [meetingInvite?.id]); // saveCounter

  useEffect(() => { // NOTE: get the product information for the meeting invite
    if (meetingProductInformation) {
      const {
        buyerTeamId,
        buyerTeam,
      } = meetingProductInformation;
      if (buyerTeamId && buyerTeam && !buyerTeam.privacyEnabled) {
        getTeamImageByTeamId(
          buyerTeamId,
          dataProvider,
        ).then(
          (teamImage) => {
            logger.info("teamImage", teamImage);
            if (teamImage) {
              setBuyerTeamImage(teamImage);
            }
          }
        ).catch(
          (err) => {
            logger.error("getTeamImage error", err);
          }
        );
      }
    }
  }, [meetingProductInformation?.product?.id]);

  useEffect(() => {
    logger.info("seconds remaining useEffect", { nextMeetingInvite, meetingInvite: meetingInviteRef.current });
    if (nextMeetingInvite && meetingInviteRef.current && nextMeetingInvite.id !== meetingInviteRef.current.id) {
      const checkerInterval = setInterval(
        () => {
          try {
            const nowTimestamp = getCurrentDateTime(cachedServerTimeDifference, clockSyncEnabled).toUnixInteger();
            const {
              startDateTime,
            } = nextMeetingInvite;
            const startTimestamp = awsDateTimeStringToDateTime(
              startDateTime,
            ).toUnixInteger();
            const secondsRemaining = Math.round(startTimestamp - nowTimestamp);
            if (secondsRemaining === 15 || secondsRemaining < 15) {
              logger.info("nowTimestamp", nowTimestamp);
              logger.info("secondsRemaining", secondsRemaining);
              if (checkerInterval) {
                try {
                  clearInterval(checkerInterval);
                } catch (err) {}
              }
              if (saveTimeout) {
                try {
                  clearTimeout(saveTimeout as NodeJS.Timeout);
                } catch (err) {}
              }
              redirect("/");
            }
          } catch(err) {
            logger.error("error calculating secondsRemaining", err);
          }
        },
        1000,
      );
      return () => {
        if (checkerInterval) {
          try {
            clearInterval(checkerInterval);
          } catch (err) {}
        }
      }
    }
  }, [nextMeetingInvite, meetingInviteRef.current, clockSyncEnabled, cachedServerTimeDifference]);

  const scheduledMeetingInvites = meetingInvitesState.scheduledMeetings;

  return (
    <>
    {isLoaded ? (
    <>
      <Grid
        container
        spacing={3}
        direction={isSmall ? "column" : "row"}
      >
        {!isSmall && (
        <Grid item md={8}>
          <img src={inAppLogo ? inAppLogo.url : (defaultLogo ? defaultLogo.url : "/img/logo-connect.png")} height={isSmall ? "55" : "110"} />
        </Grid>
        )}
        <Grid item xs={12} sm={12} md={4} alignContent={isSmall ? undefined : "flex-end"}>
          {(nextMeetingInvite && currentProfile) && (
          <MeetingCountdown
            {...meetingsState}
            nextMeetingInvite={nextMeetingInvite}
            currentProfile={currentProfile}
            useEndDateTime={false}
            clockSyncEnabled={clockSyncEnabled}
            cachedServerTimeDifference={cachedServerTimeDifference}
            inAppLogo={inAppLogo}
            defaultLogo={defaultLogo}
          />
          )}
        {(!nextMeetingInvite && scheduledMeetingInvites.length === 0) && (
        <GenericCountdown
          expiryDate={feedbackRedirectDelay}
          redirectUrl="/feedback"
        />
        )}
        </Grid>
      </Grid>
      <div className={classes.tableRow}>
      {!isSmall && (
        <div className={classes.tableMenuColumn}>
          <ScheduledMeetingsMenu
            title={"Upcoming meetings"}
            meetings={scheduledMeetingInvites}
            isSeller={isSeller}
            isBuyer={isBuyer}
            showBackToMeetingsLink={true}
            clockSyncEnabled={clockSyncEnabled}
            cachedServerTimeDifference={cachedServerTimeDifference}
          />
        </div>
      )}
        <div className={classes.tableContentColumn}>
          <Grid
            container
            spacing={isSmall ? 5 : 10}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={12} sm={12}>
            {(isSeller) ? (
              <Typography variant={isSmall ? undefined : "h5"} className={classes.boldText}>You just met with...</Typography>
            ) : (
              <Typography variant={isSmall ? undefined : "h5"} className={classes.boldText}>You just saw...</Typography>
            )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                    <TableRow key="1">
                      {!isSmall && (meetingInviteRef.current && meetingInviteRef.current.meetingInvitationType === MeetingInvitationType.ORGANIZER && buyerTeamImage) && (
                      <TableCell className={classes.tableCell}>
                        <AmplifyImageField
                          source="image"
                          title="title"
                          label=""
                          resource="teamImages"
                          basePath="/teamImages"
                          record={buyerTeamImage}
                        />
                      </TableCell>
                      )}
                      {!isSmall && (meetingProductInformation && meetingInviteRef.current && meetingInviteRef.current.meetingInvitationType === MeetingInvitationType.INVITEE) && (
                      <TableCell rowSpan={isSmall ? 1 : 2} className={classes.tableCell}>
                        <RecordContextProvider value={meetingProductInformation.product}>
                          <SimpleShowLayout>
                            <ReferenceManyField
                              reference="productImages"
                              target="productId"
                              perPage={1}
                              label=""
                              filter={{ listProductImagesByProductId: {} }}
                            >
                              <SingleFieldList>
                                <AmplifyImageField
                                  source="image"
                                  title="title"
                                  label=""
                                />
                              </SingleFieldList>
                            </ReferenceManyField>
                          </SimpleShowLayout>
                        </RecordContextProvider>
                      </TableCell>
                      )}
                      {(meetingInviteRef.current && meetingInviteRef.current.meetingInvitationType === MeetingInvitationType.ORGANIZER) && (
                      <TableCell className={classes.tableCell}>
                        {meetingProductInformation && meetingProductInformation.buyerTeam && !meetingProductInformation.buyerTeam.privacyEnabled && (
                        <Typography className={classes.productName}>
                        {meetingProductInformation.buyerTeam.name}
                        </Typography>
                        )}
                        {!isSmall && meetingProductInformation && meetingProductInformation.buyerTeam && !meetingProductInformation.buyerTeam.privacyEnabled && (
                        <Typography>
                        {meetingProductInformation.buyerTeam.description}
                        </Typography>
                        )}
                      </TableCell>
                      )}
                      {(meetingProductInformation && meetingInviteRef.current && meetingInviteRef.current.meetingInvitationType === MeetingInvitationType.INVITEE) && (
                      <TableCell className={classes.tableCell}>
                        <Typography className={classes.productName}>
                        {meetingProductInformation.product.name}
                        </Typography>
                        {!isSmall && (
                        <Typography>
                        {meetingProductInformation.product.description}
                        </Typography>
                        )}
                      </TableCell>
                      )}
                    </TableRow>
                  {(questionResponseRef.current && meetingInviteRef.current) && (
                    <TableRow key={`${questionResponseRef.current.id}`}>
                      <TableCell className={classes.tableCell} colSpan={2}>
                      {(meetingInviteRef.current.meetingInvitationType === MeetingInvitationType.ORGANIZER) ? (
                        <>
                          {meetingProductInformation && (
                          <Edit // NOTE: this form is for editing a seller's note
                            id={questionResponseRef.current.id} // @ts-ignore
                            record={questionResponseRef.current}
                            resource="questionResponses"
                            basePath="/questionResponses"
                            title={''}
                            undoable={false}
                            mutationMode="pessimistic"
                            onSuccess={(response: any) => {
                              const {
                                data,
                              } = response;
                              logger.info("onSuccess.data", data);
                              /* if (!nextMeetingInvite && scheduledMeetingInvites.length === 0) {
                                redirect("/feedback");
                              } else {
                                redirect("/");
                              } */
                            }}
                          >
                            <SimpleForm
                              toolbar={<SellerEditToolbar />}
                              size="small"
                              compact
                              save={save}
                              redirect={false}
                              warnWhenUnsavedChanges={false}
                              resetOptions={{"keepDirtyValues": true}}
                              resetoptions={{"keepDirtyValues": true}}
                            >
                              <Chip label="Note to Surgeon" color="primary" className={classes.chip} />
                              <TextInput
                                size="small"
                                compact
                                multiline
                                source="response"
                                label="Limit 500 characters and spaces"
                                minRows="4"
                                maxLength="500"
                                fullWidth
                                onChange={
                                  (e) => {
                                    if (!isFormDirty) {
                                      setIsFormDirty(true);
                                    }
                                    if (saveTimeout) {
                                      clearTimeout(saveTimeout as NodeJS.Timeout);
                                    }
                                    const id = `${questionResponseRef.current?.id}`;
                                    let originalQuestionResponse = questionResponsesData.get(id);
                                    if (originalQuestionResponse === undefined) {
                                      logger.warn("1 originalQuestionResponse is undefined", originalQuestionResponse);
                                      // @ts-ignore
                                      originalQuestionResponse = {
                                        id,
                                        // @ts-ignore
                                        _version: questionResponseRef.current?._version
                                      };
                                    }
                                    logger.info("originalQuestionResponse", originalQuestionResponse);
                                    // @ts-ignore
                                    const responseData: QuestionResponse = {
                                      ...originalQuestionResponse,
                                      id,
                                      response: e.target.value,
                                      // @ts-ignore
                                      _version: questionResponseRef.current?._version
                                    };
                                    logger.info("newQuestionResponse", responseData);
                                    logger.info("questionResponsesData", questionResponsesData);
                                    const updatedQuestionResponsesData = clone(questionResponsesData);
                                    logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
                                    updatedQuestionResponsesData.set(
                                      id,
                                      responseData,
                                    );
                                    setQuestionResponsesData(updatedQuestionResponsesData);
                                    const newSaveTimeout = setTimeout(
                                      async () => {
                                        setSaveTimeout(0);
                                        logger.info("calling saved...", responseData);
                                        // const data = await responseData);
                                        await updateQuestionResponse(
                                          transformQuestionResponse(responseData),
                                          `${questionResponseRef.current?.id}`
                                        ).then(
                                          (saveResponse) => {
                                            logger.info("save successful", saveResponse);
                                            onSuccess({ data: responseData });
                                            // setIsFormDirty(false);
                                          }
                                        ).catch(
                                          (err) => {
                                            logger.error("save failed", err);
                                            onFailure(err);
                                          }
                                        );
                                        /* await mutate(
                                          {
                                            type: "update",
                                            resource: "questionResponses",
                                            payload: { data: responseData },
                                          },
                                          {
                                            returnPromise: true,
                                            onSuccess,
                                            onFailure,
                                          }
                                        ); */
                                      },
                                      3000
                                    );
                                    setSaveTimeout(newSaveTimeout);
                                  }
                                }
                              />
                              <Chip label="Private Notes" color="secondary" className={classes.chip} /><br/>
                              <TextInput
                                size="small"
                                compact
                                multiline
                                source="privateNotes"
                                label=""
                                minRows="4"
                                fullWidth
                                onChange={
                                  (e) => {
                                    if (!isFormDirty) {
                                      setIsFormDirty(true);
                                    }
                                    if (saveTimeout) {
                                      clearTimeout(saveTimeout as NodeJS.Timeout);
                                    }
                                    // @ts-ignore
                                    const id = questionResponseRef.current.id;
                                    let originalQuestionResponse = questionResponsesData.get(id);
                                    if (originalQuestionResponse === undefined) {
                                      logger.warn("2 originalQuestionResponse is undefined", originalQuestionResponse);
                                      // @ts-ignore
                                      originalQuestionResponse = {
                                        id,
                                        // @ts-ignore
                                        _version: questionResponseRef.current?._version
                                      };
                                    }
                                    logger.info("originalQuestionResponse", originalQuestionResponse);
                                    // @ts-ignore
                                    const responseData: QuestionResponse = {
                                      ...originalQuestionResponse,
                                      id,
                                      privateNotes: e.target.value,
                                      // @ts-ignore
                                      _version: questionResponseRef.current?._version,
                                    };
                                    logger.info("newQuestionResponse", responseData);
                                    logger.info("questionResponsesData", questionResponsesData);
                                    const updatedQuestionResponsesData = clone(questionResponsesData);
                                    logger.info("updatedQuestionResponsesData", updatedQuestionResponsesData);
                                    updatedQuestionResponsesData.set(
                                      id,
                                      responseData,
                                    );
                                    setQuestionResponsesData(updatedQuestionResponsesData);
                                    const newSaveTimeout = setTimeout(
                                      async () => {
                                        setSaveTimeout(0);
                                        logger.info("calling saved...", responseData);
                                        await updateQuestionResponse(
                                          transformQuestionResponse(responseData),
                                          `${questionResponseRef.current?.id}`
                                        ).then(
                                          (saveResponse) => {
                                            logger.info("save successful", saveResponse);
                                            onSuccess({ data: responseData });
                                          }
                                        ).catch(
                                          (err) => {
                                            logger.error("save failed", err);
                                            onFailure(err);
                                          }
                                        );
                                        /* await mutate(
                                          {
                                            type: "update",
                                            resource: "questionResponses",
                                            payload: { data: responseData },
                                          },
                                          {
                                            returnPromise: true,
                                            onSuccess,
                                            onFailure,
                                          }
                                        ); */
                                      },
                                      3000
                                    );
                                    setSaveTimeout(newSaveTimeout);
                                  }
                                }
                              />
                            </SimpleForm>
                          </Edit>
                          )}
                        </>
                      ) : ( // NOTE: the buyer input layout
                        <TableContainer>
                          <Table size="small">
                            <TableBody>
                              <TableRow key="2-1">
                                {(!isSmall || step === 1) && (
                                <TableCell className={classes.tableCell}>
                                  <Typography variant="body1" className={classes.boldText}>
                                    Are you interested in this product?
                                  </Typography>
                                </TableCell>
                                )}
                                {!isSmall && (
                                <TableCell className={classes.tableCell}>
                                {showContactRequestTimeframe && (
                                  <Typography variant="body1" className={classes.boldText}>
                                    Time-frame for considering this solution:
                                  </Typography>
                                )}
                                </TableCell>
                                )}
                              </TableRow>
                              <TableRow key="2-2">
                                {(!isSmall || step === 1) && (
                                <TableCell className={classes.tableCell}>
                                  <Edit
                                    {...editProps}
                                    title={ (editProps.record && editProps.record.product) ? `${editProps.record.product.name}` : ""}
                                    undoable={false}
                                    mutationMode="pessimistic"
                                  >
                                    <SimpleForm
                                      toolbar={false}
                                      size="small"
                                      compact
                                    >
                                      <RadioButtonGroupInput
                                        size="small"
                                        compact
                                        source="feedbackSummary"
                                        label=""
                                        className={classes.boldText}
                                        choices={FEEDBACK_SUMMARY_CHOICES}
                                        translateChoice={false}
                                        row={false}
                                        onChange={
                                          (selectedValue) => {
                                            logger.info(
                                              "feedbackSummary selectedValue", selectedValue
                                            );
                                            const newFeedbackSummary = `${selectedValue}`;
                                            logger.info(
                                              "newFeedbackSummary", newFeedbackSummary
                                            );
                                            if (meetingInviteRef.current) {
                                              dataProvider.getOne(
                                                "meetingInvites",
                                                {
                                                  id: meetingInviteRef.current.id,
                                                },
                                              ).then(
                                                (m) => {
                                                  logger.info("meetingInvite results.", m);
                                                  if (m && m.data && m.data.owner === currentProfile?.userId) {
                                                    const currentMeetingInvite = m.data as MeetingInvite;
                                                    if (
                                                      currentMeetingInvite.feedbackSummary !== newFeedbackSummary
                                                    ) {
                                                      const updatedMeetingInvite = JSON.parse(JSON.stringify(currentMeetingInvite));
                                                      updatedMeetingInvite.feedbackSummary = newFeedbackSummary;
                                                      logger.info(`setting feedbackSummary for meetingInvite ${meetingInviteRef.current?.id}`, updatedMeetingInvite);
                                                      // NOTE: update MeetingInvite and set feedbackSummary
                                                      logger.info(`updating meetingInvite ${updatedMeetingInvite.id}`);
                                                      let  updateMeetingBreakRestInput: UpdateMeetingBreakRestInput  = { feedbackSummary: updatedMeetingInvite.feedbackSummary };
                                                      logger.info("updateMeetingBreakRestInput 1", updateMeetingBreakRestInput);
                                                      updateMeetingBreak(updateMeetingBreakRestInput, updatedMeetingInvite.id).then(
                                                        (meetingBreakUpdateResults) => {
                                                          logger.info("meetingBreakUpdateResults 1", meetingBreakUpdateResults);
                                                          notify("Saved", { type: "success" });
                                                          if (step !== 2) {
                                                            setStep(2);
                                                          }
                                                          if (
                                                            [
                                                              'very_interested',
                                                              'somewhat_interested',
                                                            ].includes(updatedMeetingInvite.feedbackSummary)
                                                          ) {
                                                            setShowContactRequestTimeframe(true);
                                                          } else {
                                                            setShowContactRequestTimeframe(false);
                                                            setTimeout(
                                                              () => {
                                                                if (saveTimeout) {
                                                                  try {
                                                                    clearTimeout(saveTimeout as NodeJS.Timeout);
                                                                  } catch (err) {}
                                                                }
                                                                if (!nextMeetingInvite && scheduledMeetingInvites.length === 0) {
                                                                  redirect("/feedback");
                                                                } else {
                                                                  redirect("/");
                                                                }
                                                              },
                                                              1000,
                                                            );
                                                          }
                                                        }
                                                      ).catch(
                                                        (err) => {
                                                          logger.error("failed to update meetingInvite", err);
                                                          notify(err.message, { type: "warning" });
                                                        }
                                                      );
                                                        // setMeetingInvite(updatedMeetingInvite);
                                                        meetingInviteRef.current = updatedMeetingInvite;
                                                        logger.info("updatedMeetingInvite", updatedMeetingInvite);
                                                      }
                                                    } else {
                                                      logger.info("meetingInvite.feedbackSummary is unchanged.")
                                                    }
                                                  }
                                              );
                                            }
                                          }
                                        }
                                      />
                                    </SimpleForm>
                                  </Edit>
                                </TableCell>
                                )}
                                {!isSmall && (
                                <TableCell className={classes.tableCell}>
                                  {showContactRequestTimeframe && (
                                  <Edit
                                    {...editProps}
                                    title={ (editProps.record && editProps.record.product) ? `${editProps.record.product.name}` : ""}
                                    undoable={false}
                                    mutationMode="pessimistic"
                                  >
                                    <SimpleForm
                                      toolbar={false}
                                      size="small"
                                      compact
                                    >
                                      <RadioButtonGroupInput
                                        size="small"
                                        compact
                                        source="contactRequestTimeframe"
                                        label=""
                                        className={classes.boldText}
                                        choices={CONTACT_REQUEST_TIMEFRAME_CHOICES}
                                        translateChoice={false}
                                        row={false}
                                        onChange={
                                          (selectedValue) => {
                                            logger.info(
                                              "contactRequestTimeframe selectedValue", selectedValue
                                            );
                                            const newContactRequestTimeframe = `${selectedValue}`;
                                            logger.info(
                                              "newContactRequestTimeframe", newContactRequestTimeframe
                                            );
                                            if (meetingInviteRef.current && newContactRequestTimeframe) {
                                              dataProvider.getOne(
                                                "meetingInvites",
                                                {
                                                  id: meetingInviteRef.current.id,
                                                },
                                              ).then(
                                                (m) => {
                                                  logger.info("meetingInvite results.", m);
                                                  if (m && m.data && m.data.owner === currentProfile?.userId) {
                                                    const currentMeetingInvite = m.data as MeetingInvite;
                                                    if (
                                                      currentMeetingInvite.contactRequestTimeframe !== newContactRequestTimeframe
                                                    ) {
                                                      const updatedMeetingInvite = JSON.parse(JSON.stringify(currentMeetingInvite));
                                                      updatedMeetingInvite.contactRequestTimeframe = newContactRequestTimeframe;
                                                      logger.info(`setting contactRequestTimeframe for meetingInvite ${meetingInviteRef.current?.id}`, updatedMeetingInvite);
                                                      // NOTE: update MeetingInvite and set contactRequestTimeframe
                                                      logger.info(`updating meetingInvite ${updatedMeetingInvite.id}`);
                                                      let  updateMeetingBreakRestInput: UpdateMeetingBreakRestInput  = { contactRequestTimeframe: updatedMeetingInvite.contactRequestTimeframe };
                                                      logger.info("updateMeetingBreakRestInput 2", updateMeetingBreakRestInput);
                                                      updateMeetingBreak(updateMeetingBreakRestInput, updatedMeetingInvite.id).then(
                                                        (meetingBreakUpdateResults) => {
                                                          logger.info("meetingBreakUpdateResults", meetingBreakUpdateResults);
                                                          notify("Saved", { type: "success" });
                                                          setTimeout(
                                                            () => {
                                                              if (saveTimeout) {
                                                                try {
                                                                  clearTimeout(saveTimeout as NodeJS.Timeout);
                                                                } catch (err) {}
                                                              }
                                                              if (!nextMeetingInvite && scheduledMeetingInvites.length === 0) {
                                                                redirect("/feedback");
                                                              } else {
                                                                redirect("/");
                                                              }
                                                            },
                                                            1000,
                                                          );
                                                        }
                                                      ).catch(
                                                        (err) => {
                                                          logger.error("failed to update meetingInvite", err);
                                                          notify(err.message, { type: "warning" });
                                                        }
                                                      );
                                                      // setMeetingInvite(updatedMeetingInvite);
                                                      meetingInviteRef.current = updatedMeetingInvite;
                                                      logger.info("updatedMeetingInvite", updatedMeetingInvite);
                                                    } else {
                                                      logger.info("meetingInvite.contactRequestTimeframe is unchanged.")
                                                    }
                                                  }
                                                }
                                              );
                                            }
                                          }
                                        }
                                      />
                                    </SimpleForm>
                                  </Edit>
                                  )}
                                </TableCell>
                                )}
                              </TableRow>
                              {(isSmall && step === 2) && (
                                <>
                              <TableRow key="3-0">
                                <TableCell className={classes.tableCell}>
                                {showContactRequestTimeframe && (
                                  <Typography variant="body1" className={classes.boldText}>
                                    Time-frame for considering this solution:
                                  </Typography>
                                )}
                                </TableCell>
                              </TableRow>
                              <TableRow key="3-1">
                                <TableCell className={classes.tableCell}>
                                  {showContactRequestTimeframe && (
                                  <Edit
                                    {...editProps}
                                    title={ (editProps.record && editProps.record.product) ? `${editProps.record.product.name}` : ""}
                                    undoable={false}
                                    mutationMode="pessimistic"
                                  >
                                    <SimpleForm
                                      toolbar={false}
                                      size="small"
                                      compact
                                    >
                                      <RadioButtonGroupInput
                                        size="small"
                                        compact
                                        source="contactRequestTimeframe"
                                        label=""
                                        className={classes.boldText}
                                        choices={CONTACT_REQUEST_TIMEFRAME_CHOICES}
                                        translateChoice={false}
                                        row={false}
                                        onChange={
                                          (selectedValue) => {
                                            logger.info(
                                              "contactRequestTimeframe selectedValue", selectedValue
                                            );
                                            const newContactRequestTimeframe = `${selectedValue}`;
                                            logger.info(
                                              "newContactRequestTimeframe", newContactRequestTimeframe
                                            );
                                            if (meetingInviteRef.current && newContactRequestTimeframe) {
                                              dataProvider.getOne(
                                                "meetingInvites",
                                                {
                                                  id: meetingInviteRef.current.id,
                                                },
                                              ).then(
                                                (m) => {
                                                  logger.info("meetingInvite results.", m);
                                                  if (m && m.data && m.data.owner === currentProfile?.userId) {
                                                    const currentMeetingInvite = m.data as MeetingInvite;
                                                    if (
                                                      currentMeetingInvite.contactRequestTimeframe !== newContactRequestTimeframe
                                                    ) {
                                                      const updatedMeetingInvite = JSON.parse(JSON.stringify(currentMeetingInvite));
                                                      updatedMeetingInvite.contactRequestTimeframe = newContactRequestTimeframe;
                                                      logger.info(`setting contactRequestTimeframe for meetingInvite ${meetingInviteRef.current?.id}`, updatedMeetingInvite);
                                                      logger.info(`updating meetingInvite ${updatedMeetingInvite.id}`);
                                                      let  updateMeetingBreakRestInput: UpdateMeetingBreakRestInput  = { contactRequestTimeframe: updatedMeetingInvite.contactRequestTimeframe };
                                                      logger.info("updateMeetingBreakRestInput 3", updateMeetingBreakRestInput);
                                                      // NOTE: update MeetingInvite and set contactRequestTimeframe
                                                      updateMeetingBreak(updateMeetingBreakRestInput, updatedMeetingInvite.id).then(
                                                        (meetingBreakUpdateResults) => {
                                                          logger.info("meetingInviteUpdateResults", updateMeetingBreakRestInput);
                                                          notify("Saved", { type: "success" });
                                                          setTimeout(
                                                            () => {
                                                              if (saveTimeout) {
                                                                try {
                                                                  clearTimeout(saveTimeout as NodeJS.Timeout);
                                                                } catch (err) {}
                                                              }
                                                              if (!nextMeetingInvite && scheduledMeetingInvites.length === 0) {
                                                                redirect("/feedback");
                                                              } else {
                                                                redirect("/");
                                                              }
                                                            },
                                                            1000,
                                                          );
                                                        }
                                                      ).catch(
                                                        (err) => {
                                                          logger.error("failed to update meetingInvite", err);
                                                          notify(err.message, { type: "warning" });
                                                        }
                                                      );
                                                      meetingInviteRef.current = updatedMeetingInvite;
                                                      // setMeetingInvite(updatedMeetingInvite);
                                                      logger.info("updatedMeetingInvite", updatedMeetingInvite);
                                                    } else {
                                                      logger.info("meetingInvite.contactRequestTimeframe is unchanged.")
                                                    }
                                                  }
                                                }
                                              );
                                            }
                                          }
                                        }
                                      />
                                    </SimpleForm>
                                  </Edit>
                                  )}
                                </TableCell>
                              </TableRow>
                                </>
                              )}
                              <TableRow key="2-3">
                                <TableCell className={classes.tableCell} colSpan={isSmall ? 1 : 2}>
                                  <Typography variant="body2">
                                    If interested, you'll have an opportunity to provide more information to the seller once all meetings are complete.
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow key="2-4">
                                <TableCell className={classes.tableCell} colSpan={isSmall ? 1 : 2}>
                                  {nextMeetingInvite && (
                                  <Button startIcon={<CameraFrontIcon/>} component={Link} label={'Next up'} to="/" />
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                      </TableCell>
                    </TableRow>
                  )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
    ) : <LinearProgress />}
    </>
  );
};
