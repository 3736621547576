import React, { useContext, useEffect, useState } from "react";
import {
  RecordContextProvider,
  ReferenceManyField,
  SimpleShowLayout,
  SingleFieldList,
  useAuthenticated,
  useRedirect,
  setAutomaticRefresh,
  Button,
  LinearProgress,
} from "react-admin";
import { API, Logger } from "aws-amplify";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
  Theme,
} from "@material-ui/core";
import MeetingCountdown from "../components/MeetingCountdown";
import { MeetingsStateContext } from "../App";
import ScheduledMeetingsMenu from "../components/ScheduledMeetingsMenu";
import { AmplifyImageField } from "../components/AmplifyImageField";
import Paper from "@material-ui/core/Paper";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
  ListMeetingInvitesByProfileIdQuery,
  MeetingInvite,
  MeetingStatus,
  ModelSortDirection,
} from "../API";
import { AWS_TIMESTAMP_AS_LUXON_FORMAT } from "../constants";
import { listMeetingInvitesByProfileId } from "../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import { sellerBuyerStyles } from "../layout/theme";
import { awsDateTimeStringToDateTime, getCurrentDateTime, getMeetingInvitesCount } from "../lib/helpers";
import { useDispatch } from "react-redux";
import { usePreferences } from "@react-admin/ra-preferences";

const logger = new Logger("BuyerUpcomingProducts");


/**
 * Buyer Upcoming Products
 * 
 * @returns {JSX.Element}
 */
export const BuyerUpcomingProductsPage = () => {

  useAuthenticated();
  const redirect = useRedirect();
  const classes = sellerBuyerStyles();
  const dispatch = useDispatch();
  dispatch(setAutomaticRefresh(false));
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [ isFormDirty, setIsFormDirty ] = useState<boolean>(false);
  const [ isNoMeetingInvites, setIsNoMeetingInvites ] = useState<boolean>(false);
  const [ data, setData ] = useState<MeetingInvite[]>([]);
  const [ page, setPage ] = useState(1);
  const [ totalCount, setTotalCount] = useState(0);
  const [ hasPreviousPage, setHasPreviousPage ] = useState(false);
  const [ hasNextPage, setHasNextPage ] = useState(false);
  const [ tokensByPageNumber, setTokensByPageNumber ] = useState<Map<string, string | null>>(new Map());
  const meetingsState = useContext(MeetingsStateContext);
  const [ clockSyncEnabled ] = usePreferences("clockSyncEnabled", false);
  const [ cachedServerTimeDifference ] = usePreferences("cachedServerTimeDifference", 0);
  const {
    meetingInvitesState,
    nextMeetingInvite,
    currentProfile,
    isSeller,
    isBuyer,
    defaultLogo,
    inAppLogo,
    meetingDataIsLoaded,
  } = meetingsState;
  const scheduledMeetingInvites = meetingInvitesState.scheduledMeetings;
  const getNextPage = () => {
    setIsLoaded(false);
    setPage((page + 1) > totalCount ? 1 : page + 1);
  }
  const getPreviousPage = () => {
    setIsLoaded(false);
    setPage((page - 1) < 1 || (page - 1) > totalCount ? 1 : page - 1);
  }
  const nextPageNumber = () => `${page + 1}`;

  useEffect(() => {
    if (currentProfile) {
      logger.info("BuyerUpcomingProducts called...");
      const now = getCurrentDateTime(cachedServerTimeDifference, clockSyncEnabled).toUTC();
      const daysAhead = now.plus({ days: 3 });
      const startDateTimeRange = now.toFormat(
        AWS_TIMESTAMP_AS_LUXON_FORMAT
      );
      const endDateTimeRange = daysAhead.toFormat(
        AWS_TIMESTAMP_AS_LUXON_FORMAT
      );
      getMeetingInvitesCount(
        MeetingStatus.SCHEDULED,
        startDateTimeRange,
        endDateTimeRange,
        currentProfile.id,
      ).then(
        (countResult) => {
          logger.info("getMeetingInvitesCount result", countResult);
          setTotalCount(countResult.count);
          /* if (countResult.count === 0) {
            redirect("/");
          } */
        }
      ).catch(
        (err: Error) => {
          logger.error(
            "getMeetingInvitesCount error",
            err,
          );
        }
      );
    }
  }, [currentProfile, clockSyncEnabled, cachedServerTimeDifference]);

  useEffect(() => { // NOTE: new query for meeting and questions response (notes)
    if (currentProfile && totalCount > 0) {
      const now = getCurrentDateTime(cachedServerTimeDifference, clockSyncEnabled).toUTC();
      const daysAhead = now.plus({ days: 3 });
      const startDateTimeRange = now.toFormat(
        AWS_TIMESTAMP_AS_LUXON_FORMAT
      );
      const endDateTimeRange = daysAhead.toFormat(
        AWS_TIMESTAMP_AS_LUXON_FORMAT
      );
      const nextToken = tokensByPageNumber.get(`${page}`);
      logger.info('pagination - page', page);
      logger.info(`pagination - token for page ${page}`, nextToken);
      (API.graphql({
        query: listMeetingInvitesByProfileId,
        variables: {
          limit: 1,
          profileId: currentProfile.id,
          startDateTime: {
            between: [ 
              startDateTimeRange,
              endDateTimeRange,
            ],
          },
          sortDirection: ModelSortDirection.ASC,
          filter: {
            status: {
              eq: MeetingStatus.SCHEDULED,
            },
          },
          ...(nextToken && {
            nextToken,
          }),
        },
      }) as Promise<GraphQLResult>).then(
        async (queryResult) => {
          if (queryResult.errors || !queryResult.data) {
            logger.error("queryResult", queryResult);
            throw new Error("Data provider error");
          }
          let numberOfResults = 0;
          let currentNextToken: null | string = null;
          if (page === 1) {
            if (hasPreviousPage) {
              setHasPreviousPage(false);
            }
          } else {
            if (!hasPreviousPage) {
              setHasPreviousPage(true);
            }
          }
          if (hasNextPage && (totalCount === page || page > totalCount)) {
            setHasNextPage(false);
          }
          const listMeetingInvitesByProfileIdResults = (queryResult.data as ListMeetingInvitesByProfileIdQuery).listMeetingInvitesByProfileId;
          if (listMeetingInvitesByProfileIdResults && listMeetingInvitesByProfileIdResults.items) {
            if (listMeetingInvitesByProfileIdResults.nextToken) {
              logger.info("pagination - listMeetingInvitesByProfileIdResults.nextToken", listMeetingInvitesByProfileIdResults.nextToken);
              currentNextToken = listMeetingInvitesByProfileIdResults.nextToken;
              const nextPageToken = tokensByPageNumber.get(nextPageNumber());
              if (!nextPageToken) {
                const updatedTokensByPageNumber = new Map(tokensByPageNumber);
                updatedTokensByPageNumber.set(
                  nextPageNumber(),
                  currentNextToken,
                );
                setTokensByPageNumber(updatedTokensByPageNumber);
              }
              if (!hasNextPage && (page < totalCount)) {
                setHasNextPage(true);
              }
            }
            const meetingInvites: MeetingInvite[] = [];
            numberOfResults = listMeetingInvitesByProfileIdResults.items.length;
            for (let i = 0; i < listMeetingInvitesByProfileIdResults.items.length; i=i+1) {
              const meetingInvite = listMeetingInvitesByProfileIdResults.items[i] as MeetingInvite;
              logger.info("++ meetingInvite", meetingInvite);
              const {
                productId,
              } = meetingInvite;
              if (!productId) {
                continue;
              }
              meetingInvites.push(meetingInvite);
            }
            setData(meetingInvites);
            if (!isLoaded) {
              setIsLoaded(true);
            }
          } else {
            if (!isLoaded) {
              setIsLoaded(true);
            }
            if (!isNoMeetingInvites) {
              setIsNoMeetingInvites(true);
            }
          }
          if ((numberOfResults === 0 || !currentNextToken)) {
            // setNextToken(null);
            logger.info('pagination - numberOfResults', numberOfResults);
            logger.info('pagination - currentNextToken', currentNextToken);
            setHasNextPage(false);
          }
        }
      ).catch(
        (err: Error) => {
          logger.error("error querying listOfMeetingInvites", err);
        }
      );
    }
  }, [currentProfile, page, totalCount, clockSyncEnabled, cachedServerTimeDifference]);

  useEffect(() => {
    if (isLoaded) {
      if (isNoMeetingInvites) {
        redirect("/");
      }
    }
  }, [isLoaded, isNoMeetingInvites]);

  useEffect(() => {
    logger.info("seconds remaining useEffect", { nextMeetingInvite });
    if (nextMeetingInvite) {
      logger.info("starting seconds remaining checkerInterval...");
      const checkerInterval = setInterval(
        () => {
          try {
            const nowTimestamp = getCurrentDateTime(cachedServerTimeDifference, clockSyncEnabled).toUnixInteger();
            const {
              startDateTime,
            } = nextMeetingInvite;
            const startTimestamp = awsDateTimeStringToDateTime(
              startDateTime,
            ).toUnixInteger();
            const secondsRemaining = Math.round(startTimestamp - nowTimestamp);
            if (secondsRemaining === 15) {
              redirect("/");
            }
          } catch(err) {
            logger.error("error calculating secondsRemaining", err);
          }
        },
        1000,
      );
      return () => {
        if (checkerInterval) {
          clearInterval(checkerInterval);
        }
      }
    }
  }, [nextMeetingInvite, clockSyncEnabled, cachedServerTimeDifference]);

  return (
    <>
      <Grid
        container
        spacing={3}
        direction={isSmall ? "column" : "row"}
      >
        {!isSmall && (
        <Grid item md={8}>
          <img src={inAppLogo ? inAppLogo.url : (defaultLogo ? defaultLogo.url : "/img/logo-connect.png")} height={isSmall ? "55" : "110"} />
        </Grid>
        )}
        <Grid item xs={12} sm={12} md={4} alignContent={isSmall ? undefined : "flex-end"}>
          {(nextMeetingInvite && currentProfile) ? 
          <MeetingCountdown
            {...meetingsState}
            nextMeetingInvite={nextMeetingInvite}
            currentProfile={currentProfile}
            useEndDateTime={false}
            clockSyncEnabled={clockSyncEnabled}
            cachedServerTimeDifference={cachedServerTimeDifference}
            inAppLogo={inAppLogo}
          /> : null
          }
        </Grid>
      </Grid>
      <div className={classes.tableRow}>
      {!isSmall && (
        <div className={classes.tableMenuColumn}>
          <ScheduledMeetingsMenu
            title={"Upcoming meetings"}
            meetings={scheduledMeetingInvites}
            isSeller={isSeller}
            isBuyer={isBuyer}
            showBackToMeetingsLink={true}
            clockSyncEnabled={clockSyncEnabled}
            cachedServerTimeDifference={cachedServerTimeDifference}
          />
        </div>
      )}
        <div className={classes.tableContentColumn}>
        {(isLoaded && meetingDataIsLoaded) ? (
          <>
          <Grid
            container
            spacing={isSmall ? 5 : 10}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={12} sm={12}>
              <Typography variant={isSmall ? undefined : "h5"} className={classes.boldText}>The Products You'll See.</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={isSmall ? 5 : 10}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                    <TableRow key="nav1">
                      <TableCell>
                        <Button
                          size={isSmall ? "small" : "large"}
                          onClick={() => getPreviousPage() }
                          label="Previous"
                          endIcon={<NavigateBeforeIcon />}
                          disabled={isFormDirty || !hasPreviousPage}
                        >
                          <NavigateBeforeIcon />
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {(totalCount) ? (
                          <Typography variant={isSmall ? undefined : "h4"} className={classes.boldText}>Page {page} of {totalCount}</Typography>
                        ) : (
                          <Typography variant={isSmall ? undefined : "h4"} className={classes.boldText}>&nbsp;</Typography>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          size={isSmall ? "small" : "large"}
                          onClick={() => getNextPage() }
                          label="Next"
                          endIcon={<NavigateNextIcon />}
                          disabled={isFormDirty || !hasNextPage}
                        >
                          <NavigateNextIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={12}>
              <TableContainer component={Paper}>
              {(isLoaded && meetingDataIsLoaded) && data.map((row, i) => (
                <Table size="small">
                  <TableBody>
                  {row?.product && (
                    <>
                    <TableRow key={`${row.id}-${i}`}>
                      <TableCell className={classes.tableCell}>
                        <RecordContextProvider value={row.product}>
                          <SimpleShowLayout>
                            <ReferenceManyField
                              reference="productImages"
                              target="productId"
                              perPage={1}
                              label=""
                              filter={{ listProductImagesByProductId: {} }}
                            >
                              <SingleFieldList>
                                <AmplifyImageField
                                  source="image"
                                  title="title"
                                  label=""
                                />
                              </SingleFieldList>
                            </ReferenceManyField>
                          </SimpleShowLayout>
                        </RecordContextProvider>
                      </TableCell>
                      {!isSmall && (
                      <TableCell className={classes.tableCell}>
                        <Typography className={classes.productName}>
                          {row.product.name}
                        </Typography>
                        <Typography>
                          {row.product.description}
                        </Typography>
                      </TableCell>
                      )}
                    </TableRow>
                    {isSmall && (
                      <TableRow key={`sm-${row.id}-${i}`}>
                        <TableCell className={classes.tableCell}>
                          <Typography className={classes.productName}>
                            {row.product.name}
                          </Typography>
                          <Typography>
                            {row.product.description}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    </>
                  )}
                  </TableBody>
                </Table>
              ))}
              </TableContainer>
            </Grid>
          </Grid>
          <Grid container
            spacing={3}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                  <TableRow key="nav2">
                      <TableCell>
                        <Button
                          size={isSmall ? "small" : "large"}
                          onClick={() => getPreviousPage() }
                          label="Previous"
                          endIcon={<NavigateBeforeIcon />}
                          disabled={isFormDirty || !hasPreviousPage}
                        >
                          <NavigateBeforeIcon />
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {(totalCount) ? (
                          <Typography variant={isSmall ? undefined : "h4"} className={classes.boldText}>Page {page} of {totalCount}</Typography>
                        ) : (
                          <Typography variant={isSmall ? undefined : "h4"} className={classes.boldText}>&nbsp;</Typography>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          size={isSmall ? "small" : "large"}
                          onClick={() => getNextPage() }
                          label="Next"
                          endIcon={<NavigateNextIcon />}
                          disabled={isFormDirty || !hasNextPage}
                        >
                          <NavigateNextIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          </>
        ) : <LinearProgress />}
        </div>
      </div>
    </>
  );
};
