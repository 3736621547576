import React, { useContext, useEffect, useState } from "react";
import {
  LinearProgress,
  Link,
  RecordContextProvider,
  ReferenceManyField,
  setAutomaticRefresh,
  SimpleShowLayout,
  SingleFieldList,
  useAuthenticated,
  useDataProvider,
} from "react-admin";
import { Logger } from "aws-amplify";
import type {
  TeamImage,
} from "../API";
import {
  Grid,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardContent,
  CardHeader,
  Box,
} from "@material-ui/core";
import { Theme, useMediaQuery } from "@material-ui/core";
import MeetingCountdown from "../components/MeetingCountdown";
import { MeetingsStateContext } from "../App";
import ScheduledMeetingsMenu from "../components/ScheduledMeetingsMenu";
import { AmplifyImageField } from "../components/AmplifyImageField";
import {
  getProfileDevicesStats,
  getTeamImageByTeamId,
} from "../lib/helpers";
import { sellerBuyerStyles } from "../layout/theme";
import { MeetingInvitationType } from "../API";
import { useDispatch } from "react-redux";
import { IProfileDeviceStatsResponse } from "../types";
import { usePreferences } from "@react-admin/ra-preferences";

const logger = new Logger("Dashboard");

/**
 * Seller Dashboard
 * 
 * @returns {JSX.Element}
 */
export const DashboardPage = () => {

  useAuthenticated();
  const dataProvider = useDataProvider();
  const meetingsState = useContext(MeetingsStateContext);
  const {
    meetingInvitesState,
    nextMeetingInvite,
    nextMeetingProductInformation,
    currentProfile,
    isSeller,
    isBuyer,
    isAdmin,
    defaultLogo,
    inAppLogo,
    meetingDataIsLoaded,
  } = meetingsState;
  const dispatch = useDispatch();
  dispatch(setAutomaticRefresh(false));
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const classes = sellerBuyerStyles();
  const [ buyerTeamImage, setBuyerTeamImage ] = useState<TeamImage | null>(null);
  const [ deviceStats, setDeviceStats ] = useState<IProfileDeviceStatsResponse>({
    recentMicErrors: [],
    recentCameraErrors: [],
    numberOfMicErrors: 0,
    numberOfCameraErrors: 0,
    numberOfActiveUsers: 0,
  });
  const [ clockSyncEnabled ] = usePreferences("clockSyncEnabled", false);
  const [ cachedServerTimeDifference ] = usePreferences("cachedServerTimeDifference", 0);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => { // NOTE: get the product information for the meeting invite
    if (nextMeetingProductInformation) {
      const {
        buyerTeamId,
        buyerTeam,
      } = nextMeetingProductInformation;
      if (
        buyerTeamId &&
        buyerTeam &&
        !buyerTeam.privacyEnabled &&
        nextMeetingProductInformation.meetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER
      ) {
        getTeamImageByTeamId(
          buyerTeamId,
          dataProvider,
        ).then(
          (teamImage) => {
            logger.info("teamImage", teamImage);
            if (teamImage && (!buyerTeamImage || buyerTeamImage.id !== teamImage.id)) {
              setBuyerTeamImage(teamImage);
            }
          }
        ).catch(
          (err) => {
            logger.error("getTeamImage error", err);
          }
        );
      }
    }
  }, [nextMeetingProductInformation?.buyerTeamId]);

  useEffect(() => {
    // TODO update count on deletion or creation of profile devices
    if (isAdmin) {
      getProfileDevicesStats().then(
        (statsResponse: IProfileDeviceStatsResponse) => {
          logger.info('dashboard statsResponse', statsResponse);
          setDeviceStats(statsResponse);
        }
      ).catch(
        (err: any) => {
          logger.error(err);
        }
      ).finally(
        () => {
          if (!isLoaded) {
            setIsLoaded(true);
          }
        }
      );
    } else {
      if (!isLoaded) {
        setIsLoaded(true);
      }
    }
  }, [isAdmin]);

  const scheduledMeetingInvites = meetingInvitesState.scheduledMeetings;

  if (isLoaded) {
    logger.info("dashboard render", { isAdmin, nextMeetingInvite, nextMeetingProductInformation, clockSyncEnabled, cachedServerTimeDifference });
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        direction={isSmall ? "column" : "row"}
      >
        {!isSmall && (
        <Grid item md={8}>
          <img src={inAppLogo ? inAppLogo.url : (defaultLogo ? defaultLogo.url : "/img/logo-connect.png")} height={isSmall ? "55" : "110"} />
        </Grid>
        )}
        <Grid item xs={12} sm={12} md={4} alignContent={isSmall ? undefined : "flex-end"}>
          {(nextMeetingInvite && currentProfile) && (
          <MeetingCountdown
            {...meetingsState}
            nextMeetingInvite={nextMeetingInvite}
            currentProfile={currentProfile}
            useEndDateTime={false}
            clockSyncEnabled={clockSyncEnabled}
            cachedServerTimeDifference={cachedServerTimeDifference}
            inAppLogo={inAppLogo}
            defaultLogo={defaultLogo}
          />)}
        </Grid>
      </Grid>
      <div className={classes.tableRow}>
      {!isSmall && (
        <div className={classes.tableMenuColumn}>
          <ScheduledMeetingsMenu
            title={"Upcoming meetings"}
            meetings={scheduledMeetingInvites}
            isSeller={isSeller}
            isBuyer={isBuyer}
            showBackToMeetingsLink={false}
            clockSyncEnabled={clockSyncEnabled}
            cachedServerTimeDifference={cachedServerTimeDifference}
          />
        </div>
      )}
        <div className={classes.tableContentColumn}>
        {isLoaded && meetingDataIsLoaded ? (
          <>
          <Grid
            container
            spacing={isSmall ? 5 : 10}
            direction={isSmall ? "column" : "row"}
          >
          {(nextMeetingInvite && nextMeetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER) && (
            <Grid item xs={12} sm={12}>
              <Typography variant={isSmall ? undefined : "h5"} className={classes.boldText}>Your next meeting...</Typography>
            </Grid>
          )}
          {(nextMeetingInvite && nextMeetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE) && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant={isSmall ? undefined : "h5"} className={classes.boldText}>You are about to see...</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant={isSmall ? undefined : "h6"} className={classes.boldText}>Presenter(s):</Typography>
                <Typography variant="body1">
                  {nextMeetingInvite ? nextMeetingInvite.sellerTeamName : ""}
                </Typography>
              </Grid>
            </>
          )}
          {(!nextMeetingInvite && scheduledMeetingInvites.length === 0) && (
          <Grid item xs={12} sm={12}>
          </Grid>
          )}
          </Grid>
          <Grid
            container
            spacing={3}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                    <SimpleShowLayout>
                      <TableRow key="1">
                        <TableCell className={classes.tableCell}>
                        {buyerTeamImage && nextMeetingInvite && nextMeetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER && (
                          <AmplifyImageField
                            source="image"
                            title="title"
                            label=""
                            resource="teamImages"
                            basePath="/teamImages"
                            record={buyerTeamImage}
                          />
                        )}
                        {nextMeetingProductInformation && nextMeetingInvite && nextMeetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE && (
                          <RecordContextProvider value={nextMeetingProductInformation.product}>
                            <SimpleShowLayout>
                              <ReferenceManyField
                                reference="productImages"
                                target="productId"
                                perPage={1}
                                label=""
                                filter={{ listProductImagesByProductId: {} }}
                              >
                                <SingleFieldList>
                                  <AmplifyImageField
                                    source="image"
                                    title="title"
                                    label=""
                                  />
                                </SingleFieldList>
                              </ReferenceManyField>
                            </SimpleShowLayout>
                          </RecordContextProvider>
                        )}
                        </TableCell>
                        {!isSmall && (
                        <TableCell className={classes.tableCell}>
                          {nextMeetingProductInformation && nextMeetingInvite && nextMeetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER && (
                          <>
                          {nextMeetingProductInformation.buyerTeam && !nextMeetingProductInformation.buyerTeam.privacyEnabled && (
                            <Typography className={classes.productName}>
                              {nextMeetingProductInformation.buyerTeam.name}
                            </Typography>
                          )}
                          {nextMeetingProductInformation.buyerTeam && !nextMeetingProductInformation.buyerTeam.privacyEnabled && (
                            <Typography>
                              {nextMeetingProductInformation.buyerTeam.description}
                            </Typography>
                          )}
                          </>
                          )}
                          {nextMeetingProductInformation && nextMeetingInvite && nextMeetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE && (
                          <>
                            <Typography className={classes.productName}>
                              {nextMeetingProductInformation.product.name}
                            </Typography>
                            <Typography>
                              {nextMeetingProductInformation.product.description}
                            </Typography>
                          </>
                          )}
                          {(!nextMeetingInvite && scheduledMeetingInvites.length === 0) && (
                          <Typography variant={isSmall ? "h4" : "h2"}>No upcoming meetings.</Typography>
                          )}
                        </TableCell>
                        )}
                      </TableRow>
                      {isSmall && (
                      <TableRow key="1_5">
                        <TableCell className={classes.tableCell}>
                          {nextMeetingProductInformation && nextMeetingInvite && nextMeetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER && (
                          <>
                          {nextMeetingProductInformation.buyerTeam && !nextMeetingProductInformation.buyerTeam.privacyEnabled && (
                            <Typography className={classes.productName}>
                              {nextMeetingProductInformation.buyerTeam.name}
                            </Typography>
                          )}
                          {nextMeetingProductInformation.buyerTeam && !nextMeetingProductInformation.buyerTeam.privacyEnabled && (
                            <Typography>
                              {nextMeetingProductInformation.buyerTeam.description}
                            </Typography>
                          )}
                          </>
                          )}
                          {nextMeetingProductInformation && nextMeetingInvite && nextMeetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE && (
                          <>
                            <Typography className={classes.productName}>
                              {nextMeetingProductInformation.product.name}
                            </Typography>
                            <Typography>
                              {nextMeetingProductInformation.product.description}
                            </Typography>
                          </>
                          )}
                          {(!nextMeetingInvite && scheduledMeetingInvites.length === 0) && (
                          <Typography variant={isSmall ? "h4" : "h2"}>No upcoming meetings.</Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      )}
                    </SimpleShowLayout>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          {isAdmin && (
          <Grid
            container
            spacing={3}
            direction={isSmall ? "column" : "row"}
          >
            <Grid item xs={4}>
              <Box mt={3}>
                <Card>
                  <CardHeader title="Active Users" align="center" />
                  <CardContent>
                    <Link to="/profileDevices">
                      <Typography variant={isSmall ? "h4" : "h2"} align="center">{deviceStats.numberOfActiveUsers}</Typography>
                    </Link>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box mt={3}>
                <Card>
                  <CardHeader title="Camera Errors" align="center" />
                  <CardContent>
                    <Link to="/profileDevices?displayedFilters=%7B%22cameraStatus%22%3Atrue%7D&filter=%7B%22cameraStatus%22%3A%22camera-error%22%7D&order=ASC&page=1&perPage=100&sort=id">
                      <Typography variant={isSmall ? "h4" : "h2"} align="center">{deviceStats.numberOfCameraErrors}</Typography>
                    </Link>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box mt={3}>
                <Card>
                  <CardHeader title="Mic Errors" align="center" />
                  <CardContent>
                    <Link to="/profileDevices?displayedFilters=%7B%22micStatus%22%3Atrue%7D&filter=%7B%22micStatus%22%3A%22error%22%7D&order=ASC&page=1&perPage=100&sort=id">
                      <Typography variant={isSmall ? "h4" : "h2"} align="center">{deviceStats.numberOfMicErrors}</Typography>
                    </Link>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        )}
        </>
        ) : <LinearProgress />
      }
        </div>
      </div>
    </>
  );
};
