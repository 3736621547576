import React, { ComponentProps, createContext, forwardRef, useEffect, useRef, useState } from "react";
import { Amplify, API, Auth, Cache, graphqlOperation, Logger, } from "aws-amplify";
import { Resource, usePermissions } from "ra-core";
import { Admin, AppBar, Breadcrumb, Layout } from "@react-admin/ra-enterprise";
import {
  AppLocationContext,
  ResourceBreadcrumbItems,
} from "@react-admin/ra-navigation";
import { SidebarOpenPreferenceSync, usePreferences } from "@react-admin/ra-preferences";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Box, Chip, createTheme, Slide, Theme, Typography } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ProfileIcon from "@material-ui/icons/Person";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import ScheduleIcon from "@material-ui/icons/Schedule";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import HelpIcon from "@material-ui/icons/Help";
import HomeIcon from "@material-ui/icons/Home";
import ArchiveIcon from "@material-ui/icons/Archive";
import BusinessIcon from "@material-ui/icons/Business";
import CopyrightIcon from "@material-ui/icons/Copyright";
import SettingsBrightnessIcon from "@material-ui/icons/SettingsBrightness";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import GroupIcon from "@material-ui/icons/Group";
import DuoIcon from "@material-ui/icons/Duo";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import ProfileOutlineIcon from "@material-ui/icons/PersonOutline";
import ProfilePinIcon from "@material-ui/icons/PersonPin";
import RateReviewIcon from "@material-ui/icons/RateReview";
import CameraFrontIcon from "@material-ui/icons/CameraFront";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import SubtitlesIcon from "@material-ui/icons/Subtitles";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import NotesIcon from "@material-ui/icons/Notes";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import * as Sentry from "@sentry/react";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import { useLocation } from "react-router-dom";
import { Loading, MenuItemLink, type MenuItemLinkProps, UserMenu } from "react-admin";
import { useCookies } from "react-cookie";
import { buildAuthProvider, buildDataProvider } from "./providers";
import { CognitoGroupList } from "./components/CognitoGroup";
import { CognitoUserList, CognitoUserShow } from "./components/CognitoUser";
import SignIn from "./components/SignIn";
import awsConfig from "./aws-exports";
import {
  ForwardEmailCreate,
  ForwardEmailEdit,
  ForwardEmailList,
  ForwardEmailShow,
} from "./components/ForwardEmail";
import {
  ImportJobCreate,
  ImportJobList,
  ImportJobShow,
} from "./components/ImportJob";
import {
  ImportJobAuditLogList,
  ImportJobAuditLogShow,
} from "./components/ImportJobAuditLog";
import {
  TranscriptionEventAuditLogList,
  TranscriptionEventAuditLogShow,
} from "./components/TranscriptionEventAuditLog";
import {
  OrganizationCreate,
  OrganizationEdit,
  OrganizationList,
  OrganizationShow,
} from "./components/Organization";
import {
  OrganizationLocationCreate,
  OrganizationLocationEdit,
  OrganizationLocationList,
  OrganizationLocationShow,
} from "./components/OrganizationLocation";
import {
  OrganizationLogoCreate,
  OrganizationLogoEdit,
  OrganizationLogoList,
  OrganizationLogoShow,
} from "./components/OrganizationLogo";
import {
  OrganizationThemeCreate,
  OrganizationThemeEdit,
  OrganizationThemeList,
  OrganizationThemeShow,
} from "./components/OrganizationTheme";
import {
  MeetingCreate,
  MeetingEdit,
  MeetingList,
  MeetingShow,
} from "./components/Meeting";
import {
  TeamMeetingRelationshipCreate,
  TeamMeetingRelationshipEdit,
  TeamMeetingRelationshipList,
  TeamMeetingRelationshipShow,
} from "./components/TeamMeetingRelationship";
import {
  MeetingRoomEdit,
  MeetingRoomList,
  MeetingRoomShow,
} from "./components/MeetingRoom";
import {
  MeetingInviteEdit,
  MeetingInviteList,
  MeetingInviteShow,
} from "./components/MeetingInvite";
import {
  MeetingRoomProviderCreate,
  MeetingRoomProviderEdit,
  MeetingRoomProviderList,
  MeetingRoomProviderShow,
} from "./components/MeetingRoomProvider";
import {
  MeetingRoomEventAuditLogList,
  MeetingRoomEventAuditLogShow,
} from "./components/MeetingRoomEventAuditLog";
import {
  ProfileLocationCreate,
  ProfileLocationEdit,
  ProfileLocationList,
  ProfileLocationShow,
} from "./components/ProfileLocation";
import {
  ProfileCreate,
  ProfileEdit,
  ProfileList,
  ProfileShow,
} from "./components/Profile";
import {
  ProfileImageCreate,
  ProfileImageEdit,
  ProfileImageList,
  ProfileImageShow,
} from "./components/ProfileImage";
import {
  TeamImageCreate,
  TeamImageEdit,
  TeamImageList,
  TeamImageShow,
} from "./components/TeamImage";
import {
  ProductCreate,
  ProductEdit,
  ProductList,
  ProductShow,
} from "./components/Product";
import {
  ProductImageCreate,
  ProductImageEdit,
  ProductImageList,
  ProductImageShow,
} from "./components/ProductImage";
import {
  ProductQuestionCreate,
  ProductQuestionEdit,
  ProductQuestionList,
  ProductQuestionShow,
} from "./components/ProductQuestion";
import {
  QuestionResponseCreate,
  QuestionResponseEdit,
  QuestionResponseList,
  QuestionResponseShow,
} from "./components/QuestionResponse";
import {
  TeamCreate,
  TeamEdit,
  TeamList,
  TeamShow,
} from "./components/Team";
import {
  TeamMemberRelationshipCreate,
  TeamMemberRelationshipEdit,
  TeamMemberRelationshipList,
  TeamMemberRelationshipShow,
} from "./components/TeamMemberRelationship";
import {
  TranscriptionList,
  TranscriptionShow,
} from "./components/Transcription";
import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";
import {
  customDarkTheme,
  customDarkThemeOptions,
  customLightTheme,
  customLightThemeOptions,
  customTheme,
} from "./layout/theme";
import { customRoutes } from "./routes";
import {
  CognitoUserAmplifyWithAttributes,
  IMeetingProductInformation,
  IMeetingsStateContext,
  MeetingsState,
  ServerDateResponse,
  ThemeSettingsLogo,
  ThemeSettingsResponse,
} from "./types";
import type {
  MeetingInvite,
  Profile,
  Notification,
  CreateProfileDeviceInput,
} from "./API";
import {
  MeetingStatus,
} from "./API";
import {
  awsDateTimeStringToDateTime,
  getActiveMeetingInvites,
  getEndedMeetingInvites,
  getProfile,
  getMeetingInvitesByProfileId,
  isExpiredMeeting,
  getMeetingProductInformation,
  meetingInviteCacheName,
  joinInfoCacheName,
  privacyEnabledCacheName,
  startsInSeconds,
  playSound,
  logEvent,
  getCurrentDateTime,
  getSettingsVersion,
  getNotificationsByRead,
  upsertProfileDevice,
  hashBrowser,
  meetingInviteChangeDetected,
} from "./lib/helpers";
import {
  onCreateNotification,
  onCreateMeetingInvite,
  onDeleteMeetingInvite,
  onUpdateMeetingInvite,
} from "./graphql/subscriptions";
import {
  AWS_TIMESTAMP_AS_LUXON_FORMAT,
  SITE_TITLE,
  LATEST_GIT_COMMIT,
} from "./constants";
import { MeetingInvitationType } from "./models";
import { DashboardPage } from "./pages/Dashboard";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ExportJobCreate, ExportJobList, ExportJobShow } from "./components/ExportJob";
import { ExportJobAuditLogList, ExportJobAuditLogShow } from "./components/ExportJobAuditLog";
import type { AuthOptions } from "@aws-amplify/auth/lib-esm/types";
import { TranscriptionJobList, TranscriptionJobShow } from "./components/TranscriptionJob";
import { TranscriptionJobAuditLogList, TranscriptionJobAuditLogShow } from "./components/TranscriptionJobAuditLog";
import { TranscriptionAdminJobList, TranscriptionAdminJobShow } from "./components/TranscriptionAdminJob";
import { TranscriptionAdminJobAuditLogList, TranscriptionAdminJobAuditLogShow } from "./components/TranscriptionAdminJobAuditLog";
import UAParser from "ua-parser-js";
import { MeetingAttendeeList, MeetingAttendeeShow } from "./components/MeetingAttendee";
import { ProfileDeviceList, ProfileDeviceShow } from "./components/ProfileDevice";
import { ToggleClockSyncButton } from "./components/ToggleClockSyncButton";



const ReactSwal = withReactContent(Swal);
const logger = new Logger("App");

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)){3}$/
    )
);

// NOTE: There are two redirect URIs, and the first is for localhost (development) and the second is for production.
const [localRedirectSignIn, productionRedirectSignIn] = awsConfig.oauth.redirectSignIn.split(",");
const [localRedirectSignOut, productionRedirectSignOut] = awsConfig.oauth.redirectSignOut.split(",");

let updatedAwsConfig: AuthOptions = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};

if (!isLocalhost) {
  updatedAwsConfig = {
    ...updatedAwsConfig,
    cookieStorage: {
      domain: "insightgateway.com",
      path: "/",
      expires: 30,
      sameSite: "lax",
      secure: true,
    },
  };
}

Amplify.configure(updatedAwsConfig);
Amplify.Logger.LOG_LEVEL = "INFO";

if (isLocalhost) {
  Amplify.Logger.LOG_LEVEL = "INFO";
  // @ts-ignore
  window.LOG_LEVEL = "INFO";
}

const authProvider = buildAuthProvider({
  authGroups: ["superuser", "admin", "attendee"],
  enableAdminQueries: true,
});

const dataProvider = buildDataProvider(
  {
    queries,
    mutations,
  },
  {
    storageBucket: awsConfig.aws_user_files_s3_bucket,
    storageRegion: awsConfig.aws_user_files_s3_bucket_region,
    enableAdminQueries: true,
  }
);

const userAgentParser = new UAParser();

/**
 * Global state data
 *
 * includes upcoming scheduled meetings, number of scheduled meetings, number of ended meetings
 */
export const MeetingsStateContext = createContext<IMeetingsStateContext>({
  meetingInvitesState: {
    scheduledMeetings: [],
    numberOfScheduledMeetings: 0,
    numberOfEndedMeetings: 0,
  },
  nextMeetingProductInformation: null,
  nextMeetingInvite: null,
  isSeller: false,
  isBuyer: false,
  isAdmin: false,
  previousMeetingProductInformation: null,
  previousMeetingInvite: null,
  currentProfile: null,
  userAgentData: null,
  isApplicationOutdated: false,
});


function App() {

  const [ currentProfile, setCurrentProfile ] = useState<Profile | null>(null);
  const [ meetingInvitesState, setMeetingInvitesState] = useState<MeetingsState>(
    {
      scheduledMeetings: [],
      numberOfScheduledMeetings: 0,
      numberOfEndedMeetings: 0,
    }
  );
  const [ nextMeetingProductInformation, setNextMeetingProductInformation ] =  useState<IMeetingProductInformation | null>(null);
  const [ nextMeetingInvite, setNextMeetingInvite ] = useState<MeetingInvite | null>(null);
  const [ previousMeetingProductInformation, setPreviousMeetingProductInformation ] =  useState<IMeetingProductInformation | null>(null);
  const [ previousMeetingInvite, setPreviousMeetingInvite ] = useState<MeetingInvite | null>(null);
  const [ requestServerTimeDifference, setRequestServerTimeDifference ] = useState<number>(0);
  // const [ userAgentData, setUserAgentData ] = useState<UAParser.IResult | null>(userAgentParser.getResult());
  const userAgentData = userAgentParser.getResult();
  // const [ userAgentHash, setUserAgentHash ] = useState<string | null>(null);
  const userAgentHashRef = useRef<string | null>(null);
  const [ showAppBar, setShowAppBar ] = useState(true);
  const [ isSeller, setIsSeller ] = useState(false);
  const [ isBuyer, setIsBuyer ] = useState(false);
  const [ isAdmin, setIsAdmin ] = useState(false);
  const [ isApplicationOutdated, setIsApplicationOutdated ] = useState(false);
  const [ cookies, setCookie ] = useCookies(
    ["autoClockSyncDisabled", "hasCustomTheme", "customDarkPalette", "customLightPalette", "overrideDefaultLogo", "overrideInAppLogo"]
  );
  const [ overrideInAppLogo, setOverrideInAppLogo ] = useState<ThemeSettingsLogo | null>(
    (cookies.hasOwnProperty('overrideInAppLogo') && cookies.overrideInAppLogo) ? cookies.overrideInAppLogo as ThemeSettingsLogo : null
  );
  const [ overrideDefaultLogo, setOverrideDefaultLogo ] = useState<ThemeSettingsLogo | null>(
    (cookies.hasOwnProperty('overrideDefaultLogo') && cookies.overrideDefaultLogo) ? cookies.overrideDefaultLogo as ThemeSettingsLogo : null
  );

  const [ overrideCustomLightTheme, setOverrideCustomLightTheme ] = useState<Theme | null>(null);
  const [ overrideCustomDarkTheme, setOverrideCustomDarkTheme ] = useState<Theme | null>(null);

  const [ clockSyncEnabled, setClockSyncEnabled ] = usePreferences(
    "clockSyncEnabled",
    !cookies.hasOwnProperty('autoClockSyncDisabled') ? true : (
      cookies.autoClockSyncDisabled ? false : true
    )
  );
  const [ cachedServerTimeDifference, setCachedServerTimeDifference ] = usePreferences("cachedServerTimeDifference", 0);
  const [ customThemeChecked, setCustomThemeChecked ] = useState<boolean>(
    (cookies.hasOwnProperty('hasCustomTheme') && ['0', '1'].includes(cookies.hasCustomTheme)) ? true : false
  );
  logger.info("custom cookies.hasOwnProperty('hasCustomTheme')", cookies.hasOwnProperty('hasCustomTheme'));
  logger.info("custom cookies.hasCustomTheme", cookies.hasCustomTheme);
  logger.info("custom (cookies.hasOwnProperty('hasCustomTheme') && ['0', '1'].includes(cookies.hasCustomTheme))", (cookies.hasOwnProperty('hasCustomTheme') && ['0', '1'].includes(cookies.hasCustomTheme)) ? true : false);
  logger.info("custom customThemeChecked", customThemeChecked);

  logger.info("custom overrideInAppLogo", overrideInAppLogo);
  logger.info("custom overrideDefaultLogo", overrideDefaultLogo);
  logger.info("custom overrideCustomLightTheme", overrideCustomLightTheme);
  logger.info("custom overrideCustomDarkTheme", overrideCustomDarkTheme);

  const AdminAppBar = (props: ComponentProps<any>) => {
    const { pathname } = useLocation();
    const { loaded, permissions } = usePermissions();
    useEffect(() => {
      logger.info("AdminAppBar useeffect called...");
      if (loaded && permissions && pathname) {
        try {
          const isAdminOrSuperuser = (permissions.includes("superuser") || permissions.includes("admin"));
          logger.info("isAdminOrSuperuser", isAdminOrSuperuser);
          const inMeeting = pathname.trim().startsWith("/join");
          const newShowAppBar = isAdminOrSuperuser || (!isAdminOrSuperuser && !inMeeting);
          const appBarElement = document.getElementById("appBar");
          if (
            isAdminOrSuperuser !== isAdmin
          ) {
            setIsAdmin(isAdminOrSuperuser);
          }
          if (newShowAppBar !== showAppBar) {
            setShowAppBar(newShowAppBar);
          }
          if (appBarElement) {
            const appFrameElement = appBarElement.parentElement;
            if (appFrameElement) {
              if (newShowAppBar) {
                appFrameElement.style.marginTop = "48px";
              } else if (!newShowAppBar) {
                appFrameElement.style.marginTop = "0px";
              }
            }
          }
        } catch(err: any) {
          logger.error("error occured detecting or setting app bar visiblilty", err);
        }
      }
    }, [pathname, loaded, permissions]);

    const ApplicationMenu = forwardRef<HTMLLIElement>(
      // @ts-ignore
      ({ onClick, ...props }: MenuItemLinkProps, ref) => {
        return (
          <MenuItemLink
            ref={ref}
            to="/"
            primaryText="Back to meetings"
            leftIcon={<HomeIcon />}
          />
        );
      }
    );

    const CustomUserMenu = (props: ComponentProps<any>) => (
      <UserMenu {...props}>
        <ApplicationMenu {...props} />
      </UserMenu>
    );

    return (
      <Slide appear={false} direction="down" in={showAppBar}>
        <AppBar {...props} userMenu={<CustomUserMenu />} id="appBar">
          <Box flex="1">
            <Typography variant="h6" id="react-admin-title"></Typography>
          </Box>
          {(!clockSyncEnabled && Math.abs(cachedServerTimeDifference) > 5000) && (
            <Chip
              icon={<WarningIcon />}
              color="primary"
              label="Please check your system clock"
            />
          )}
          {(clockSyncEnabled) && (
            <Chip
              icon={<InfoIcon />}
              color="primary"
              label="Clock sync is enabled"
            />
          )}
          <ToggleClockSyncButton
            clockSyncEnabled={clockSyncEnabled}
            setClockSyncEnabled={setClockSyncEnabled}
            cachedServerTimeDifference={cachedServerTimeDifference}
            setRequestServerTimeDifference={setRequestServerTimeDifference}
          />
        </AppBar>
      </Slide>
    );
  }
  // See https://marmelab.com/react-admin/Theming.html#using-a-custom-menu
  // Tip: You can generate the menu items for each of the resources by reading the Resource configurations context:
  const AdminLayout = ({ children, ...props }: ComponentProps<any>) => {

    logger.info("AdminLayout render called...");

    return (
      <AppLocationContext>
        <SidebarOpenPreferenceSync />
        <Layout {...props} appBar={AdminAppBar}>
          <Breadcrumb {...props}>
            <ResourceBreadcrumbItems />
          </Breadcrumb>
          {children}
        </Layout>
      </AppLocationContext>
    );
  }

  useEffect(() => {
    // removeCookie("serverTimeDifference", { sameSite: 'none' });
    logger.info("Auth.currentAuthenticatedUser useeffect called...");
    // NOTE: Gets the user credentials
    Auth.currentAuthenticatedUser().then(
      (user: CognitoUserAmplifyWithAttributes) => {
        return getProfile(user.username).then(
          (profile) => {
            setCurrentProfile(profile);
            Cache.setItem("profile", profile);
            if (profile) {
              try {
                Sentry.setUser(
                  {
                    email: profile.email,
                    id: profile.id,
                    username: profile.fullName,
                  }
                );
              } catch (err) {
                logger.warn("Sentry.setUser failed", err);
              }
            }
          }
        );
      }
    ).catch(
      () => {
        logger.info("Not logged in.");
      }
    );
  }, []);

  useEffect(() => {
    logger.info("serverSettingsResponse useeffect called...");
    if (currentProfile?.id) {
      getSettingsVersion().then(
        (serverSettingsResponse) => {
          const isOutdated = serverSettingsResponse.commit !== LATEST_GIT_COMMIT;
          if (isOutdated && isApplicationOutdated !== isOutdated) {
            ReactSwal.fire(
              {
                title: "Please update!",
                text: "This application is outdated.  Please reload to update.",
                icon: "warning",
                toast: true,
                position: "top-end",
                timer: 1000 * 15,
              }
            );
          }
          if (isApplicationOutdated !== isOutdated) {
            setIsApplicationOutdated(isOutdated);
          }
        }
      ).catch(
        (err: Error) => {
          logger.error("serverSettingsResponse error", err);
        }
      );
    }
  }, [
    currentProfile?.id,
  ]);

  useEffect(() => {
    logger.info("currentVersionCheckerInterval serverSettingsResponse useeffect called...");
    if (currentProfile?.id) {
      const currentVersionCheckerInterval = setInterval(
        () => {
          getSettingsVersion().then(
            (serverSettingsResponse) => {
              const isOutdated = serverSettingsResponse.commit !== LATEST_GIT_COMMIT;
              if (isApplicationOutdated !== isOutdated) {
                setIsApplicationOutdated(isOutdated);
              }
            }
          ).catch(
            (err: Error) => {
              logger.error("currentVersionCheckerInterval serverSettingsResponse error", err);
            }
          );
        },
        1000 * 60 * 30,
      );
      return () => {
        if (currentVersionCheckerInterval) {
          try {
            clearInterval(currentVersionCheckerInterval);
          } catch {
            (err: Error) => { }
          }
        }
      }
    }
  }, [
    currentProfile?.id,
  ]);

  useEffect(() => {
    if (
      currentProfile?.id &&
      userAgentData.browser
    ) {
      logger.info("upsertProfileDevice userAgentData", userAgentData);
      const deviceString = `${userAgentData.os.name}${userAgentData.engine.name}${userAgentData.browser.name}`;
      logger.info("upsertProfileDevice deviceString", deviceString);
      hashBrowser(deviceString).then(
        (deviceHash) => {
          logger.info("upsertProfileDevice deviceHash", deviceHash);
          // setUserAgentHash(deviceHash);
          userAgentHashRef.current = deviceHash;
          if (userAgentHashRef.current) {
            setTimeout(
              () => {
                const lastSeen = getCurrentDateTime().toUTC().toFormat(AWS_TIMESTAMP_AS_LUXON_FORMAT);
                const upsertInput: CreateProfileDeviceInput = {
                  deviceHash: userAgentHashRef.current as string,
                  profileId: currentProfile.id,
                  clockOffset: cachedServerTimeDifference,
                  lastSeen,
                  userAgent: JSON.stringify(userAgentData),
                  userAgentSummary: userAgentData?.ua,
                };
                logger.info("upsertProfileDevice upsertInput", upsertInput);
                upsertProfileDevice(upsertInput).then(
                  (upsertProfileDeviceResponse) => {
                    logger.info("upsertProfileDevice response", upsertProfileDeviceResponse);
                  }
                ).catch(
                  (err: Error) => {
                    logger.error("upsertProfileDevice serverSettingsResponse error", err);
                  }
                );
              },
              1000 * 30,
            );
            const upsertProfileDeviceInterval = setInterval(
              () => {
                const lastSeen = getCurrentDateTime().toUTC().toFormat(AWS_TIMESTAMP_AS_LUXON_FORMAT);
                const upsertInput: CreateProfileDeviceInput = {
                  deviceHash: userAgentHashRef.current as string,
                  profileId: currentProfile.id,
                  clockOffset: cachedServerTimeDifference,
                  lastSeen,
                  userAgent: JSON.stringify(userAgentData),
                  userAgentSummary: userAgentData?.ua,
                };
                logger.info("upsertProfileDevice upsertInput (2)", upsertInput);
                upsertProfileDevice(upsertInput).then(
                  (upsertProfileDeviceResponse) => {
                    logger.info("upsertProfileDevice response (2)", upsertProfileDeviceResponse);
                  }
                ).catch(
                  (err: Error) => {
                    logger.error("upsertProfileDevice serverSettingsResponse error (2)", err);
                  }
                );
              },
              1000 * 60 * 14,
            );
            return () => {
              if (upsertProfileDeviceInterval) {
                try {
                  clearInterval(upsertProfileDeviceInterval);
                } catch {
                  (err: Error) => { }
                }
              }
            }
          }
        }
      )
    }
  }, [
    currentProfile?.id,
  ]);

  /* useEffect(() => {
    logger.info("upsertProfileDevice useeffect called...");
    if (
      currentProfile?.id
      // userAgentHashRef.current
    ) {
      setTimeout(
        () => {
          if (userAgentHashRef.current) {
            const lastSeen = getCurrentDateTime().toUTC().toFormat(AWS_TIMESTAMP_AS_LUXON_FORMAT);
            const upsertInput: CreateProfileDeviceInput = {
              deviceHash: userAgentHashRef.current as string,
              profileId: currentProfile.id,
              clockOffset: cachedServerTimeDifference,
              lastSeen,
              userAgent: JSON.stringify(userAgentData),
              userAgentSummary: userAgentData?.ua,
            };
            logger.info("upsertProfileDevice upsertInput", upsertInput);
            upsertProfileDevice(upsertInput).then(
              (upsertProfileDeviceResponse) => {
                logger.info("upsertProfileDevice response", upsertProfileDeviceResponse);
              }
            ).catch(
              (err: Error) => {
                logger.error("upsertProfileDevice serverSettingsResponse error", err);
              }
            );
          }
        },
        1000 * 30,
      );
      const upsertProfileDeviceInterval = setInterval(
        () => {
          if (userAgentHashRef.current) {
            const lastSeen = getCurrentDateTime().toUTC().toFormat(AWS_TIMESTAMP_AS_LUXON_FORMAT);
            const upsertInput: CreateProfileDeviceInput = {
              deviceHash: userAgentHashRef.current as string,
              profileId: currentProfile.id,
              clockOffset: cachedServerTimeDifference,
              lastSeen,
              userAgent: JSON.stringify(userAgentData),
              userAgentSummary: userAgentData?.ua,
            };
            logger.info("upsertProfileDevice upsertInput (2)", upsertInput);
            upsertProfileDevice(upsertInput).then(
              (upsertProfileDeviceResponse) => {
                logger.info("upsertProfileDevice response (2)", upsertProfileDeviceResponse);
              }
            ).catch(
              (err: Error) => {
                logger.error("upsertProfileDevice serverSettingsResponse error (2)", err);
              }
            );
          }
        },
        1000 * 60 * 14,
      );
      return () => {
        if (upsertProfileDeviceInterval) {
          try {
            clearInterval(upsertProfileDeviceInterval);
          } catch {
            (err: Error) => { }
          }
        }
      }
    }
  }, [
    currentProfile?.id,
    // userAgentHash,
  ]); */

  useEffect(() => {
    logger.info("nextMeetingCheckerInterval useeffect called...");
    // NOTE: Activates the internal timer for setting the next meeting invitation
    const nextMeetingCheckerInterval = setInterval(
      () => {
        if (
          nextMeetingInvite
        ) {
          if (startsInSeconds(nextMeetingInvite, 30, cachedServerTimeDifference, clockSyncEnabled)) {
            playSound('meeting-starts-in-thirty-seconds.mp3', 0.5);
          } else if (startsInSeconds(nextMeetingInvite, 10, cachedServerTimeDifference, clockSyncEnabled)) {
            playSound('meeting-starts-in-ten-seconds.mp3', 0.5);
          } else if (isExpiredMeeting(
            nextMeetingInvite,
            cachedServerTimeDifference,
            clockSyncEnabled,
          )) {
            setPreviousMeetingInvite(nextMeetingInvite);
            const scheduledMeetingsWithoutCurrentNextMeeting = meetingInvitesState.scheduledMeetings.filter((m) => m.id != nextMeetingInvite.id);
            if (scheduledMeetingsWithoutCurrentNextMeeting.length > 0) {
              if (typeof scheduledMeetingsWithoutCurrentNextMeeting[0].providerJoinData === "string") {
                scheduledMeetingsWithoutCurrentNextMeeting[0].providerJoinData = JSON.parse(scheduledMeetingsWithoutCurrentNextMeeting[0].providerJoinData);
              }
              setNextMeetingInvite(scheduledMeetingsWithoutCurrentNextMeeting[0]);
              const meetingCacheName = meetingInviteCacheName(nextMeetingInvite.id);
              const joinCacheName = joinInfoCacheName(nextMeetingInvite.id);
              const privacyCacheName = privacyEnabledCacheName(nextMeetingInvite.id);
              const cacheExpiration = new Date(Date.parse(nextMeetingInvite.endDateTime) + (3600 * 1000));
              logger.info("cacheExpiration", cacheExpiration);
              Cache.setItem(
                privacyCacheName,
                currentProfile && currentProfile.privacyEnabled ? "True" : nextMeetingInvite.privacyEnabled ? "True" : "False",
                { expires: cacheExpiration.getTime() },
              );
            } else {
              setNextMeetingInvite(null);
            }
          }
        }
      },
      1000,
    );
    return () => {
      if (nextMeetingCheckerInterval) {
        try {
          clearInterval(nextMeetingCheckerInterval);
        } catch {
          (err: Error) => { }
        }
      }
    }
  }, [
    currentProfile?.id,
    meetingInvitesState,
    clockSyncEnabled,
    cachedServerTimeDifference,
    nextMeetingInvite,
  ]);

  useEffect(() => {
    logger.info("onCreateNotification useeffect called...");
    // NOTE: Sets the realtime observers for Notifications
    if (currentProfile?.id) {

      const onCreateSubscription = API.graphql(
        graphqlOperation(onCreateNotification, { owner: currentProfile?.userId })
      ).subscribe({
        next: ({ value }: any) => {
          logger.info("value.data.onCreateNotification", value.data.onCreateNotification);
          const newNotification = value.data.onCreateNotification as Notification;
          logger.info("newNotification", newNotification);
          const {
            payload,
          } = newNotification;

          logEvent(
            currentProfile,
            'detected new notification',
            { details: newNotification },
            'info',
            cachedServerTimeDifference,
            userAgentData,
          );

          if (payload) {
            logger.info("payload", payload);
            try {
              const {
                messages,
              } = JSON.parse(payload) as unknown as { type: string, messages: string[] };
              ReactSwal.fire({
                title: newNotification.title,
                html: messages.join("<br/>"),
                icon: "info",
                confirmButtonColor: "#056839",
                cancelButtonColor: "#39B54A",
              });
              // NOTE: update Notification and set read and dateRead
              if (!newNotification.read || !newNotification.dateRead) {
                dataProvider.update(
                  "notifications",
                  {
                    id: newNotification.id,
                    data: {
                      id: newNotification.id,
                      read: 1,
                      dateRead: getCurrentDateTime().toFormat(AWS_TIMESTAMP_AS_LUXON_FORMAT),
                      _version: newNotification._version
                    },
                    previousData: {
                      id: newNotification.id,
                      read: newNotification.read,
                      dateRead: newNotification.dateRead,
                    },
                  }
                ).then(
                  (notificationUpdateResult: any) => {
                    logger.info("notificationUpdateResult", notificationUpdateResult);
                  }
                ).catch(
                  (err: any) => {
                    logger.error("failed to update notification", err);
                    Sentry.captureException(err);
                  }
                );
              }
            } catch(err) {
              logger.warn("onCreateNotification error", `${err}`);
              Sentry.captureException(err);
            }
          }
        },
        error: (err: Error) => {
          logger.warn("onCreateNotification error", err);
        }
      });

      return () => {
        logger.info("notifications unsubscribe called");
        if (onCreateSubscription) {
          onCreateSubscription.unsubscribe();
        }
      }
    }
  }, [currentProfile?.id]);

  useEffect(() => {
    logger.info("getMeetingInvitesByProfileId useeffect called...");
    // NOTE: loads the initial meeting data load
    if (currentProfile?.id) {
      getMeetingInvitesByProfileId(
        currentProfile.id,
        {
          status: {
            ne: MeetingStatus.ENDED
          }
        },
      ).then(
        (meetingInvitesByProfileIdScheduled) => {
          logger.info("meetingInvitesByProfileIdScheduled", meetingInvitesByProfileIdScheduled);
          const activeMeetingInvites = getActiveMeetingInvites(
            meetingInvitesByProfileIdScheduled,
            cachedServerTimeDifference,
            clockSyncEnabled,
          );
          logger.info("activeMeetingInvites", activeMeetingInvites);
          logEvent(
            currentProfile,
            `detected ${activeMeetingInvites.length} scheduled meetings`,
            {},
            'info',
            cachedServerTimeDifference,
            userAgentData,
          );
          getMeetingInvitesByProfileId(
            currentProfile.id,
            {
              status: {
                eq: MeetingStatus.ENDED
              }
            },
          ).then(
            (meetingInvitesByProfileIdEnded) => {
              const endedMeetingInvites = getEndedMeetingInvites(
                meetingInvitesByProfileIdEnded,
                cachedServerTimeDifference,
                clockSyncEnabled,
              );
              logger.info("endedMeetingInvites", endedMeetingInvites);
              if (endedMeetingInvites.length > 0) {
                const endedMeetingInvite = endedMeetingInvites.pop() as MeetingInvite;
                setPreviousMeetingInvite(endedMeetingInvite);
                if (
                  activeMeetingInvites.length === 0 &&
                  !isSeller &&
                  !isBuyer
                ) {
                  const updatedIsSeller = endedMeetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER;
                  if (updatedIsSeller && !isSeller) {
                    setIsSeller(updatedIsSeller);
                  }
                  const updatedIsBuyer  = endedMeetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE;
                  if (updatedIsBuyer && !isBuyer) {
                    setIsBuyer(updatedIsBuyer);
                  }
                }
              }
              if (
                endedMeetingInvites.length != meetingInvitesState.numberOfEndedMeetings ||
                meetingInviteChangeDetected(meetingInvitesState.scheduledMeetings, activeMeetingInvites)
              ) {
                logger.info("setMeetingInvitesState #1");
                setMeetingInvitesState(
                  {
                    scheduledMeetings: activeMeetingInvites,
                    numberOfScheduledMeetings: activeMeetingInvites.length,
                    numberOfEndedMeetings: endedMeetingInvites.length,
                  }
                );
              }
            }
          );
        }
      ).catch(
        (err: Error) => {
          logger.warn("getMeetingInvitesByProfileId error", err);
        }
      );
    }
  }, [
    currentProfile?.id,
    clockSyncEnabled,
    cachedServerTimeDifference,
  ]);

  useEffect(() => {
    logger.info("getNotificationsByRead useeffect called...");
    // NOTE: loads the initial meeting data load
    if (currentProfile?.id) {
      getNotificationsByRead(0).then(
        (unreadNotifications) => {
          logger.info("unreadNotifications", unreadNotifications);
          // TODO limit the number of notifications
          for (let i = 0; i < unreadNotifications.length; i++) {
            const unreadNotification = unreadNotifications[i];
            logger.info("unreadNotification", unreadNotification);
            const {
              payload,
            } = unreadNotification;
            logEvent(
              currentProfile,
              'detected unread notification',
              { details: unreadNotification },
              'info',
              cachedServerTimeDifference,
              userAgentData,
            );
            if (payload) {
              logger.info("payload", payload);
              try {
                const {
                  messages,
                } = JSON.parse(payload) as unknown as { type: string, messages: string[] };
                ReactSwal.fire({
                  title: unreadNotification.title,
                  html: messages.join("<br/>"),
                  icon: "info",
                  confirmButtonColor: "#056839",
                  cancelButtonColor: "#39B54A",
                });
                // NOTE: update Notification and set read and dateRead
                if (!unreadNotification.read || !unreadNotification.dateRead) {
                  dataProvider.update(
                    "notifications",
                    {
                      id: unreadNotification.id,
                      data: {
                        id: unreadNotification.id,
                        read: 1,
                        dateRead: getCurrentDateTime().toFormat(AWS_TIMESTAMP_AS_LUXON_FORMAT),
                        _version: unreadNotification._version
                      },
                      previousData: {
                        id: unreadNotification.id,
                        read: unreadNotification.read,
                        dateRead: unreadNotification.dateRead,
                      },
                    }
                  ).then(
                    (notificationUpdateResult: any) => {
                      logger.info("notificationUpdateResult", notificationUpdateResult);
                    }
                  ).catch(
                    (err: any) => {
                      logger.error("failed to update notification", err);
                      Sentry.captureException(err);
                    }
                  );
                }
              } catch(err) {
                logger.warn("unreadNotification error", `${err}`);
                Sentry.captureException(err);
              }
            }
          }
        }
      ).catch(
        (err: Error) => {
          logger.warn("unreadNotifications error", err);
        }
      );
    }
  }, [currentProfile?.id]);

  useEffect(() => {
    logger.info("onCreateSubscription useeffect called...");
    // NOTE: Sets the realtime observers for MeetingInvites
    if (currentProfile?.id) {

      const {
        numberOfEndedMeetings,
      } = meetingInvitesState;

      const onCreateSubscription = API.graphql(
        graphqlOperation(onCreateMeetingInvite, { owner: currentProfile?.userId })
      ).subscribe({
        next: ({ value }: any) => {
          logger.info("value.data.onCreateMeetingInvite", value.data.onCreateMeetingInvite);
          const newMeetingInvite = value.data.onCreateMeetingInvite;

          logger.info("newMeetingInvite", newMeetingInvite);

          logEvent(
            currentProfile,
            'detected new scheduled meeting',
            { details: newMeetingInvite },
            'info',
            cachedServerTimeDifference,
            userAgentData,
          );

          const {
            providerId
          } = newMeetingInvite;

          if (!providerId) {
            logger.info("providerId is empty");
            dataProvider.getOne(
              "meetingInvites",
              {
                id: newMeetingInvite.id,
              },
            ).then(
              (results: any) => {
                logger.info("meetingInvites results", results);
                if (results && results.data) {
                  const mInvite = results.data as MeetingInvite;
                  logger.info("mInvite", mInvite);
                  const {
                    scheduledMeetings,
                  } = meetingInvitesState;
                  const updatedScheduledMeetings = scheduledMeetings.concat([ mInvite ]).sort( // NOTE: sort by startdate
                    (a, b) => awsDateTimeStringToDateTime(
                      a.startDateTime,
                    ).toUnixInteger() - awsDateTimeStringToDateTime(
                      b.startDateTime,
                    ).toUnixInteger()
                  );
                  const activeMeetingInvites = getActiveMeetingInvites(
                    updatedScheduledMeetings,
                    cachedServerTimeDifference,
                    clockSyncEnabled,
                  );
                  if (
                    meetingInviteChangeDetected(meetingInvitesState.scheduledMeetings, activeMeetingInvites)
                  ) {
                    logger.info("setMeetingInvitesState #2");
                    setMeetingInvitesState(
                      {
                        scheduledMeetings: activeMeetingInvites,
                        numberOfScheduledMeetings: activeMeetingInvites.length,
                        numberOfEndedMeetings,
                      }
                    );
                  }
                } else {
                  const {
                    scheduledMeetings,
                  } = meetingInvitesState;
                  const updatedScheduledMeetings = scheduledMeetings.concat([ newMeetingInvite ]).sort( // NOTE: sort by startdate
                    (a, b) => awsDateTimeStringToDateTime(
                      a.startDateTime,
                    ).toUnixInteger() - awsDateTimeStringToDateTime(
                      b.startDateTime,
                    ).toUnixInteger()
                  );
                  const activeMeetingInvites = getActiveMeetingInvites(
                    updatedScheduledMeetings,
                    cachedServerTimeDifference,
                    clockSyncEnabled,
                  );
                  if (
                    meetingInviteChangeDetected(meetingInvitesState.scheduledMeetings, activeMeetingInvites)
                  ) {
                    logger.info("setMeetingInvitesState #3");
                    setMeetingInvitesState(
                      {
                        scheduledMeetings: activeMeetingInvites,
                        numberOfScheduledMeetings: activeMeetingInvites.length,
                        numberOfEndedMeetings,
                      }
                    );
                  }
                }
              }
            ).catch(
              (err: any) => {
                logger.error("get meetingInvite error", err);
              }
            );
          } else {
            const {
              scheduledMeetings,
            } = meetingInvitesState;
            const updatedScheduledMeetings = scheduledMeetings.concat([ newMeetingInvite ]).sort( // NOTE: sort by startdate
              (a, b) => awsDateTimeStringToDateTime(
                a.startDateTime,
              ).toUnixInteger() - awsDateTimeStringToDateTime(
                b.startDateTime,
              ).toUnixInteger()
            );
            const activeMeetingInvites = getActiveMeetingInvites(
              updatedScheduledMeetings,
              cachedServerTimeDifference,
              clockSyncEnabled,
            );
            if (
              meetingInviteChangeDetected(meetingInvitesState.scheduledMeetings, activeMeetingInvites)
            ) {
              logger.info("setMeetingInvitesState #4");
              setMeetingInvitesState(
                {
                  scheduledMeetings: activeMeetingInvites,
                  numberOfScheduledMeetings: activeMeetingInvites.length,
                  numberOfEndedMeetings,
                }
              );
            }
          }
        },
        error: (err: Error) => {
          logger.warn("onCreateMeetingInvite error", err);
        }
      });

      const onUpdateSubscription = API.graphql(
        graphqlOperation(onUpdateMeetingInvite, { owner: currentProfile?.userId })
      ).subscribe({
        next: ({ value }: any) => {
          logger.info("value.data.onUpdateMeetingInvite", value.data.onUpdateMeetingInvite);
          const updatedMeetingInvite = value.data.onUpdateMeetingInvite;

          const {
            providerId,
          } = updatedMeetingInvite;

          logEvent(
            currentProfile,
            "meeting update detected",
            {
              meeting: {
                id: updatedMeetingInvite.meetingId,
                invite_id: updatedMeetingInvite.Id,
              },
              details: updatedMeetingInvite,
            },
            'info',
            cachedServerTimeDifference,
            userAgentData,
          );

          const {
            scheduledMeetings,
            numberOfEndedMeetings,
          } = meetingInvitesState;
          logger.info("scheduledMeetings", scheduledMeetings);
          const index = scheduledMeetings.findIndex((m) => m.id == updatedMeetingInvite.id);

          if (!providerId) {
            logger.info("providerId is empty");

            if (index > -1) {
              const existingMeetingInvite = scheduledMeetings[index];
              logger.info("existingMeetingInvite", existingMeetingInvite);
              dataProvider.getOne(
                "meetingInvites",
                {
                  id: updatedMeetingInvite.id,
                },
              ).then(
                (results: any) => {
                  logger.info("meetingInvites results", results);
                  if (results && results.data) {
                    const mInvite = results.data as MeetingInvite;
                    logger.info("mInvite", mInvite);
                    // TODO merge updatedMeetingInvite
                    scheduledMeetings[index] = mInvite;  // updatedMeetingInvite;
                    const updatedScheduledMeetings = scheduledMeetings.sort( // NOTE: sort by startdate
                      (a, b) => awsDateTimeStringToDateTime(
                        a.startDateTime,
                      ).toUnixInteger() - awsDateTimeStringToDateTime(
                        b.startDateTime,
                      ).toUnixInteger()
                    );
                    const activeMeetingInvites = getActiveMeetingInvites(
                      updatedScheduledMeetings,
                      cachedServerTimeDifference,
                      clockSyncEnabled,
                    );
                    if (
                      meetingInviteChangeDetected(meetingInvitesState.scheduledMeetings, activeMeetingInvites)
                    ) {
                      logger.info("setMeetingInvitesState #5");
                      setMeetingInvitesState(
                        {
                          scheduledMeetings: activeMeetingInvites,
                          numberOfScheduledMeetings: activeMeetingInvites.length,
                          numberOfEndedMeetings,
                        }
                      );
                    }
                  } else {
                    scheduledMeetings[index] = updatedMeetingInvite;  // updatedMeetingInvite;
                    const updatedScheduledMeetings = scheduledMeetings.sort( // NOTE: sort by startdate
                      (a, b) => awsDateTimeStringToDateTime(
                        a.startDateTime,
                      ).toUnixInteger() - awsDateTimeStringToDateTime(
                        b.startDateTime,
                      ).toUnixInteger()
                    );
                    const activeMeetingInvites = getActiveMeetingInvites(
                      updatedScheduledMeetings,
                      cachedServerTimeDifference,
                      clockSyncEnabled,
                    );
                    if (
                      meetingInviteChangeDetected(meetingInvitesState.scheduledMeetings, activeMeetingInvites)
                    ) {
                      logger.info("setMeetingInvitesState #6");
                      setMeetingInvitesState(
                        {
                          scheduledMeetings: activeMeetingInvites,
                          numberOfScheduledMeetings: activeMeetingInvites.length,
                          numberOfEndedMeetings,
                        }
                      );
                    }
                  }
                }
              ).catch(
                (err: any) => {
                  logger.error("get meetingInvite error", err);
                }
              );
            }
          } else {
            if (index > -1) {
              const existingMeetingInvite = scheduledMeetings[index];
              scheduledMeetings[index] = updatedMeetingInvite;  // updatedMeetingInvite;
              const updatedScheduledMeetings = scheduledMeetings.sort( // NOTE: sort by startdate
                (a, b) => awsDateTimeStringToDateTime(
                  a.startDateTime,
                ).toUnixInteger() - awsDateTimeStringToDateTime(
                  b.startDateTime,
                ).toUnixInteger()
              );
              const activeMeetingInvites = getActiveMeetingInvites(
                updatedScheduledMeetings,
                cachedServerTimeDifference,
                clockSyncEnabled,
              );
              if (
                meetingInviteChangeDetected(meetingInvitesState.scheduledMeetings, activeMeetingInvites)
              ) {
                logger.info("setMeetingInvitesState #7");
                setMeetingInvitesState(
                  {
                    scheduledMeetings: activeMeetingInvites,
                    numberOfScheduledMeetings: activeMeetingInvites.length,
                    numberOfEndedMeetings,
                  }
                );
              }
            }
          }
        },
        error: (err: Error) => {
          logger.warn("onUpdateMeetingInvite error", err);
        }
      });

      const onDeleteSubscription = API.graphql(
        graphqlOperation(onDeleteMeetingInvite, { owner: currentProfile?.userId })
      ).subscribe({
        next: ({ value }: any) => {
          logger.info("value.data.onDeleteMeetingInvite", value.data.onDeleteMeetingInvite);
          const deletedMeetingInvite = value.data.onDeleteMeetingInvite;
          logEvent(
            currentProfile,
            'meeting delete detected',
            {
              meeting: {
                id: deletedMeetingInvite.meetingId,
                invite_id: deletedMeetingInvite.Id,
              },
              details: deletedMeetingInvite,
            },
            'info',
            cachedServerTimeDifference,
            userAgentData,
          );
          const {
            scheduledMeetings,
          } = meetingInvitesState;
          const updatedScheduledMeetings = scheduledMeetings.filter(
            (m) => m.id != deletedMeetingInvite.id
          ).sort( // NOTE: sort by startdate
            (a, b) => awsDateTimeStringToDateTime(
              a.startDateTime,
            ).toUnixInteger() - awsDateTimeStringToDateTime(
              b.startDateTime,
            ).toUnixInteger()
          );
          if (
            meetingInviteChangeDetected(meetingInvitesState.scheduledMeetings, updatedScheduledMeetings)
          ) {
            logger.info("setMeetingInvitesState #8");
            setMeetingInvitesState(
              {
                scheduledMeetings: updatedScheduledMeetings,
                numberOfScheduledMeetings: updatedScheduledMeetings.length,
                numberOfEndedMeetings,
              }
            );
          }
        },
        error: (err: Error) => {
          logger.warn("onDeleteMeetingInvite error", err);
        }
      });

      return () => {
        logger.info("unsubscribe called");
        if (onCreateSubscription) {
          onCreateSubscription.unsubscribe();
        }
        if (onUpdateSubscription) {
          onUpdateSubscription.unsubscribe();
        }
        if (onDeleteSubscription) {
          onDeleteSubscription.unsubscribe();
        }
      }
    }
  }, [
    currentProfile?.id,
    // TODO improve this
    meetingInvitesState,
    clockSyncEnabled,
    cachedServerTimeDifference,
  ]);

  useEffect(() => {
    logger.info("Cache nextMeetingInvite useeffect called...");
    // NOTE: update the nextMeetingInvite when scheduled meetings change
    if (currentProfile?.id) {
      const {
        scheduledMeetings,
      } = meetingInvitesState;
      logger.info("meetingInvitesState.scheduledMeetings", scheduledMeetings);
      if (scheduledMeetings.length > 0) {
        if (
          !nextMeetingInvite ||
          (
            nextMeetingInvite.id != scheduledMeetings[0].id ||
            (
              nextMeetingInvite.id == scheduledMeetings[0].id // && nextMeetingInvite != scheduledMeetings[0]
              // TODO better change detection
            )
          )
        ) {
          if (typeof scheduledMeetings[0].providerJoinData === "string") {
            scheduledMeetings[0].providerJoinData =  JSON.parse(scheduledMeetings[0].providerJoinData);
          }
          logger.info("setting nextMeetingInvite", scheduledMeetings[0]);
          setNextMeetingInvite(scheduledMeetings[0]);
          const privacyCacheName = privacyEnabledCacheName(scheduledMeetings[0].id);
          const cacheExpiration = new Date(Date.parse(scheduledMeetings[0].endDateTime) + 3600 * 1000);
          logger.info("cacheExpiration", cacheExpiration);
          Cache.setItem(
            privacyCacheName,
            currentProfile && currentProfile.privacyEnabled ? "True" : scheduledMeetings[0].privacyEnabled ? "True" : "False",
            { expires: cacheExpiration.getTime() },
          );
        }
      } else {
        if (nextMeetingInvite !== null) {
          logger.info("setting nextMeetingInvite to null");
          setNextMeetingInvite(null);
          Cache.removeItem("nextMeetingInvite"); // TODO deprecate
        }
      }
    }
  }, [
    currentProfile?.id,
    meetingInvitesState,
  ]);

  useEffect(() => {
    if (currentProfile?.id) {
      class TimeDriftDetector {
        static oldTime: number;
      }
      const clockChangeDetectionInterval = setInterval(
        () => {
          let oldTime = TimeDriftDetector.oldTime || new Date().getTime();
          let newTime = new Date().getTime();
          let timeDiff = newTime - oldTime;
          TimeDriftDetector.oldTime = newTime;
          if (Math.abs(timeDiff) > 10000) {
            logger.info("timeOffset time changed detected (seconds)", Math.floor(Math.abs(timeDiff) / 1000));
            setRequestServerTimeDifference(newTime);
          }
        },
        1000
      );
      return () => {
        if (clockChangeDetectionInterval) {
          try {
            clearInterval(clockChangeDetectionInterval);
          } catch {
            (err: Error) => { }
          }
        }
      };
    }
  }, [currentProfile?.id]);

  useEffect(() => {
    logger.info("[ws] getServerDate useEffect called...");
    let inMeeting = false;
    try {
      inMeeting = window.location.href.includes("join");
    } catch (err) { }

    logger.info("[ws] getServerDate cachedServerTimeDifference", cachedServerTimeDifference);

    if (currentProfile?.id && !inMeeting) {
      const serverTimeDifferences: number[] = [];
      const serverDateResponses: ServerDateResponse[] = [];
      let syncAttempts = 0;
      let requestStartTime = Date.now();
      let serverDateInterval: NodeJS.Timer;
      const socket = new WebSocket("wss://v89g17nle1.execute-api.us-east-1.amazonaws.com/production/");
      socket.onopen = function(e) {
        logger.info("[ws open] Connection established");
        serverDateInterval = setInterval(
          () => {
            requestStartTime = Date.now();
            logger.info("[ws send] Sending to server");
            syncAttempts = syncAttempts + 1;
            try {
              socket.send(JSON.stringify({"action": "date"}));
              if (syncAttempts >= 11) {
                throw new Error("maximum server date requests exceeded");
              }
            } catch(err) {
              logger.warn(`[ws] send error on attempt ${syncAttempts}`, err);
              if (syncAttempts >= 3) {
                if (serverDateInterval) {
                  try {
                    clearInterval(serverDateInterval);
                  } catch {
                    (err: Error) => { }
                  }
                }
                if (socket) {
                  try {
                    socket.close();
                  } catch {
                    (err: Error) => { }
                  }
                }
              }
            }
          },
          1 * 1000
        );
      };
      socket.onmessage = function(event) {
        const requestEndTime = Date.now();
        logger.info("[ws] syncAttempts", syncAttempts);
        logger.info("[ws] requestStartTime", requestStartTime);
        logger.info("[ws message] Data received from server", event.data);
        const serverDateResponse = JSON.parse(event.data) as ServerDateResponse;
        logger.info("[ws message] serverDateResponse", serverDateResponse);
        const totalRequestTimeInMillis = (requestEndTime > requestStartTime) ? requestEndTime - requestStartTime : 0;
        const {
          date,
          requestTimeEpoch,
        } = serverDateResponse;
        logger.info("[ws] getServerDate serverTimeDifference serverDateResponse", serverDateResponse);
        if (date) {
          if (requestTimeEpoch) {
            const requestProcessingTime = date - requestTimeEpoch; // running time of lambda function (should be greater than zero)
            let totalLatencyInMillis = totalRequestTimeInMillis - requestProcessingTime; // network latency (positive) TODO check that totalRequestTime is greater than requestProcessingTime
            if (totalLatencyInMillis < 0) {
              logger.warn("[ws] totalLatencyInMillis is negative", { totalRequestTimeInMillis, requestProcessingTime });
              totalLatencyInMillis = 0;
            }
            // NOTE: assumption that 60% of the remaining latency is 60% request and 40% response.
            const estimatedResponseLatency = Math.round(totalLatencyInMillis * 0.40); // estimated response latency (positive)
            // const timeDifference = (date + estimatedResponseLatency) - requestEndTime; // serverTime - localTime = positive if server is ahead / negative if local is ahead
            const timeDifference = date - requestEndTime;
            serverTimeDifferences.push(timeDifference);
            serverDateResponses.push(serverDateResponse);
            logger.info("[ws] 1 getServerDate serverTimeDifferences.length", serverTimeDifferences.length);
            logger.info("[ws] 1 getServerDate serverDateResponses.length", serverDateResponses.length);
            /* if (serverTimeDifferences.length > 10) {
              serverTimeDifferences.shift()
            }
            if (serverDateResponses.length > 10) {
              serverDateResponses.shift()
            } */
            logger.info("[ws] 2 getServerDate serverTimeDifferences.length", serverTimeDifferences.length);
            logger.info("[ws] 2 getServerDate serverDateResponses.length", serverDateResponses.length);
            const averageTimeDifference = Math.round(
              serverTimeDifferences.reduce(
                (previousValue, currentValue) => previousValue + currentValue
              ) / serverTimeDifferences.length
            );
            logger.info("[ws] getServerDate averageTimeDifference", averageTimeDifference);
            logger.info("[ws] getServerDate serverTimeDifferences.length", serverTimeDifferences.length);
            if (serverTimeDifferences.length >= 3) {
              try {
                clearInterval(serverDateInterval);
                logger.info("[ws] clearInterval successful");
              } catch {
                (err: Error) => { }
              }
              try {
                socket.close();
                logger.info("[ws] socket.close successful");
              } catch {
                (err: Error) => { }
              }
              logEvent(
                currentProfile,
                'updated server time offset',
                {
                  details: {
                    averageTimeDifference,
                    serverTimeDifferences: serverTimeDifferences,
                    serverDateResponses: serverDateResponses,
                  }
                },
                'warn',
                averageTimeDifference,
                userAgentData,
              );
              const clockIsOutOfAcceptableRange = Math.abs(averageTimeDifference) > 5000;
              const cachedServerTimeDifferenceChange = Math.abs(averageTimeDifference - cachedServerTimeDifference);
              logger.info("[ws] cachedServerTimeDifferenceChange", cachedServerTimeDifferenceChange);
              logger.info("[ws] socket.close successful");
              if (cachedServerTimeDifference === 0 || cachedServerTimeDifferenceChange > 2000) {
                setCachedServerTimeDifference(averageTimeDifference);
              } else {
                logger.info("[ws] skipping setCachedServerTimeDifference");
              }
              if (clockIsOutOfAcceptableRange) {
                logEvent(
                  currentProfile,
                  'high server time difference detected',
                  {
                    details: serverDateResponse,
                  },
                  'warn',
                  averageTimeDifference,
                  userAgentData,
                );
                if (!clockSyncEnabled) {
                  // NOTE: alert if server time is off by more than 5 seconds
                  ReactSwal.fire(
                    "Please check your clock!",
                    "Your clock is off by more than 5 seconds.",
                    "warning"
                  );
                }
              }
            }
          }
        }
      };

      socket.onclose = function(event) {
        if (event.wasClean) {
          logger.warn(`[ws close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
          // e.g. server process killed or network down
          // event.code is usually 1006 in this case
          logger.warn('[ws close] Connection died');
        }
        if (serverDateInterval) {
          try {
            clearInterval(serverDateInterval);
          } catch {
            (err: Error) => { }
          }
        }
      };

      socket.onerror = function(error) {
        logger.warn(`[ws error]`, error);
      };

      return () => {
        if (serverDateInterval) {
          try {
            clearInterval(serverDateInterval);
          } catch {
            (err: Error) => { }
          }
        }
        if (socket) {
          try {
            socket.close();
          } catch {
            (err: Error) => { }
          }
        }
      }
    }
  }, [
    currentProfile?.id,
    requestServerTimeDifference,
  ]);

  useEffect(() => {
    logger.info("getMeetingProductInformation useeffect called...");
    // NOTE: Update NextMeetingProductInformation when nextMeetingInvite is updated
    if (!nextMeetingInvite || !nextMeetingInvite?.productId) {
      if (nextMeetingProductInformation) {
        logger.info("setting nextMeetingProductInformation to null");
        setNextMeetingProductInformation(null);
      } else {
        logger.info("nextMeetingProductInformation no change detected", nextMeetingInvite);
      }
    } else if (nextMeetingInvite && nextMeetingInvite.productId) {
      getMeetingProductInformation(
        nextMeetingInvite,
        dataProvider,
      ).then(
        (meetingProductInformation) => {
          logger.info("nextMeetingProductInformation", meetingProductInformation);
          setNextMeetingProductInformation(meetingProductInformation);
        }
      ).catch(
        (err: any) => {
          logger.warn(`error getting product information ${err}`);
        }
      );
    }
    if (nextMeetingInvite) {
      const updatedIsSeller = nextMeetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER;
      if (isSeller !== updatedIsSeller) {
        setIsSeller(updatedIsSeller);
      }
      const updatedIsBuyer  = nextMeetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE;
      if (isBuyer !== updatedIsBuyer) {
        setIsBuyer(updatedIsBuyer);
      }
    }
  }, [currentProfile?.id, nextMeetingInvite]); // TODO nextMeetingInvite?.productId

  useEffect(() => {
    logger.info("previousMeetingInvite useeffect called...");
    // NOTE: Update PreviousMeetingProductInformation when previousMeetingInvite is updated
    if (!previousMeetingInvite || !previousMeetingInvite?.productId) {
      if (previousMeetingProductInformation) {
        logger.info("setting previousMeetingProductInformation to null");
        setPreviousMeetingProductInformation(null);
      } else {
        logger.info("previousMeetingProductInformation no change detected");
      }
    } else if (previousMeetingInvite && previousMeetingInvite.productId) {
      if (
        true
        /* !previousMeetingProductInformation ||
        (
          previousMeetingProductInformation.product.id !== previousMeetingInvite.productId ||
          previousMeetingProductInformation.meetingInvite.id !== previousMeetingInvite.id // TODO better change detection
        ) */
      ) {
        getMeetingProductInformation(
          previousMeetingInvite,
          dataProvider,
        ).then(
          (meetingProductInformation) => {
            logger.info("previousMeetingProductInformation", meetingProductInformation);
            setPreviousMeetingProductInformation(meetingProductInformation);
          }
        ).catch(
          (err: any) => {
            logger.warn(`error getting product information ${err}`);
          }
        );
        if (!nextMeetingInvite) {
          logger.info("using nextMeetingInvite to set isSeller or isBuyer");
          const updatedIsSeller = previousMeetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER;
          if (isSeller !== updatedIsSeller) {
            logger.info("setting isSeller");
            setIsSeller(updatedIsSeller);
          }
          const updatedIsBuyer  = previousMeetingInvite.meetingInvitationType === MeetingInvitationType.INVITEE;
          if (isBuyer !== updatedIsBuyer) {
            logger.info("setting isBuyer");
            setIsBuyer(updatedIsBuyer);
          }
        }
      }
    }
  }, [currentProfile?.id, previousMeetingInvite]);

  useEffect(() => {
    // logger.info("custom theme defaultTheme.palette", defaultTheme.palette);
    logger.info("custom theme cookies", cookies);
    let customDarkPalette: any = null;
    let customLightPalette: any = null;
    if (!customThemeChecked) {
      logger.info("custom theme checking...");
      const init = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      API.get(
        "IgwPublicREST",
        "/themes/settings",
        init,
      ).then((response: ThemeSettingsResponse) => {
        logger.info("custom theme response", response);
        const {
          palettes,
          logos
        } = response;
        if (palettes) {
          const {
            light: lightPalette,
            dark: darkPalette,
          } = palettes;
          if (lightPalette) {
            const {
              // title,
              // themeType,
              primaryLightColor,
              primaryMainColor,
              primaryDarkColor,
              primaryContrastTextColor,
              secondaryLightColor,
              secondaryMainColor,
              secondaryDarkColor,
              secondaryContrastTextColor,
              backgroundColor,
              organizationId,
              activationStartDateTime,
              activationEndDateTime,
            } = lightPalette

            customLightPalette = {
              type: "light",
              primary: {
                light: primaryLightColor,
                main: primaryMainColor,
                dark: primaryDarkColor,
                contrastText: primaryContrastTextColor,
              },
              secondary: {
                light: secondaryLightColor,
                main: secondaryMainColor,
                dark: secondaryDarkColor,
                contrastText: secondaryContrastTextColor,
              },
              background: {
                default: backgroundColor,
              },
            };
            if (!backgroundColor || ['transparent', '#11ffee00', '#1fe0'].includes(backgroundColor?.toLowerCase())) {
              delete customLightPalette['background'];
            }
            setCookie('customLightPalette', JSON.stringify(customLightPalette));
            logger.info("custom theme setting light theme.");
            try {
              const overrideCustomLightThemeOptions = {
                ...customLightThemeOptions,
                palette: customLightPalette,
              };
              setOverrideCustomLightTheme(createTheme(overrideCustomLightThemeOptions));
            } catch(err) {
              logger.error(err);
            }
          }
          if (darkPalette) {
            const {
              // title,
              // themeType,
              primaryLightColor,
              primaryMainColor,
              primaryDarkColor,
              primaryContrastTextColor,
              secondaryLightColor,
              secondaryMainColor,
              secondaryDarkColor,
              secondaryContrastTextColor,
              backgroundColor,
              organizationId,
              activationStartDateTime,
              activationEndDateTime,
            } = darkPalette;
            customDarkPalette = {
              type: "dark",
              primary: {
                light: primaryLightColor,
                main: primaryMainColor,
                dark: primaryDarkColor,
                contrastText: primaryContrastTextColor,
              },
              secondary: {
                light: secondaryLightColor,
                main: secondaryMainColor,
                dark: secondaryDarkColor,
                contrastText: secondaryContrastTextColor,
              },
              background: {
                default: backgroundColor,
              },
            };
            if (!backgroundColor || ['transparent', '#11ffee00', '#1fe0'].includes(backgroundColor?.toLowerCase())) {
              delete customDarkPalette['background'];
            }
            setCookie('customDarkPalette', JSON.stringify(customDarkPalette));
            logger.info("custom theme setting dark theme.");
            try {
              const overrideCustomDarkThemeOptions = {
                ...customDarkThemeOptions,
                palette: customDarkPalette,
              };
              setOverrideCustomDarkTheme(createTheme(overrideCustomDarkThemeOptions));
            } catch(err) {
              logger.error(err);
            }
          }
        }
        if (logos) {
          const {
            default: defaultLogo,
            inApp: inAppLogo,
          } = logos;
          if (defaultLogo) {
            logger.info("custom theme setting in default logo.");
            setCookie('overrideDefaultLogo', JSON.stringify(defaultLogo));
            setOverrideDefaultLogo(defaultLogo);
          }
          if (inAppLogo) {
            logger.info("custom theme setting in app logo.");
            setCookie('overrideInAppLogo', JSON.stringify(inAppLogo));
            setOverrideInAppLogo(inAppLogo);
          }
        }
      }).finally(
        () => {
          logger.info("custom theme checking finished", cookies);
          setCustomThemeChecked(true);
          const hasCustomDarkOrLightTheme = (overrideCustomDarkTheme || overrideCustomLightTheme);
          logger.info("custom theme hasCustomDarkOrLightTheme", hasCustomDarkOrLightTheme);
          setCookie(
            "hasCustomTheme",
            hasCustomDarkOrLightTheme ? "1" : "0",
          );
        }
      );
    } else {
      logger.info("custom theme skip checking.");
      /* const hasCustomDarkOrLightTheme = (
        cookies.hasOwnProperty('customDarkPalette')
          && cookies.customDarkPalette)
          || (cookies.hasOwnProperty('customLightPalette')
          && cookies.customLightPalette); */
      logger.info("custom theme cookies.hasCustomTheme", cookies.hasCustomTheme);
      if (
        cookies.hasOwnProperty('customDarkPalette') && cookies.customDarkPalette
      ) {
        logger.info("custom theme setting dark theme.");
        try {
          const overrideCustomDarkThemeOptions = {
            ...customDarkThemeOptions,
            palette: cookies.customDarkPalette,
          };
          setOverrideCustomDarkTheme(createTheme(overrideCustomDarkThemeOptions));
        } catch(err) {
          logger.error(`custom theme parse error ${cookies.customDarkPalette}`, err);
        }
      }
      if (
        cookies.hasOwnProperty('customLightPalette') && cookies.customLightPalette
      ) {
        logger.info("custom theme setting light theme.");
        try {
          const overrideCustomLightThemeOptions = {
            ...customLightThemeOptions,
            palette: cookies.customLightPalette,
          };
          setOverrideCustomDarkTheme(createTheme(overrideCustomLightThemeOptions));
        } catch(err) {
          logger.info(`custom cookies.customLightPalette`, cookies.customLightPalette);
          logger.error(`custom theme parse error`, err);
        }
      }
    }
  }, []);

  const initialState = {
    admin: { ui: { sidebarOpen: false, viewVersion: 0 } } // , automaticRefreshEnabled: false
  };

  logger.info(
    "App render called...",
    {
      clockSyncEnabled,
      cachedServerTimeDifference,
    }
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MeetingsStateContext.Provider value={{
        meetingInvitesState,
        nextMeetingProductInformation,
        nextMeetingInvite,
        isSeller,
        isBuyer,
        isAdmin,
        previousMeetingProductInformation,
        previousMeetingInvite,
        currentProfile,
        userAgentData,
        isApplicationOutdated,
        inAppLogo: overrideInAppLogo || undefined,
        defaultLogo: overrideDefaultLogo || undefined,
      }}>
        {customThemeChecked ? (<Admin
          initialState={initialState}
          authProvider={authProvider}
          dataProvider={dataProvider}
          dashboard={DashboardPage}
          customRoutes={customRoutes}
          title={SITE_TITLE}
          disableTelemetry={true}
          layout={AdminLayout}
          loginPage={SignIn}
          theme={overrideCustomLightTheme || customTheme}
          lightTheme={overrideCustomLightTheme || customLightTheme}
          darkTheme={overrideCustomDarkTheme || customDarkTheme}
        >
          {(permissions) => [
            <Resource
              name="teams"
              options={{ label: "Teams" }}
              icon={GroupIcon}
              list={
                (permissions.includes("superuser") || permissions.includes("admin")) ? TeamList : undefined
              }
              edit={TeamEdit}
              show={TeamShow}
              create={TeamCreate}
            />,
            <Resource
              name="teamImages"
              options={{ label: "Team Images" }}
              icon={AssignmentIndIcon}
              list={
                (permissions.includes("superuser")) ? TeamImageList : undefined
              }
              edit={TeamImageEdit}
              show={TeamImageShow}
              create={TeamImageCreate}
            />,
            <Resource
              name="teamMemberRelationships"
              options={{ label: "Members" }}
              icon={GroupWorkIcon}
              list={
                (permissions.includes("superuser") || permissions.includes("admin")) ? TeamMemberRelationshipList : undefined
              }
              edit={TeamMemberRelationshipEdit}
              show={TeamMemberRelationshipShow}
              create={TeamMemberRelationshipCreate}
            />,
            <Resource
              name="meetings"
              options={{ label: "Meetings" }}
              icon={CameraFrontIcon}
              list={
                (permissions.includes("superuser") || permissions.includes("admin")) ? MeetingList : undefined
              }
              edit={MeetingEdit}
              show={MeetingShow}
              create={MeetingCreate}
            />,
            <Resource
              name="teamMeetingRelationships"
              options={{ label: "Meeting Team" }}
              icon={CameraFrontIcon}
              list={
                (permissions.includes("superuser")) ? TeamMeetingRelationshipList : undefined
              }
              edit={TeamMeetingRelationshipEdit}
              show={TeamMeetingRelationshipShow}
              create={TeamMeetingRelationshipCreate}
            />,
            <Resource
              name="meetingRooms"
              options={{ label: "Rooms" }}
              icon={MeetingRoomIcon}
              list={
                (permissions.includes("superuser")) ? MeetingRoomList : undefined
              }
              edit={MeetingRoomEdit}
              show={MeetingRoomShow}
            />,
            <Resource
              key="100"
              name="meetingInvites"
              options={{ label: "Invites" }}
              icon={ScheduleIcon}
              list={
                (permissions.includes("superuser")) ? MeetingInviteList : undefined
              }
              edit={
                (permissions.includes("superuser") || permissions.includes("admin")) ? MeetingInviteEdit : undefined
              }
              show={MeetingInviteShow}
            />,
            <Resource
              name="products"
              options={{ label: "Products" }}
              icon={ReceiptIcon}
              list={
                (permissions.includes("superuser") || permissions.includes("admin")) ? ProductList : undefined
              }
              edit={ProductEdit}
              show={ProductShow}
              create={ProductCreate}
            />,
            <Resource
              name="productImages"
              options={{ label: "Images" }}
              icon={PhotoLibraryIcon}
              list={
                (permissions.includes("superuser")) ? ProductImageList : undefined
              }
              edit={ProductImageEdit}
              show={ProductImageShow}
              create={ProductImageCreate}
            />,
            <Resource
              name="productQuestions"
              options={{ label: "Questions" }}
              icon={HelpIcon}
              list={
                (permissions.includes("superuser") || permissions.includes("admin")) ? ProductQuestionList : undefined
              }
              edit={ProductQuestionEdit}
              show={ProductQuestionShow}
              create={ProductQuestionCreate}
            />,
            <Resource
              name="questionResponses"
              options={{ label: "Responses" }}
              icon={RateReviewIcon}
              list={
                (permissions.includes("superuser") || permissions.includes("admin")) ? QuestionResponseList : undefined
              }
              edit={QuestionResponseEdit}
              show={QuestionResponseShow}
              create={QuestionResponseCreate}
            />,
            <Resource
              name="meetingRoomEventAuditLogs"
              options={{ label: "Meeting Logs" }}
              icon={ArchiveIcon}
              list={
                permissions.includes("superuser") ? MeetingRoomEventAuditLogList : undefined
              }
              show={MeetingRoomEventAuditLogShow}
            />,
            <Resource
              name="organizations"
              options={{ label: "Companies" }}
              icon={BusinessIcon}
              list={
                (permissions.includes("superuser") || permissions.includes("admin")) ? OrganizationList : undefined
              }
              edit={OrganizationEdit}
              show={OrganizationShow}
              create={OrganizationCreate}
            />,
            <Resource
              name="organizationLocations"
              options={{ label: "Locations" }}
              icon={LocationCityIcon}
              list={
                permissions.includes("superuser") ? OrganizationLocationList : undefined
              }
              edit={OrganizationLocationEdit}
              show={OrganizationLocationShow}
              create={OrganizationLocationCreate}
            />,            <Resource
            name="organizationLogos"
            options={{ label: "Logos" }}
            icon={CopyrightIcon}
            list={
              (permissions.includes("superuser") || permissions.includes("admin")) ? OrganizationLogoList : undefined
            }
            edit={OrganizationLogoEdit}
            show={OrganizationLogoShow}
            create={OrganizationLogoCreate}
          />,
            <Resource
              name="organizationThemes"
              options={{ label: "Themes" }}
              icon={SettingsBrightnessIcon}
              list={
                (permissions.includes("superuser") || permissions.includes("admin")) ? OrganizationThemeList : undefined
              }
              edit={OrganizationThemeEdit}
              show={OrganizationThemeShow}
              create={OrganizationThemeCreate}
            />,
            <Resource
              name="profiles"
              options={{ label: "Profiles" }}
              icon={ProfileIcon}
              list={
                (permissions.includes("superuser") || permissions.includes("admin")) ? ProfileList : undefined
              }
              // @ts-ignore
              edit={ProfileEdit}
              // @ts-ignore
              show={ProfileShow}
              create={ProfileCreate}
            />,
            <Resource
              name="profileImages"
              options={{ label: "Images" }}
              icon={AssignmentIndIcon}
              list={
                (permissions.includes("superuser")) ? ProfileImageList : undefined
              }
              edit={ProfileImageEdit}
              show={ProfileImageShow}
              create={ProfileImageCreate}
            />,
            <Resource
              name="profileLocations"
              options={{ label: "Locations" }}
              icon={ProfilePinIcon}
              list={
                (permissions.includes("superuser")) ? ProfileLocationList : undefined
              }
              edit={ProfileLocationEdit}
              show={ProfileLocationShow}
              create={ProfileLocationCreate}
            />,
            (permissions.includes("superuser") || permissions.includes("admin")) ? (
            <Resource
              name="profileDevices"
              options={{ label: "Devices" }}
              icon={DesktopMacIcon}
              list={ProfileDeviceList}
              show={ProfileDeviceShow}
            />
            ) : null,
            (permissions.includes("superuser") || permissions.includes("admin")) ? (
            <Resource
              name="exportJobs"
              options={{ label: "Export" }}
              list={ExportJobList}
              show={ExportJobShow}
              create={ExportJobCreate}
              icon={ImportExportIcon}
            />
            ) : null,
            (permissions.includes("superuser") || permissions.includes("admin")) ? (
            <Resource
              name="exportJobAuditLogs"
              options={{ label: "Export Logs" }}
              list={ExportJobAuditLogList}
              show={ExportJobAuditLogShow}
              icon={NotificationImportantIcon}
            />
            ) : null,
            (permissions.includes("superuser") || permissions.includes("admin")) ? (
              <Resource
                name="importJobs"
                options={{ label: "Import" }}
                list={ImportJobList}
                show={ImportJobShow}
                create={ImportJobCreate}
                icon={ImportExportIcon}
              />
            ) : null,
            (permissions.includes("superuser") || permissions.includes("admin")) ? (
              <Resource
                name="importJobAuditLogs"
                options={{ label: "Import Logs" }}
                list={
                  permissions.includes("superuser") ? ImportJobAuditLogList : undefined
                }
                show={ImportJobAuditLogShow}
                icon={NotificationImportantIcon}
              />
            ) : null,
            (permissions.includes("superuser") || permissions.includes("admin")) ? (
              <Resource
                name="transcriptions"
                list={TranscriptionList}
                show={TranscriptionShow}
                icon={SpeakerNotesIcon}
              />
            ) : null,
            (permissions.includes("superuser")) ? (
              <Resource
                name="transcriptionEventAuditLogs"
                options={{ label: "Transcript Entries" }}
                list={
                  permissions.includes("superuser") ? TranscriptionEventAuditLogList : undefined
                }
                show={TranscriptionEventAuditLogShow}
                icon={SubtitlesIcon}
              />
            ) : null,
            <Resource
              name="transcriptionJobs"
              options={{ label: "Jobs" }}
              list={
                permissions.includes("superuser") ? TranscriptionJobList : undefined
              }
              show={TranscriptionJobShow}
              icon={SpeakerNotesIcon}
            />,
            <Resource
              name="transcriptionJobAuditLogs"
              options={{ label: "Logs" }}
              list={
                permissions.includes("superuser") ? TranscriptionJobAuditLogList : undefined
              }
              show={TranscriptionJobAuditLogShow}
              icon={NotesIcon}
            />,
            (permissions.includes("superuser") || permissions.includes("admin")) ? (
              <Resource
                name="transcriptionAdminJobs"
                options={{ label: "Admin Jobs" }}
                list={
                  permissions.includes("superuser") ? TranscriptionAdminJobList : undefined
                }
                show={TranscriptionAdminJobShow}
                icon={SpeakerNotesIcon}
              />
            ) : null,
            (permissions.includes("superuser") || permissions.includes("admin")) ? (
              <Resource
                name="transcriptionAdminJobAuditLogs"
                options={{ label: "Admin Logs" }}
                list={
                  permissions.includes("superuser") ? TranscriptionAdminJobAuditLogList : undefined
                }
                show={TranscriptionAdminJobAuditLogShow}
                icon={NotesIcon}
              />
            ) : null,
            (permissions.includes("superuser")) ? (
              <Resource
                name="meetingAttendees"
                options={{ label: "Attendees" }}
                list={
                  permissions.includes("superuser") ? MeetingAttendeeList : undefined
                }
                show={MeetingAttendeeShow}
                icon={EventAvailableIcon}
              />
            ) : null,
            <Resource
              name="meetingRoomProviders"
              options={{ label: "Providers" }}
              icon={DuoIcon}
              list={
                (permissions.includes("superuser")) ? MeetingRoomProviderList : undefined
              }
              edit={MeetingRoomProviderEdit}
              show={MeetingRoomProviderShow}
              create={MeetingRoomProviderCreate}
            />,
            (permissions.includes("superuser")) ? (
              <Resource
                name="cognitoUsers"
                options={{ label: "Users" }}
                list={
                  permissions.includes("superuser") ? CognitoUserList : undefined
                }
                show={CognitoUserShow}
                icon={ProfileOutlineIcon}
              />
            ) : null,
            (permissions.includes("superuser") || permissions.includes("admin")) ? (
              <Resource
                name="forwardEmails"
                options={{ label: "Forward Email" }}
                list={ForwardEmailList}
                edit={ForwardEmailEdit}
                show={ForwardEmailShow}
                create={ForwardEmailCreate}
                icon={EmailIcon}
              />
            ) : null,
            (permissions.includes("superuser")) ? (
              <Resource
                name="cognitoGroups"
                options={{ label: "User Groups" }}
                list={
                  permissions.includes("superuser") ? CognitoGroupList : undefined
                }
                icon={PeopleOutlineIcon}
              />
            ) : null,
          ]}
        </Admin>) :<Loading loadingPrimary="Loading" loadingSecondary="custom theme..." />
      }
      </MeetingsStateContext.Provider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
