import { DateTime } from "luxon";
import {
  API,
  Auth,
  graphqlOperation,
  Logger,
  StorageClass,
} from "aws-amplify";
import type { GraphQLResult } from "@aws-amplify/api";
import type { DataProvider, GetListResult } from "ra-core";
import {
  JobStatus,
  MeetingStatus,
  ModelSortDirection,
  TeamMeetingRelationshipType,
} from "../API";
import type {
  CreateProfileInput,
  CreateProfileMutation,
  CreateMeetingRoomEventAuditLogInput,
  CreateMeetingRoomEventAuditLogMutation,
  ListMeetingInvitesByProfileIdQuery,
  ListTeamMeetingRelationshipsByMeetingIdAndRelationshipTypeQuery,
  ListTeamMemberRelationshipsByTeamIdQuery,
  ModelTeamMeetingRelationshipConnection,
  ModelTeamMemberRelationshipFilterInput,
  GetMeetingRoomQuery,
  GetMeetingQuery,
  GetMeetingInviteQuery,
  ListMeetingsByStatusQuery,
  ListTeamMeetingRelationshipsByMeetingIdQuery,
  MeetingRoomEventAuditLog,
  Meeting,
  MeetingInvite,
  MeetingRoom,
  ModelMeetingInviteFilterInput,
  Product,
  Profile,
  ListProfilesQuery,
  QuestionResponse,
  Team,
  TeamImage,
  TeamMeetingRelationship,
  TranscriptionJob,
  TranscriptionAdminJob,
  UpdateProfileInput,
  UpdateProfileMutation,
  UpdateMeetingInviteInput,
  UpdateMeetingInviteMutation,
  ModelNotificationFilterInput,
  ListNotificationsByReadQuery,
  Notification,
  ProfileDevice,
  CreateProfileDeviceInput,
  Organization,
  ListForwardEmailsBySellerProfileIdQuery,
  ModelForwardEmailConnection,
  ForwardEmail,
} from "../API";
import { APPLY_SERVER_OFFSET, AWS_TIMESTAMP_AS_LUXON_FORMAT } from "../constants";
import {
  getMeeting as getMeetingQuery,
  getMeetingInvite as getMeetingInviteQuery,
  getMeetingRoom as getMeetingRoomQuery,
  listMeetingInvitesByProfileId,
  listMeetingsByStatus,
  listProfiles,
  listProfilesByEmail,
  listProfilesByUserId,
  listTeamMeetingRelationshipsByMeetingId,
  listTeamMeetingRelationshipsByMeetingIdAndRelationshipType,
  listTeamMemberRelationshipsByTeamId,
  listNotificationsByRead,
  listForwardEmailsBySellerProfileId,
} from "../graphql/queries";
import type {
  GraphQLErrorResponse,
  IMeetingProductInformation,
  SettingsVersionResponse,
  PublicProfile,
  CreateReadinessMeetingRestApiInput,
  CreateReadinessMeetingRestApiResponse,
  ISqsSendMessageResponse,
  StartTranscriptionAdminJob,
  IProfileDeviceStatsResponse,
  CreateQuestionResponseRestApiInput,
  UpdateQuestionResponseRestApiInput,
  QuestionResponseRestApiResponse,
  ISellerFeedbackResponse,
  UpdateMeetingBreakRestInput,
  ThemeSettingsLogo,
} from "../types";
import {
  createProfile,
  updateMeetingInvite,
  updateProfile,
  createMeetingRoomEventAuditLog,
} from "../graphql/mutations";
import type { IResult as UserAgentParserResult } from "ua-parser-js";
import { StorageAccessLevel } from "@aws-amplify/storage";

const logger = new Logger("helpers");

/**
 * Converts an AWS date time string to a luxon DateTime
 *
 * @param {string} dateTimeString AWS date as a string
 * @returns {DateTime} DateTime
 */
export function awsDateTimeStringToDateTime(dateTimeString: string): DateTime {
  return DateTime.fromFormat(
    dateTimeString,
    AWS_TIMESTAMP_AS_LUXON_FORMAT,
    { zone: "utc" },
  )
}

/**
 * Determine if a meeting or meeting invite is within the current date time range
 *
 * @param {MeetingInvite | Meeting} session meeting invite or meeting
 * @param {number} serverTimeDifference server time difference
 * @param {boolean} applyServerOffset apply server offset
 * @returns {boolean} true or false
 */
export function inDateTimeRange(
  session: MeetingInvite | Meeting,
  serverTimeDifference: number,
  applyServerOffset=false,
): boolean {
  const nowTimestamp = getCurrentDateTime(
    serverTimeDifference,
    applyServerOffset,
  ).toUnixInteger();
  logger.debug("nowTimestamp", nowTimestamp);
  const {
    startDateTime,
    endDateTime,
  } = session;
  const startTimestamp = awsDateTimeStringToDateTime(
    startDateTime,
  ).toUnixInteger();
  const endTimestamp = awsDateTimeStringToDateTime(
    endDateTime,
  ).toUnixInteger();
  logger.debug("startTimestamp", startTimestamp);
  logger.debug("endTimestamp", endTimestamp);
  return (
    (startTimestamp === nowTimestamp || nowTimestamp > startTimestamp) &&
    (nowTimestamp < endTimestamp)
  );
}

/**
 * Is a meeting or meeting invite expired
 *
 * @param {MeetingInvite | Meeting} session meeting invite or meeting
 * @param {number} serverTimeDifference server time difference
 * @param {boolean} applyServerOffset apply server offset
 * @returns {boolean} true or false
 */
export function isExpiredMeeting(
  session: MeetingInvite | Meeting,
  serverTimeDifference: number,
  applyServerOffset=false,
): boolean {
  const nowTimestamp = getCurrentDateTime(
    serverTimeDifference,
    applyServerOffset,
  ).toUnixInteger();
  const {
    endDateTime,
  } = session;
  const endTimestamp = awsDateTimeStringToDateTime(
    endDateTime,
  ).toUnixInteger();
  logger.debug("isExpiredMeeting nowTimestamp", nowTimestamp);
  logger.debug("isExpiredMeeting endTimestamp", endTimestamp);
  logger.debug("isExpiredMeeting =", (nowTimestamp >= endTimestamp));
  return (
    (nowTimestamp >= endTimestamp)
  );
}

/**
 * Play a file sound
 *
 *
 * @param {string} filename file name
 * @returns void
 */
export function playSound(filename: string, volume: number = 1) {
  try {
    const notificationAudio = new Audio(`https://cdn.insightgateway.com/sounds/${filename}`);
    if (volume) {
      console.info("playSound volume", volume);
      try {
        notificationAudio.volume = volume;
      } catch (err) {
        console.error("failed to set volume", err);
      }
    }
    notificationAudio.play().catch(
      (err) => {
        logger.warn("failed to play the end of meeting alert", err);
      }
    );
  } catch (err) {
    logger.warn("failed to play the end of meeting alert", err);
  }
}

/**
 * Does a meeting or meeting invite start in X seconds
 *
 * @param {MeetingInvite | Meeting} session meeting invite or meeting
 * @param {number} seconds seconds
 * @param {number} serverTimeDifference server time difference
 * @param {boolean} applyServerOffset apply server offset
 * @returns {boolean} true or false
 */
 export function startsInSeconds(
  session: MeetingInvite | Meeting,
  seconds: number,
  serverTimeDifference: number = 0,
  applyServerOffset=false,
): boolean {
  const startDateTime = DateTime.fromFormat(
    session.startDateTime,
    AWS_TIMESTAMP_AS_LUXON_FORMAT,
    { zone: "utc" }
  );
  const nowTimestamp = getCurrentDateTime(
    serverTimeDifference,
    applyServerOffset,
  ).toUTC().toUnixInteger();
  const startTimestamp = startDateTime.toUnixInteger();
  const startsInAsSeconds = Math.floor(startTimestamp - nowTimestamp);
  return startsInAsSeconds === seconds;
}

export function transformQuestionResponse(data: UpdateQuestionResponseRestApiInput): UpdateQuestionResponseRestApiInput {
  const validQuestionResponseFields = [
    "id",
    "response",
    "privateNotes",
    "questionId",
    "profileId",
    "meetingId",
    "meetingInviteId",
    "owner",
    "contactRequestTimeframe",
    "contactRequestDateTime",
    "contactPhoneNumber",
    "contactEmail",
    "contactName",
    "providePersonalEmail",
    "providePersonalPhoneNumber",
    "textOnly",
    "followupMeetingRequested",
    "version",
  ];
  const dataKeys = Object.keys(data);
  for (let i = 0; i < dataKeys.length; i=i+1) {
    const dataKey = dataKeys[i];
    if (!validQuestionResponseFields.includes(dataKey)) {
      // @ts-ignore
      delete data[dataKey];
    }
  }
  const updateData: UpdateQuestionResponseRestApiInput = {
    ...data,
  };
  return updateData;
};

/**
 * Search for profiles by full name or email
 *
   * @param {{ fullName?: string, email?: string}} query query
   * @param {string} query.fullName fullName
   * @param {string} query.email email
 * @returns {Promise<Profile[]>} list of profiles
 */
export async function searchProfiles(query: { name?: string, email?: string}): Promise<Profile[]> {
  const init = {
    body: query,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(
    "IgwSearchApi",
    "/profiles/search",
    init,
  )
  .then((response: Profile[]) => {
    return response;
  });
}

/**
 * Search for products by name
 *
 * @param {string} name keyword for search
 * @returns {Promise<Product[]>} list of products
 */
export async function searchProducts(name: string): Promise<Product[]> {
  const init = {
    body: { name },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(
    "IgwSearchApi",
    "/products/search",
    init,
  )
  .then((response: Product[]) => {
    return response;
  });
}

/**
 * Search for teams by name
 *
 * @param {string} name keyword for search
 * @returns {Promise<Team[]>} list of teams
 */
export async function searchTeams(name: string): Promise<Team[]> {
  const init = {
    body: { name },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(
    "IgwSearchApi",
    "/teams/search",
    init,
  )
  .then((response: Team[]) => {
    return response;
  });
}

/**
 * Search for organizations by name
 *
 * @param {string} name keyword for search
 * @returns {Promise<Organization[]>} list of organizations
 */
export async function searchOrganizations(name: string): Promise<Organization[]> {
  const init = {
    body: { name },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(
    "IgwSearchApi",
    "/organizations/search",
    init,
  )
  .then((response: Organization[]) => {
    return response;
  });
}

/**
 * Create or update profile device
 *
 * @param {CreateProfileDeviceInput} input create profile device input
 * @returns {Promise<ProfileDevice>} profile device
 */
export async function upsertProfileDevice(input: CreateProfileDeviceInput): Promise<ProfileDevice> {
  const init = {
    body: input,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(
    "IgwREST",
    "/profileDevice",
    init,
  )
  .then((response: ProfileDevice) => {
    return response;
  });
}

/**
 * Get profile device stats response
 *
 * @param {CreateProfileDeviceInput} input create profile device input
 * @returns {Promise<IProfileDeviceStatsResponse>} ProfileDeviceStatsResponse
 */
export async function getProfileDevicesStats(): Promise<IProfileDeviceStatsResponse> {
  const init = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.get(
    "IgwREST",
    "/profileDevice/stats",
    init,
  )
  .then((response: IProfileDeviceStatsResponse) => {
    return response;
  });
}

/**
 * Create feedback ASAP
 *
 * @param {{ meetingInviteId: string, questionResponseId?: string }} input meeting invite ID
 * @returns {Promise<ISqsSendMessageResponse>} server date response
 */
export async function createFeedbackAsap(input: { meetingInviteId: string, questionResponseId?: string }): Promise<ISqsSendMessageResponse> {
  const init = {
    body: input,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(
    "IgwREST",
    "/feedback/asap",
    init,
  )
  .then((response: ISqsSendMessageResponse) => {
    return response;
  });
}

/**
 * Get feedback by meeting ID
 *
 * @param {string} meetingId the meeting ID
 * @param {string} profileId the profile ID
 * @returns {Promise<ISellerFeedbackResponse[]>} feedback responses
 */
export async function getMeetingFeedback(
  meetingId: string,
  profileId?: string
): Promise<ISellerFeedbackResponse[]> {
  const init = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    ...(profileId && { queryStringParameters: { profileId } }),
  };
  return API.get(
    "IgwREST",
    `/feedback/${meetingId}`,
    init,
  )
  .then((responses: ISellerFeedbackResponse[]) => {
    return responses;
  });
}

/**
 *Queue transcription admin job
 *
 * @param {StartTranscriptionAdminJob} input create a transcription admin job
 * @returns {Promise<ISqsSendMessageResponse>} transcription export response
 */
export async function startTranscriptionAdminJob(input: StartTranscriptionAdminJob): Promise<ISqsSendMessageResponse> {
  const init = {
    body: input,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(
    "IgwREST",
    "/transcriptions/admin",
    init,
  )
  .then((response: ISqsSendMessageResponse) => {
    return response;
  });
}

/**
 * Creates an auditlog entry
 *
 * @param {CreateMeetingRoomEventAuditLogInput} input create auditlog input
 * @returns {Promise<MeetingRoomEventAuditLog>} auditlog entry creation response
 */
 export async function createAuditLog(
  input: CreateMeetingRoomEventAuditLogInput,
  level = "INFO",
): Promise<MeetingRoomEventAuditLog> {
  const body = {
    ...input,
    ...{
      level,
    },
  };
  const init = {
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(
    "IgwREST",
    "/auditlog",
    init,
  )
  .then((response: MeetingRoomEventAuditLog) => {
    return response;
  });
}

/**
 * Creates a provider meeting
 *
 * @param {CreateReadinessMeetingRestApiInput} input create meeting input
 * @returns {Promise<CreateReadinessMeetingRestApiResponse>} meeting creation response
 */
 export async function createMeeting(input: CreateReadinessMeetingRestApiInput): Promise<CreateReadinessMeetingRestApiResponse> { // TODO deprecate
  const init = {
    body: input,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(
    "IgwREST",
    "/meeting/Daily",
    init,
  )
  .then((response: CreateReadinessMeetingRestApiResponse) => {
    return response;
  });
}

/**
 * Creates a question response
 *
 * @param {CreateQuestionResponseRestApiInput} input create question response input
 * @returns {Promise<QuestionResponseRestApiResponse>} question response
 */
export async function createQuestionResponse(input: CreateQuestionResponseRestApiInput): Promise<QuestionResponseRestApiResponse> {
  const init = {
    body: input,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(
    "IgwREST",
    "/questionResponse",
    init,
  )
  .then((response: QuestionResponseRestApiResponse) => {
    return response;
  });
}

/**
 * Update a question response
 *
 * @param {UpdateQuestionResponseRestApiInput} input update question response input
 * @returns {Promise<QuestionResponseRestApiResponse>} question response
 */
export async function updateQuestionResponse(
  input: UpdateQuestionResponseRestApiInput,
  questionResponseId: string,
): Promise<QuestionResponseRestApiResponse> {
  const init = {
    body: input,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.put(
    "IgwREST",
    `/questionResponse/${questionResponseId}`,
    init,
  )
  .then((response: QuestionResponseRestApiResponse) => {
    return response;
  });
}

/**
 * Get a question response
 *
 * @param {questionResponseId} string question response input
 * @returns {Promise<QuestionResponseRestApiResponse>} question response
 */
export async function getQuestionResponse(questionResponseId: string): Promise<QuestionResponseRestApiResponse> {
  const init = {
    headers: {
      // "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.get(
    "IgwREST",
    `/questionResponse/${questionResponseId}`,
    init,
  )
  .then((response: QuestionResponseRestApiResponse) => {
    return response;
  });
}

/**
 * End a meeting session
 *
 * @param {string} meetingInviteId meeting invite ID
 * @returns {Promise<MeetingInvite>} MeetingInvite
 */
 export async function endMeeting(meetingInviteId: string): Promise<MeetingInvite> {
  const init = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.put(
    "IgwREST",
    `/meetinginvite/${meetingInviteId}`,
    init,
  )
  .then((response: MeetingInvite) => {
    return response;
  });
}


/**
 * Get meeting token
 *
 * @param {string} meetingInviteId meeting invite ID
 * @returns {Promise<{ meetingInviteId: string, token: string }>} the token response
 */
export async function getMeetingToken(meetingInviteId: string): Promise<{ meetingInviteId: string, token: string }> {
  const init = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(
    "IgwREST",
    `/meetinginvite/${meetingInviteId}`,
    init,
  )
  .then((response: { meetingInviteId: string, token: string }) => {
    return response as { meetingInviteId: string, token: string };
  });
}

/**
 * Starts a Daily provider meeting
 *
 * @param {number} endTimestamp the end timestamp ins seconds
 * @param {string} profileId the profile ID
 * @returns {Promise<{ roomName: string, url: string, invitees?: { name:? string, email: string }[] }>} meeting provider data
 */
 export async function startMeeting(
  endTimestamp: number,
  profileId: string,
  invitees?: {
    name?: string,
    email: string,
  }[],
): Promise<{ roomName: string, url: string }> {
  const init = {
    body: {
      endTimestamp,
      profileId,
      invitees,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.post(
    "IgwREST",
    "/meeting",
    init,
  )
  .then((response: { roomName: string, url: string }) => {
    return response;
  });
}

/**
 * Stop a Daily provider meeting
 *
 * @param {string} roomName the name of the room
 * @returns {Promise<{ roomName: string }>} meeting provider data
 */
 export async function stopMeeting(roomName: string): Promise<{ roomName: string }> {
  const init = {
    body: {
      roomName,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.del(
    "IgwREST",
    "/meeting",
    init,
  )
  .then((response: { roomName: string }) => {
    return response;
  });
}

/**
 * Add meeting ID to meeting verify queue
 *
 * @param {string} meetingId the meeting ID
 * @param {string} profileId the profile ID
 * @returns {Promise<{ meetingId: string }>} meeting queue results
 */
export async function verifyMeeting(
  meetingId: string,
  profileId: string,
): Promise<{ meetingId: string, id?: string }> {
  const init = {
    body: {
      meetingId,
      profileId,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.put(
    "IgwREST",
    "/meeting/validate",
    init,
  )
  .then((response: { meetingId: string, id?: string }) => {
    return response;
  });
}

/**
 * Creates and starts a readiness check meeting session
 *
 * @param {string} profileId profile ID
 * @param {number} durationInMinutes duration in minutes
 * @returns {Promise<MeetingInvite>} MeetingInvite
 */
 export async function startReadinessMeeting(profileId: string, durationInMinutes: number = 5): Promise<MeetingInvite> {
  const init = {
    body: {
      profileId,
      durationInMinutes,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.put(
    "IgwREST",
    "/meetinginvite",
    init,
  )
  .then((response: MeetingInvite) => {
    return response;
  });
}

/**
 * Update a meeting break
 *
 * @param {UpdateMeetingBreakRestInput} input update meeting break REST input
 * @returns {Promise<MeetingInvite>} question response
 */
export async function updateMeetingBreak(
  input: UpdateMeetingBreakRestInput,
  meetingInviteId: string,
): Promise<MeetingInvite> {
  const init = {
    body: input,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.put(
    "IgwREST",
    `/meetingBreak/${meetingInviteId}`,
    init,
  )
  .then((response: MeetingInvite) => {
    return response;
  });
}

/**
 * Calls a REST API that returns the application version
 *
 * @returns {Promise<SettingsVersionResponse>} server settings version response
 */
export async function getSettingsVersion(): Promise<SettingsVersionResponse> {
  const init = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.get(
    "IgwREST",
    "/settings/currentVersion",
    init,
  )
  .then((response: SettingsVersionResponse) => {
    return response;
  });
}

/**
 * Get meeting room
 *
 * @param {string} id meeting room ID
 * @returns {Promise<MeetingRoom | undefined>} MeetingRoom
 */
export async function getMeetingRoom(id: string): Promise<MeetingRoom | undefined> { // TODO deprecate
  const query = graphqlOperation(getMeetingRoomQuery, { id });
  const results = await (API.graphql(query) as Promise<
    GraphQLResult<GetMeetingRoomQuery>
  >);
  return results.data?.getMeetingRoom as MeetingRoom;
}

/**
 * Get meeting
 *
 * @param {string} id meeting ID
 * @returns {Promise<Meeting | undefined>} Meeting
 */
export async function getMeeting(id: string): Promise<Meeting | undefined> { // TODO deprecate
  const query = graphqlOperation(getMeetingQuery, { id });
  return (API.graphql(query) as Promise<GraphQLResult<GetMeetingQuery>>).then(
    (results: GraphQLResult<GetMeetingQuery>) =>
      results.data?.getMeeting as Meeting
  );
}

/**
 * Get meeting invite
 *
 * @param {string} id meeting invite ID
 * @returns {Promise<MeetingInvite | undefined>} Meeting
 */
 export async function getMeetingInvite(id: string): Promise<MeetingInvite | undefined> {
  const query = graphqlOperation(getMeetingInviteQuery, { id });
  return (API.graphql(query) as Promise<GraphQLResult<GetMeetingInviteQuery>>).then(
    (results: GraphQLResult<GetMeetingInviteQuery>) =>
      results.data?.getMeetingInvite as MeetingInvite
  );
}

/**
 * Get profile by Cognito ID
 *
 * @param {string} userId Cognito ID
 * @returns {Promise<(Profile | null)>} profile
 */
export const getProfile = async (userId: string): Promise<(Profile | null)> => { // TODO deprecate
  const results = await API.graphql({
    query: listProfilesByUserId,
    variables: {
      userId,
    },
  });
  const listProfilesByUserIdData =
    results && results.hasOwnProperty("data")
      ? results.data.listProfilesByUserId
      : null;
  const { items } = listProfilesByUserIdData;
  if (items && items.length === 0) {
    return null;
  }
  return items[0];
}


/**
 * Get public profile by profile ID
 *
 * @param {string} id Profile ID
 * @returns {Promise<(PublicProfile)>} profile
 */
 export async function getPublicProfile(id: string): Promise<PublicProfile> {
  const init = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return API.get(
    "IgwREST",
    `/profile/${id}`,
    init,
  )
  .then((response: PublicProfile) => {
    return response;
  });
}

/**
 * Get meetings HTML by Team
 *
 * @param {string} teamId team ID
 * @returns {Promise<string>} string
 */
export async function getScheduledMeetingsByTeamHtml(
  teamId: string,
  timeZone = 'America/New_York',
): Promise<string> {
  const init = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    ...(timeZone && { queryStringParameters: { timeZone } }),
  };
  return API.get(
    "IgwREST",
    `/team/${teamId}`,
    init,
  )
  .then((response: string) => {
    return response;
  });
}

/**
 * Get count from meetinginvite
 *
 * @param {MeetingStatus} status status
 * @param {string} startDateTime start date time
 * @param {string} endDateTime end date time
 * @param {string} profileId profile ID
 * @returns {Promise<{ count: number }>} string
 */
export async function getMeetingInvitesCount(
  status: MeetingStatus,
  startDateTime: string,
  endDateTime: string,
  profileId: string,
  notEqual?: boolean,
): Promise<{ count: number }> {
  const init = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    queryStringParameters: {
      status,
      startDateTime,
      endDateTime,
      profileId,
      ...(notEqual && { notEqual })
    },
  };
  return API.get(
    "IgwREST",
    `/meetinginvites/count`,
    init,
  )
  .then((response: { count: number }) => {
    return response;
  });
}

/**
 * Get profile by email
 *
 * @param {string} email string
 * @returns {Promise<(Profile | null)>} profile
 */
export const getProfileByEmail = async (email: string): Promise<(Profile | null)> => {
  const results = await API.graphql({
    query: listProfilesByEmail,
    variables: {
      email,
    },
  });
  const listProfilesData =
    results && results.hasOwnProperty("data")
      ? results.data.listProfilesByEmail
      : null;
  const { items } = listProfilesData;
  if (items && items.length === 0) {
    return null;
  }
  return items[0];
}

export function meetingInviteChangeDetected(
  currentMeetings: MeetingInvite[],
  updatedMeetings: MeetingInvite[],
): boolean {
  return true;
  /* if (currentMeetings.length !== updatedMeetings.length) {
    logger.info("changeDetected length mismatch");
    console.log("changeDetected length mismatch");
    return true;
  }
  let changeDetected = false;
  for (let i = 0; i < currentMeetings.length; i=i+1) {
    const currentMeeting = currentMeetings[i];
    const updatedMeeting = updatedMeetings[i];
    if (
      currentMeeting.id !== updatedMeeting.id ||
      currentMeeting.title !== updatedMeeting.title ||
      currentMeeting.status !== updatedMeeting.status ||
      currentMeeting.startDateTime !== updatedMeeting.startDateTime ||
      currentMeeting.endDateTime !== updatedMeeting.endDateTime ||
      currentMeeting.productId !== updatedMeeting.productId
    ) {
      changeDetected = true;
      logger.info("changeDetected = yes");
      break;
    }
  }
  logger.info("changeDetected", changeDetected);
  return changeDetected; */
}


/**
 * Get active MeetingInvites
 *
 * @param {MeetingInvite[]} items listing meeting invites
 * @param {number} serverTimeDifference server time difference
 * @param {boolean} applyServerOffset apply server offset
 * @returns {MeetingInvite[]} list of meeting invite
 */
export function getActiveMeetingInvites(
  items: MeetingInvite[],
  serverTimeDifference: number,
  applyServerOffset=false,
): MeetingInvite[] {
  const now = getCurrentDateTime(serverTimeDifference, applyServerOffset);
  const endDateTimestamp = now.toUnixInteger();
  logger.info("getActiveMeetingInvites endDateTimestamp no mod", now.toUnixInteger());
  logger.info("getActiveMeetingInvites endDateTimestamp with mod", endDateTimestamp);
  return items.filter(
    (i) => (
      i.status === MeetingStatus.SCHEDULED ||
      i.status === MeetingStatus.STARTED ||
      i.status === MeetingStatus.READY ||
      i.status === MeetingStatus.PENDING
    ) &&
    awsDateTimeStringToDateTime(
      i.endDateTime,
    ).toUnixInteger() > endDateTimestamp
  );
}

/**
 * Returns ended MeetingInvites
 *
 * @param {MeetingInvite[]} items listing meeting invites
 * @param {number} serverTimeDifference server time difference
 * @param {boolean} applyServerOffset apply server offset
 * @returns {MeetingInvite[]} list of meeting invites
 */
export function getEndedMeetingInvites(
  items: MeetingInvite[],
  serverTimeDifference: number,
  applyServerOffset=false,
): MeetingInvite[] {
  const now = getCurrentDateTime(serverTimeDifference, applyServerOffset);
  const endDateTimestamp = now.toUnixInteger();
  logger.info("getEndedMeetingInvites endDateTimestamp no mod", now.toUnixInteger());
  logger.info("getEndedMeetingInvites endDateTimestamp with mod", endDateTimestamp);
  return items.filter(
    (i) => /* i.profile && */ (
      i.status === MeetingStatus.ENDED
    ) &&
    awsDateTimeStringToDateTime(
      i.endDateTime,
    ).toUnixInteger() <= endDateTimestamp
    // && i.profile.id === userProfileId
  );
}


/**
 * Sorted profiles
 *
 * @param {Profile[]} profiles list of profiles
 * @returns {Profile[]} list of profiles
 */
export function sortProfiles(profiles: Profile[]): Profile[] {
  return profiles.sort(
    (a, b) => {
      const fullNameA = a.fullName.trim().toLowerCase();
      const fullNameB = b.fullName.trim().toLowerCase();
      if (fullNameA < fullNameB) {
        return -1;
      }
      if (fullNameA > fullNameB) {
        return 1;
      }
      return 0;
    }
  );
}

/**
 * Returns Profile query results
 *
 * @param {string | null} nextToken string
 * @param {number} limit number
 * @returns {Promise<ListProfilesQuery["listProfiles"]>} profile query results
 */
export const getProfileQueryResults = async (nextToken: string | null, limit: number = 50): Promise<ListProfilesQuery["listProfiles"] | null> => {
  const query = graphqlOperation(
    listProfiles,
    {
      limit,
      nextToken,
    }
  );
  return API.graphql(query).then(
    (results: { data: ListProfilesQuery }) => {
      return results && results.hasOwnProperty("data")
      ? results.data.listProfiles as ListProfilesQuery["listProfiles"]
      : null;
    }
  );
};

/**
 * Returns all profiles
 *
 * @param {number} limitPerRequest number
 * @returns {Promise<Profile[]>} list of profiles
 */
export const getAllProfiles = async (limitPerRequest: number = 50): Promise<Profile[]> => {
  return getProfileQueryResults(null, limitPerRequest).then(
    async (queryResults) => {
      // logger.info("1 queryResults", queryResults);
      if (!queryResults) {
        return [];
      }
      let nextToken = queryResults.nextToken ? queryResults.nextToken as string : null;
      let profiles = queryResults.items ? queryResults.items as Profile[] : [];
      // logger.info("nextToken", nextToken);
      // logger.info("profiles.length", profiles.length);
      if (!nextToken || profiles.length === 0) {
        // logger.info("return the first results");
        return profiles;
      }
      while (nextToken) {
        nextToken = await getProfileQueryResults(
          nextToken,
          limitPerRequest,
        ).then(
          (nextQueryResults) => {
            if (!nextQueryResults) {
              // logger.info("nextQueryResults is null");
              return null;
            }
            const nextProfiles = nextQueryResults.items ? nextQueryResults.items as Profile[] : [];
            // logger.info("nextProfiles.length", nextProfiles.length);
            if (nextProfiles.length === 0) {
              // logger.info("nextProfiles.length === 0");
              // logger.info("returning null token");
              return null;
            }
            // logger.info("nextQueryResults.nextToken", nextQueryResults.nextToken);
            profiles = profiles.concat(nextProfiles);
            if (nextQueryResults.nextToken === nextToken) {
              // logger.warn("nextQueryResults.nextToken === nextToken");
              return null;
            }
            return (nextQueryResults.nextToken && nextProfiles.length === limitPerRequest) ? nextQueryResults.nextToken : null;
          }
        );
      }
      return sortProfiles(profiles);
    }
  );
};

/**
 * Returns true if the meeting has a conflict
 *
 * @param {Profile} profile profile
 * @param {Meeting} meeting meeting
 * @returns {Promise<boolean>} true or false
 */
export async function hasConflictingMeeting(
  profile: Profile,
  meeting: Meeting,
): Promise<boolean> {
  return getMeetingInvitesByProfileId(
    profile.id,
    null,
    [
      meeting.startDateTime,
      meeting.endDateTime,
    ]
  ).then(
    (meetingInvites) => {
      if (meetingInvites.length > 0) {
        return true;
      }
      return getMeetingInvitesByProfileId(
        profile.id,
        {
          endDateTime: {
            between: [
              meeting.startDateTime,
              meeting.endDateTime,
            ]
          }
        },
        []
      ).then(
        (meetingInvites) => {
          logger.info('meetingInvites by end date', meetingInvites);
          return meetingInvites.length > 0;
        }
      );
    }
  );
}

/**
 * Get upcoming scheduled meetings based on dates
 *
 * @param {string} startDateTime start date time as a string
 * @param {string} endDateTime end date time as a string
 * @param {number} limit limit as an integer
 * @returns {Promise<ListMeetingsByStatusQuery["listMeetingsByStatus"]>} Meetings
 */
 export async function getScheduledMeetingsByDate(
  startDateTime: string,
  endDateTime: string,
  limit: number = 100,
): Promise<Meeting[]> {
  return (
    API.graphql(
      graphqlOperation(listMeetingsByStatus, {
        status: MeetingStatus.SCHEDULED,
        startDateTime: { between: [startDateTime, endDateTime] },
        sortDirection: ModelSortDirection.ASC,
        ...(limit && { limit }),
      }),
    ) as Promise<GraphQLResult>
  ).then((queryResult) => {
    if (queryResult.errors || !queryResult.data) {
      logger.error("queryResult", queryResult);
      throw new Error("Data provider error");
    }
    const queryResults = (queryResult.data as ListMeetingsByStatusQuery).listMeetingsByStatus;
    return queryResults?.items ? queryResults.items as Meeting[] : [];
  }).catch(
    (err: GraphQLErrorResponse) => {
      const { errors } = err;
      if (errors && errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        logger.error(errorMessage);
        throw new Error(errorMessage);
      }
      logger.error(err);
      throw err;
    }
  );
};

/**
 * Get upcoming scheduled meetings based on end dates
 *
 * @param {string} startDateTime start date time as a string
 * @param {string} endDateTime end date time as a string
 * @param {number} limit limit as an integer
 * @returns {Promise<ListMeetingsByStatusQuery["listMeetingsByStatus"]>} Meetings
 */
export async function getScheduledMeetingsByEndDate(
  startDateTime: string,
  endDateTime: string,
  limit: number = 100,
): Promise<Meeting[]> {
  return (
    API.graphql(
      graphqlOperation(listMeetingsByStatus, {
        status: MeetingStatus.SCHEDULED,
        filter: {
          endDateTime: { between: [startDateTime, endDateTime] },
        },
        // sortDirection: ModelSortDirection.ASC,
        ...(limit && { limit }),
      }),
    ) as Promise<GraphQLResult>
  ).then((queryResult) => {
    if (queryResult.errors || !queryResult.data) {
      logger.error("queryResult", queryResult);
      throw new Error("Data provider error");
    }
    const queryResults = (queryResult.data as ListMeetingsByStatusQuery).listMeetingsByStatus;
    return queryResults?.items ? queryResults.items as Meeting[] : [];
  }).catch(
    (err: GraphQLErrorResponse) => {
      const { errors } = err;
      if (errors && errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        logger.error(errorMessage);
        throw new Error(errorMessage);
      }
      logger.error(err);
      throw err;
    }
  );
};

/**
 * Get TeamMeetingRelationships by meeting
 *
 * @param {string} meetingId meeting ID
 * @returns {Promise<TeamMeetingRelationship[]>} TeamMeetingRelationships
 */
export async function getTeamMeetingRelationshipsByMeetingId(
  meetingId: string,
): Promise<TeamMeetingRelationship[]> {
  return (
    API.graphql(
      graphqlOperation(listTeamMeetingRelationshipsByMeetingId, {
        meetingId,
      }),
    ) as Promise<GraphQLResult>
  ).then((queryResult) => {
    if (queryResult.errors || !queryResult.data) {
      logger.error("queryResult", queryResult);
      throw new Error("Data provider error");
    }
    const queryResults = (queryResult.data as ListTeamMeetingRelationshipsByMeetingIdQuery).listTeamMeetingRelationshipsByMeetingId;
    return queryResults?.items ? queryResults.items as TeamMeetingRelationship[] : [];
  }).catch(
    (err: GraphQLErrorResponse) => {
      const { errors } = err;
      if (errors && errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        logger.error(errorMessage);
        throw new Error(errorMessage);
      }
      logger.error(err);
      throw err;
    }
  );
};

/**
 * Get conflicting TeamMeetingRelationships by team
 *
 * @param {string} teamId team ID
 * @param {Meeting} meeting meeting to exclude
 * @returns {Promise<TeamMeetingRelationship[]>} list of team meeting relationships
 */
export async function getConflictingMeetingsByTeam(
  teamId: string,
  meeting: Meeting,
): Promise<TeamMeetingRelationship[]> {
  return getScheduledMeetingsByDate(
    meeting.startDateTime,
    meeting.endDateTime,
  ).then(
    async (scheduledMeetings) => {
      logger.info("scheduledMeetings.length", scheduledMeetings.length);
      const updatedScheduledMeetings = scheduledMeetings.length > 0 ? scheduledMeetings.filter((m) => m.id !== meeting.id) : [];
      logger.info("updatedScheduledMeetings.length", updatedScheduledMeetings.length);
      for (let i = 0; i < updatedScheduledMeetings.length; i = i + 1) {
        const scheduledMeeting = updatedScheduledMeetings[i];
        const teamMeetingRelationships = (await getTeamMeetingRelationshipsByMeetingId(scheduledMeeting.id)).filter((t) => t.teamId === teamId);
        logger.info("teamMeetingRelationships.length", teamMeetingRelationships.length);
        if (teamMeetingRelationships.length > 0) {
          return teamMeetingRelationships;
        }
      }
      return getScheduledMeetingsByEndDate(
        meeting.startDateTime,
        meeting.endDateTime,
      ).then(
        async (scheduledMeetingsByEndDate) => {
          logger.info("scheduledMeetingsByEndDate.length", scheduledMeetingsByEndDate.length);
          const updatedScheduledMeetings2 = scheduledMeetingsByEndDate.length > 0 ? scheduledMeetingsByEndDate.filter((m) => m.id !== meeting.id) : [];
          logger.info("updatedScheduledMeetings2.length", updatedScheduledMeetings2.length);
          if (updatedScheduledMeetings2.length === 0) {
            return [];
          }
          for (let i2 = 0; i2 < updatedScheduledMeetings2.length; i2 = i2 + 1) {
            const scheduledMeeting2 = updatedScheduledMeetings2[i2];
            const teamMeetingRelationships2 = (await getTeamMeetingRelationshipsByMeetingId(scheduledMeeting2.id)).filter((t) => t.teamId === teamId);
            logger.info("teamMeetingRelationships2.length", teamMeetingRelationships2.length);
            if (teamMeetingRelationships2.length > 0) {
              return teamMeetingRelationships2;
            }
          }
          return [];
        }
      ).catch(
        (err) => {
          const { errors } = err;
          if (errors && errors.length > 0) {
            const { message } = err.errors[0];
            const errorMessage = message || "Unknown error";
            logger.error(errorMessage);
            throw new Error(errorMessage);
          }
          logger.error(err);
          throw err;
        }
      );
    }
  ).catch(
    (err) => {
      const { errors } = err;
      if (errors && errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        logger.error(errorMessage);
        throw new Error(errorMessage);
      }
      logger.error(err);
      throw err;
    }
  );
}

/**
 * Returns true if the meeting has a conflict
 *
 * @param {string} teamId team ID
 * @param {Meeting} meeting meeting
 * @returns {Promise<boolean>} true or false
 */
 export async function hasConflictingMeetingByTeam(
  teamId: string,
  meeting: Meeting,
): Promise<boolean> {
  return getConflictingMeetingsByTeam(
    teamId,
    meeting,
  ).then(
    (teamMeetingRelationships) => teamMeetingRelationships.length > 0
  ).catch(
    (err) => {
      logger.error(err);
      return false;
    }
  );
}

/**
 * asyncProfileFilter
 *
 * @param {Profile[]} profiles list of profile
 * @param {(value: Profile, index: number, array: Profile[]) => Promise<boolean>} predicate predicate
 * @returns {Promise<Profile[]>} list of profiles
 */
async function asyncProfileFilter(
  profiles: Profile[],
  predicate: (value: Profile, index: number, array: Profile[]) => Promise<boolean>
): Promise<Profile[]> {
	const results = await Promise.all(profiles.map(predicate));
	return profiles.filter((_v, index) => results[index]);
}

/**
 * Get all profiles without meeting conflicts
 *
 * @param {Meeting} meeting meeting
 * @param {number} limitPerRequest limit per request
 * @returns {Promise<Profile[]>} list of profiles
 */
export async function getAllProfilesWithoutMeetingConflicts(
  meeting: Meeting,
  limitPerRequest: number = 50,
): Promise<Profile[]> {
  return getAllProfiles(limitPerRequest).then(
    (profiles) => {
      return asyncProfileFilter(
        profiles,
        async (value, index, array) => {
          return hasConflictingMeeting(value, meeting).then(
            (result) => {
              // logger.info("hasConflictingMeeting result", result);
              if (result) {
                // logger.info("hasConflictingMeeting profile", value);
              }
              return !result;
            }
          )
        }
      );
    }
  );
}

/**
 * Get TeamMemberRelationships by team ID query
 *
 * @param {string} teamId team ID
 * @param {ModelTeamMemberRelationshipFilterInput} filter filter
 * @returns {Promise<GraphQLResult<ListTeamMemberRelationshipsByTeamIdQuery>>} the GraphQLResult
 */
const getTeamMemberRelationshipsByTeamIdQuery = async (
  teamId: string,
  filter?: ModelTeamMemberRelationshipFilterInput,
): Promise<
  GraphQLResult<ListTeamMemberRelationshipsByTeamIdQuery>
  > => {
  const query = graphqlOperation(listTeamMemberRelationshipsByTeamId, { teamId, filter, });
  return API.graphql(query) as Promise<
    GraphQLResult<ListTeamMemberRelationshipsByTeamIdQuery>
  >;
};

/**
 * Get TeamMembers by team ID
 *
 * @param {string} teamId team ID
 * @param {ModelTeamMemberRelationshipFilterInput} filter filter
 * @returns {Promise<(Profile | null | undefined)[]>} list of profiles
 */
const getTeamMembersByTeamId = async (
  teamId: string,
  filter?: ModelTeamMemberRelationshipFilterInput,
): Promise<(Profile | null | undefined)[]> => {
  return getTeamMemberRelationshipsByTeamIdQuery(
    teamId,
    filter,
  ).then(
    (query) => {
      const {
        data
      } = query;
      if (data) {
        const {
          listTeamMemberRelationshipsByTeamId
        } = data;
        if (listTeamMemberRelationshipsByTeamId) {
          const { items } = listTeamMemberRelationshipsByTeamId;
          if (items) {
            return items.map((i: any) => i?.member);
          }
        }
      }
      // data is empty
      return [];
    }
  );
};

/**
 * Get forward emails by sellerProfileId and profileId
 *
 * @param {string} sellerProfileId seller profile ID
 * @param {string} profileId profile ID
 * @param {number} limit limit
 * @returns {Promise<ForwardEmail[]>} forward emails
 */
export const getForwardEmailsBySellerProfileIdAndProfileId = async (
  sellerProfileId: string,
  profileId: string,
  limit: number = 1,
): Promise<ForwardEmail[]> => {
  const query = graphqlOperation(
    listForwardEmailsBySellerProfileId,
    {
      sellerProfileId,
      profileId: {
        eq: profileId
      },
      ...(limit && { limit }),
    }
  );
  return API.graphql(query).then(
    (queryResult: GraphQLResult<ListForwardEmailsBySellerProfileIdQuery>) => {
      if (queryResult.errors || !queryResult.data) {
        throw new Error("Data provider error");
      }
      const queryResultData = (queryResult.data as ListForwardEmailsBySellerProfileIdQuery).listForwardEmailsBySellerProfileId;
      if (!queryResultData) {
        return [];
      }
      const {
        items
      } = queryResultData as ModelForwardEmailConnection;
      if (!items) {
        return [];
      }
      return items;
    }
  );
};

/**
 * Get profiles by meeting ID and buyer or seller query
 *
 * @param {string} meetingId meeting ID
 * @param {string | TeamMeetingRelationshipType} buyerOrSeller buyer or seller
 * @param {number} limit limit
 * @returns {Promise<GraphQLResult<ListTeamMeetingRelationshipsByMeetingIdAndRelationshipTypeQuery>>} the GraphQLResult
 */
const getProfilesByMeetingIdAndBuyerOrSellerQuery = async (
  meetingId: string,
  buyerOrSeller: string | TeamMeetingRelationshipType,
  limit: number = 0,
): Promise<
  GraphQLResult<ListTeamMeetingRelationshipsByMeetingIdAndRelationshipTypeQuery>
  > => {
  let relationshipType = TeamMeetingRelationshipType.INVITEE;
  if (buyerOrSeller === TeamMeetingRelationshipType.INVITEE || buyerOrSeller === TeamMeetingRelationshipType.ORGANIZER) {
    relationshipType = buyerOrSeller;
  } else {
    relationshipType = buyerOrSeller.toLowerCase() === "seller" ? TeamMeetingRelationshipType.ORGANIZER : TeamMeetingRelationshipType.INVITEE;
  }
  const query = graphqlOperation(
    listTeamMeetingRelationshipsByMeetingIdAndRelationshipType,
    {
      meetingId,
      relationshipType: {
        eq: relationshipType
      },
      ...(limit && { limit }),
    }
  );
  return API.graphql(query) as Promise<
    GraphQLResult<ListTeamMeetingRelationshipsByMeetingIdAndRelationshipTypeQuery>
  >;
};

/**
 * Get profiles by meeting ID and buyer or seller
 *
 * @param {string} meetingId meeting ID
 * @param {string | TeamMeetingRelationshipType} buyerOrSeller buyer or seller
 * @param {number} limit limit
 * @returns {Promise<Profile[]>} list of Profiles
 */
export const getProfilesByMeetingIdAndBuyerOrSeller = async (
  meetingId: string,
  buyerOrSeller: string | TeamMeetingRelationshipType,
  limit: number = 0,
): Promise<Profile[]> => {
  let relationshipType = "";
  if (buyerOrSeller === TeamMeetingRelationshipType.INVITEE || buyerOrSeller === TeamMeetingRelationshipType.ORGANIZER) {
    relationshipType = buyerOrSeller;
  } else {
    relationshipType = buyerOrSeller.toLowerCase() === "seller" ? TeamMeetingRelationshipType.ORGANIZER : TeamMeetingRelationshipType.INVITEE;
  }
  return getProfilesByMeetingIdAndBuyerOrSellerQuery(
    meetingId,
    buyerOrSeller,
    limit,
  ).then(
    (queryResult) => {
      if (queryResult.errors || !queryResult.data) {
        throw new Error("Data provider error");
      }
      const queryResultData = (queryResult.data as ListTeamMeetingRelationshipsByMeetingIdAndRelationshipTypeQuery).listTeamMeetingRelationshipsByMeetingIdAndRelationshipType;
      if (!queryResultData) {
        return [];
      }
      const {
        items
      } = queryResultData as ModelTeamMeetingRelationshipConnection;
      if (!items) {
        return [];
      }
      const teamMeetingRelationship = items[0];
      return getTeamMembersByTeamId(
        teamMeetingRelationship?.teamId as string
      ).then(
        (profiles) => {
          return profiles ? profiles.filter((p) => p !== null && p !== undefined) as Profile[] : [];
        }
      );
    }
  )
  .catch(
    (err: GraphQLErrorResponse) => {
      const { errors } = err;
      if (errors && errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        logger.error(errorMessage);
        throw new Error(errorMessage);
      }
      logger.error(err);
      throw err;
    }
  );
};

/**
 * Get team by meeting ID and buyer or seller
 *
 * @param {string} meetingId meeting ID
 * @param {string | TeamMeetingRelationshipType} buyerOrSeller buyer or seller
 * @returns {Promise<Team | null>} team
 */
 export const getTeamByMeetingIdAndBuyerOrSeller = async (
  meetingId: string,
  buyerOrSeller: string | TeamMeetingRelationshipType,
): Promise<Team | null> => {
  let relationshipType = TeamMeetingRelationshipType.INVITEE;
  if (buyerOrSeller === TeamMeetingRelationshipType.INVITEE || buyerOrSeller === TeamMeetingRelationshipType.ORGANIZER) {
    relationshipType = buyerOrSeller;
  } else {
    relationshipType = buyerOrSeller.toLowerCase() === "seller" ? TeamMeetingRelationshipType.ORGANIZER : TeamMeetingRelationshipType.INVITEE;
  }
  return getProfilesByMeetingIdAndBuyerOrSellerQuery(
    meetingId,
    buyerOrSeller,
    1,
  ).then(
    (queryResult) => {
      if (queryResult.errors || !queryResult.data) {
        throw new Error("Data provider error");
      }
      const queryResultData = (queryResult.data as ListTeamMeetingRelationshipsByMeetingIdAndRelationshipTypeQuery).listTeamMeetingRelationshipsByMeetingIdAndRelationshipType;
      if (!queryResultData) {
        return null;
      }
      const {
        items
      } = queryResultData as ModelTeamMeetingRelationshipConnection;
      if (!items) {
        return null;
      }
      return (items[0]?.team && !items[0].team.privacyEnabled) ? items[0].team : null;
    }
  )
  .catch(
    (err: GraphQLErrorResponse) => {
      const { errors } = err;
      if (errors && errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        logger.error(errorMessage);
        throw new Error(errorMessage);
      }
      logger.error(err);
      throw err;
    }
  );
};

/**
 * Create or update a record in the "QuestionResponse" database table
 *
 * Use this function to store notes for sellers or buyers.
 * NOTE: a product must have at least one entry in the "ProductQuestion" table.
 *
 * @param {DataProvider} dataProvider dataProvider
 * @param {MeetingInvite} meetingInvite meeting invite
 * @param {string} notes notes
 */
export const createOrUpdateQuestionResponse = async (
  dataProvider: DataProvider,
  meetingInvite: MeetingInvite,
  notes: string = "",
  allowEmptyUpdate: boolean = true,
): Promise<null | QuestionResponse | QuestionResponseRestApiResponse> => {
  return dataProvider.getList(
    "productQuestionsByProductId",
    {
      pagination: { page: 1, perPage: 1 },
      sort: { field: "listProductQuestionsByProductId", order: "" }, // field is the name of the index
      filter: {
        listProductQuestionsByProductId: { // the name of the index
          productId: meetingInvite.productId,
        },
      },
    },
  ).then(
    (listProductQuestionsByProductIdResults) => {
      if (
        listProductQuestionsByProductIdResults.data &&
        listProductQuestionsByProductIdResults.data.length > 0
      ) {
        const questionId = listProductQuestionsByProductIdResults.data[0]?.id;
        return dataProvider.getList(
          "questionResponses",
          {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "listQuestionResponsesByMeetingInviteId", order: "" },
            filter: {
              listQuestionResponsesByMeetingInviteId: { // the name of the index
                meetingInviteId: meetingInvite.id,
                questionId: {
                  eq: questionId,
                }
              },
            },
          },
        ).then( // @ts-ignore
          (listOfQuestionResponses: GetListResult<QuestionResponse>) => {
            logger.info("listOfQuestionResponses.data", listOfQuestionResponses.data);
            if (!listOfQuestionResponses.data || listOfQuestionResponses.data.length === 0) {
              // NOTE: this creates a record in the QuestionResponse database table.
              // The "response" (notes) field is created on the meeting break page.
              const input = {
                response: notes,
                questionId: questionId.toString(),
                profileId: meetingInvite.profileId,
                meetingId: meetingInvite.meetingId,
                meetingInviteId: meetingInvite.id,
                owner: meetingInvite.owner,
              };
              logger.info("create QuestionResponse input", input);
              return createQuestionResponse(
                input,
              ).then(
                (questionResponseResults) => {
                  logger.info("create questionResponseResults", questionResponseResults);
                  return questionResponseResults;
                }
              ).catch(
                (err: any) => {
                  logger.error("failed to create questionResponse");
                  return null;
                }
              );
            } else if (listOfQuestionResponses.data.length > 0) {
              // NOTE: this updates a record in the QuestionResponse database table.
              // The "response" (notes) field is updated on the feedback page.
              const qResponse = listOfQuestionResponses.data[0] as QuestionResponse;
              const {
                id,
                response,
                _version,
              } = qResponse;
              if (!allowEmptyUpdate && (!notes || `${notes}`.length === 0)) {
                logger.info("skipping empty questionResponse update");
                return qResponse;
              }
              return updateQuestionResponse(
                {
                  response: notes,
                  // _version,
                },
                id,
              ).then(
                (questionResponseResults) => {
                  return questionResponseResults;
                }
              ).catch(
                (err) => {
                  logger.error("failed to create questionResponse");
                  return null;
                }
              );
            } else {
              logger.info("QuestionResponses reults empty");
              return null;
            }
          }
        ).catch(
          (err: Error) => {
            logger.error("error querying ProductQuestions");
            return null;
          }
        );
      }
      return null;
    }
  ).catch(
    (err) => {
      logger.error("failed to update productQuestionsByProductId");
      return null;
    }
  );
};

/**
 * Create or update a record in the "QuestionResponse" database table
 *
 * Use this function to store notes for sellers or buyers.
 * NOTE: a product must have at least one entry in the "ProductQuestion" table.
 *
 * @param {DataProvider} dataProvider dataProvider
 * @param {MeetingInvite} meetingInvite meeting invite
 * @param {string} notes notes
 */
export const createOrUpdateQuestionResponseOld = async (
  dataProvider: DataProvider,
  meetingInvite: MeetingInvite,
  notes: string = "",
  allowEmptyUpdate: boolean = true,
): Promise<null | QuestionResponse> => {
  return dataProvider.getList(
    "productQuestionsByProductId",
    {
      pagination: { page: 1, perPage: 1 },
      sort: { field: "listProductQuestionsByProductId", order: "" }, // field is the name of the index
      filter: {
        listProductQuestionsByProductId: { // the name of the index
          productId: meetingInvite.productId,
        },
      },
    },
  ).then(
    (listProductQuestionsByProductIdResults: any) => {
      if (
        listProductQuestionsByProductIdResults.data &&
        listProductQuestionsByProductIdResults.data.length > 0
      ) {
        const questionId = listProductQuestionsByProductIdResults.data[0]?.id;
        return dataProvider.getList(
          "questionResponses",
          {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "listQuestionResponsesByMeetingInviteId", order: "" },
            filter: {
              listQuestionResponsesByMeetingInviteId: { // the name of the index
                meetingInviteId: meetingInvite.id,
                questionId: {
                  eq: questionId,
                }
              },
            },
          },
        ).then(
          (listOfQuestionResponses: any) => {
            logger.info("listOfQuestionResponses.data", listOfQuestionResponses.data);
            if (!listOfQuestionResponses.data || listOfQuestionResponses.data.length === 0) {
              // NOTE: this creates a record in the QuestionResponse database table.
              // The "response" (notes) field is created on the meeting break page.
              const input = {
                response: notes,
                questionId,
                profileId: meetingInvite.profileId,
                meetingId: meetingInvite.meetingId,
                meetingInviteId: meetingInvite.id,
                owner: meetingInvite.owner,
              };
              logger.info("create QuestionResponse input", input);
              return dataProvider.create(
                "questionResponses",
                {
                  data: input,
                }
              ).then(
                (questionResponseResults: any) => {
                  logger.info("create questionResponseResults", questionResponseResults);
                  return questionResponseResults.data as QuestionResponse;
                }
              ).catch(
                (err: any) => {
                  logger.error("failed to create questionResponse");
                  return null;
                }
              );
            } else if (listOfQuestionResponses.data.length > 0) {
              // NOTE: this updates a record in the QuestionResponse database table.
              // The "response" (notes) field is updated on the feedback page.
              const qResponse = listOfQuestionResponses.data[0] as QuestionResponse;
              const {
                id,
                response,
                _version,
              } = qResponse;
              if (!allowEmptyUpdate && (!notes || `${notes}`.length === 0)) {
                logger.info("skipping empty questionResponse update");
                return qResponse;
              }
              return dataProvider.update(
                "questionResponses", {
                  id,
                  data: {
                    id,
                    response: notes,
                    _version,
                  },
                  previousData: {
                    id,
                    response,
                    _version,
                  },
                }
              ).then(
                (questionResponseResults: any) => {
                  return questionResponseResults.data as QuestionResponse;
                }
              ).catch(
                (err: any) => {
                  logger.error("failed to create questionResponse");
                  return null;
                }
              );
            } else {
              logger.info("QuestionResponses reults empty");
              return null;
            }
          }
        ).catch(
          (err: Error) => {
            logger.error("error querying ProductQuestions");
            return null;
          }
        );
      }
      return null;
    }
  ).catch(
    (err) => {
      logger.error("failed to update productQuestionsByProductId");
      return null;
    }
  );
};

/**
 * hashBrowser
 *
 * converts string to sha-256 hash
 *
 * @param {string} val
 * @returns {Promise<string>} hash
 */
export const hashBrowser = async(val: string): Promise<string> => {
  return crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(val)
  ).then(
    (h) => {
      const hexes = [];
      const view = new DataView(h);
      for (let i = 0; i < view.byteLength; i += 4) {
        hexes.push(('00000000' + view.getUint32(i).toString(16)).slice(-8));
      }
      return hexes.join('');
    }
  );
};


/**
 * Create or update a record in the "ProfileDevice" database table
 *
 * Use this function to store devices for a user.
 * NOTE: a profile must have at least one entry in the "ProfileDevice" table.
 *
 * @param {DataProvider} dataProvider dataProvider
 * @param {input} CreateProfileDeviceInput input to create or update device record
 * @param {Profile} profile profile
 * @param {string} deviceHash device hash
 * @returns {null | ProfileDevice} profile device
 */
export const createOrUpdateProfileDeviceResponse = async (
  dataProvider: DataProvider,
  input: CreateProfileDeviceInput,
  profile: Profile, // TODO change to profileId?
  uaParsed: UserAgentParserResult,
  // deviceHash: string,
): Promise<null | ProfileDevice> => {
  logger.info("uaParsed.device", uaParsed.device);
  logger.info("uaParsed", uaParsed);
  const deviceString = `${uaParsed.os.name}${uaParsed.engine.name}${uaParsed.browser.name}`;
  logger.info("deviceString", deviceString);
  const deviceHash = await hashBrowser(deviceString); // TODO skip if set
  logger.info("deviceHash", deviceHash);
  return dataProvider.getList(
    "profileDevicesByProfileId",
    {
      pagination: { page: 1, perPage: 1 },
      sort: { field: "listProfileDevicesByProfileId", order: "" }, // field is the name of the index
      filter: {
        listProfileDevicesByProfileId: { // the name of the index
          profileId: profile.id,
          deviceHash: {
            eq: deviceHash,
          },
        },
      },
    },
  ).then(
    (listProfileDevicesByProfileIdResults) => {
      logger.info("listProfileDevicesByProfileIdResults", listProfileDevicesByProfileIdResults);
      if (
        listProfileDevicesByProfileIdResults.data &&
        listProfileDevicesByProfileIdResults.data.length > 0
      ) {
        // const deviceId = listProfileDevicesByProfileIdResults.data[0]?.id;
        // NOTE: this updates a record in the ProfileDevice database table.
        const pDevice = listProfileDevicesByProfileIdResults.data[0] as ProfileDevice;
        logger.info("pDevice", pDevice);
        const {
          id,
          _version,
          hasWebcam: oldHasWebcam,
          hasMicrophone: oldHasMicrophone,
          isScreenCapturingSupported: oldIsScreenCapturingSupported,
          isWebRTCSupported: oldIsWebRTCSupported,
          isWebsiteHasMicrophonePermissions: oldIsWebsiteHasMicrophonePermissions,
          isWebsiteHasWebcamPermissions: oldIsWebsiteHasWebcamPermissions,
          isWebSocketsSupported: oldIsWebSocketsSupported,
          clockOffset: oldClockOffset,
          lastSeen: oldLastSeen,
          lastLogin: oldLastLogin,
          // lastMeetingId: oldlastMeetingId,
          // lastMeetingInviteId: oldlastMeetingInviteId,
          // lastMeetingJoin: oldLastMeetingJoin,
          // lastMeetingStatus: oldLastMeetingStatus,
          // meetingData: oldMeetingData,
          userAgent: oldUserAgent,
          userAgentSummary: oldUserAgentSummary,
          // lastMeetingRoomProvider: oldLastMeetingRoomProvider,
          // micStatus: oldMicStatus,
          // cameraStatus: oldCameraStatus,
          // networkQuality: oldNetworkQuality,
        } = pDevice;

        const {
          hasWebcam,
          hasMicrophone,
          isScreenCapturingSupported,
          isWebRTCSupported,
          isWebsiteHasMicrophonePermissions,
          isWebsiteHasWebcamPermissions,
          isWebSocketsSupported,
          clockOffset,
          lastSeen,
          lastLogin,
          // lastMeetingId: oldlastMeetingId,
          // lastMeetingInviteId: oldlastMeetingInviteId,
          // lastMeetingJoin: oldLastMeetingJoin,
          // lastMeetingStatus: oldLastMeetingStatus,
          // meetingData: oldMeetingData,
          // lastMeetingRoomProvider: oldLastMeetingRoomProvider,
          // micStatus: oldMicStatus,
          // cameraStatus: oldCameraStatus,
          // networkQuality: oldNetworkQuality,
        } = input;
        let {
          userAgent,
          userAgentSummary,
        } = input;
        if (!userAgent) {
          userAgent = JSON.stringify(uaParsed);
        }
        if (!userAgentSummary) {
          userAgentSummary = uaParsed.ua;
        }
        return dataProvider.update(
          "profileDevices", {
            id,
            data: {
              id,
              ...(hasWebcam !== undefined && hasWebcam !== oldHasWebcam && { hasWebcam  }),
              ...(hasMicrophone !== undefined && hasMicrophone !== oldHasMicrophone && { hasMicrophone  }),
              ...(isScreenCapturingSupported !== undefined && isScreenCapturingSupported !== oldIsScreenCapturingSupported && { isScreenCapturingSupported }),
              ...(isWebRTCSupported !== undefined && isWebRTCSupported !== oldIsWebRTCSupported && { isWebRTCSupported  }),
              ...(isWebsiteHasMicrophonePermissions !== undefined && isWebsiteHasMicrophonePermissions !== oldIsWebsiteHasMicrophonePermissions && { isWebsiteHasMicrophonePermissions }),
              ...(isWebsiteHasWebcamPermissions !== undefined && isWebsiteHasWebcamPermissions !== oldIsWebsiteHasWebcamPermissions && { isWebsiteHasWebcamPermissions }),
              ...(isWebSocketsSupported !== undefined && isWebSocketsSupported !== oldIsWebSocketsSupported && { isWebSocketsSupported }),
              ...(clockOffset !== undefined && clockOffset !== oldClockOffset && { clockOffset }),
              ...(lastSeen !== undefined && lastSeen !== oldLastSeen && { lastSeen }),
              ...(lastLogin !== undefined && lastLogin !== oldLastLogin && { lastLogin }),
              ...(userAgent !== undefined && userAgent !== oldUserAgent && { userAgent }),
              ...(userAgentSummary !== undefined && userAgentSummary !== oldUserAgentSummary && { userAgentSummary }),
              deviceHash,
              profileId: profile.id,
              _version,
            },
            previousData: {
              id,
              ...(hasWebcam !== undefined && hasWebcam !== oldHasWebcam && { hasWebcam: oldHasWebcam }),
              ...(hasMicrophone !== undefined && hasMicrophone !== oldHasMicrophone && { hasMicrophone: oldHasMicrophone  }),
              ...(isScreenCapturingSupported !== undefined && isScreenCapturingSupported !== oldIsScreenCapturingSupported && { isScreenCapturingSupported: oldIsScreenCapturingSupported }),
              ...(isWebRTCSupported !== undefined && isWebRTCSupported !== oldIsWebRTCSupported && { isWebRTCSupported: oldIsWebRTCSupported }),
              ...(isWebsiteHasMicrophonePermissions !== undefined && isWebsiteHasMicrophonePermissions !== oldIsWebsiteHasMicrophonePermissions && { isWebsiteHasMicrophonePermissions: oldIsWebsiteHasMicrophonePermissions }),
              ...(isWebsiteHasWebcamPermissions !== undefined && isWebsiteHasWebcamPermissions !== oldIsWebsiteHasWebcamPermissions && { isWebsiteHasWebcamPermissions: oldIsWebsiteHasMicrophonePermissions }),
              ...(isWebSocketsSupported !== undefined && isWebSocketsSupported !== oldIsWebSocketsSupported && { isWebSocketsSupported: oldIsWebSocketsSupported }),
              ...(clockOffset !== undefined && clockOffset !== oldClockOffset && { clockOffset: oldClockOffset }),
              ...(lastSeen !== undefined && lastSeen !== oldLastSeen && { lastSeen: oldLastSeen }),
              ...(lastLogin !== undefined && lastLogin !== oldLastLogin && { lastLogin: oldLastLogin }),
              ...(userAgent !== undefined && userAgent !== oldUserAgent && { userAgent: oldUserAgent }),
              ...(userAgentSummary !== undefined && userAgentSummary !== oldUserAgentSummary && { userAgentSummary: oldUserAgentSummary }),
              deviceHash,
              profileId: profile.id,
              _version,
            },
          }
        ).then(
          (profileDeviceResults) => {
            return profileDeviceResults.data as ProfileDevice;
          }
        ).catch(
          (err) => {
            logger.error("failed to create profileDevice", err);
            return null;
          }
        );
      } else {
        // NOTE: this creates a record in the ProfileDevice database table.
        const {
          hasWebcam,
          hasMicrophone,
          isScreenCapturingSupported,
          isWebRTCSupported,
          isWebsiteHasMicrophonePermissions,
          isWebsiteHasWebcamPermissions,
          isWebSocketsSupported,
          clockOffset,
          lastSeen,
          lastLogin,
        } = input;
        let {
          userAgent,
          userAgentSummary,
        } = input;
        if (!userAgent) {
          userAgent = JSON.stringify(uaParsed);
        }
        if (!userAgentSummary) {
          userAgentSummary = uaParsed.ua;
        }
        const createInput = {
          ...(hasWebcam !== undefined && { hasWebcam  }),
          ...(hasMicrophone !== undefined && { hasMicrophone  }),
          ...(isScreenCapturingSupported !== undefined && { isScreenCapturingSupported }),
          ...(isWebRTCSupported !== undefined && { isWebRTCSupported  }),
          ...(isWebsiteHasMicrophonePermissions !== undefined && { isWebsiteHasMicrophonePermissions }),
          ...(isWebsiteHasWebcamPermissions !== undefined && { isWebsiteHasWebcamPermissions }),
          ...(isWebSocketsSupported !== undefined && { isWebSocketsSupported }),
          ...(clockOffset !== undefined && { clockOffset }),
          ...(lastSeen !== undefined && { lastSeen }),
          ...(lastLogin !== undefined && { lastLogin }),
          ...(userAgent !== undefined && { userAgent }),
          ...(userAgentSummary !== undefined && { userAgentSummary }),
          profileId: profile.id,
          deviceHash,
          owner: profile.owner,
        };
        logger.info("create ProfileDevice createInput", createInput);
        // return null;
        return dataProvider.create(
          "profileDevices",
          {
            data: createInput,
          }
        ).then(
          (profileDeviceResults) => {
            logger.info("create profileDeviceResults", profileDeviceResults);
            return profileDeviceResults.data as ProfileDevice;
          }
        ).catch(
          (err) => {
            logger.error("failed to create profileDeviceResults", err);
            return null;
          }
        );
      }
    }
  );
};

/**
 * Query for MeetingInvites by profile and dates
 *
 * @param {string} profileId profile ID
 * @param {ModelMeetingInviteFilterInput | null} filter ModelMeetingInviteFilterInput or null
 * @param {string[]} betweenDateTimes string[]
 * @param {ModelSortDirection} sortDirection ModelSortDirection
 * @param {number} limit number
 * @returns {Promise<MeetingInvite[]>} list of meeting invites
 */
 export const getMeetingInvitesByProfileId = async (
  profileId: string,
  filter: ModelMeetingInviteFilterInput | null = null,
  betweenDateTimes: string[] = [],
  sortDirection: ModelSortDirection = ModelSortDirection.ASC,
  limit: number = 1000,
): Promise<MeetingInvite[]> => {
  const queryVariables = {
    profileId,
    sortDirection,
    ...((betweenDateTimes && betweenDateTimes.length === 2) && {
      startDateTime: {
        between: betweenDateTimes
      },
    }),
    ...(filter && { filter }),
    ...(limit && { limit }),
  };
  const query = graphqlOperation(listMeetingInvitesByProfileId, queryVariables);
  return (
    API.graphql(query) as Promise<GraphQLResult<ListMeetingInvitesByProfileIdQuery>>
  )
    .then(
      (results: GraphQLResult<ListMeetingInvitesByProfileIdQuery>) => {
        const listMeetingInvitesData =
        results && results.hasOwnProperty("data")
          ? results.data?.listMeetingInvitesByProfileId
          : null;
        if (listMeetingInvitesData) {
          const { items } = listMeetingInvitesData;
          if (items && items.length > 0) {
            return items as MeetingInvite[];
          }
        }
        return [];
      }
    ).catch((err: GraphQLErrorResponse) => {
      const { errors } = err;
      if (errors && errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        logger.error(errorMessage);
        throw new Error(errorMessage);
      }
      logger.error(err);
      throw err;
    }
  );
};

/**
 * Query for Notifications by read
 *
 * @param {ModelNotificationFilterInput | null} filter ModelNotificationFilterInput or null
 * @param {string[]} betweenDateTimes string[]
 * @param {ModelSortDirection} sortDirection ModelSortDirection
 * @param {number} limit number
 * @returns {Promise<Notification[]>} list of notifications
 */
export const getNotificationsByRead = async (
  read: number,
  filter: ModelNotificationFilterInput | null = null,
  betweenDateTimes: string[] = [],
  sortDirection: ModelSortDirection = ModelSortDirection.ASC,
  limit: number = 1000,
): Promise<Notification[]> => {
  const queryVariables = {
    read,
    sortDirection,
    ...((betweenDateTimes && betweenDateTimes.length === 2) && {
      startDateTime: {
        between: betweenDateTimes
      },
    }),
    ...(filter && { filter }),
    ...(limit && { limit }),
  };
  const query = graphqlOperation(listNotificationsByRead, queryVariables);
  return (
    API.graphql(query) as Promise<GraphQLResult<ListNotificationsByReadQuery>>
  )
    .then(
      (results: GraphQLResult<ListNotificationsByReadQuery>) => {
        const listNotificationsData =
        results && results.hasOwnProperty("data")
          ? results.data?.listNotificationsByRead
          : null;
        if (listNotificationsData) {
          const { items } = listNotificationsData;
          if (items && items.length > 0) {
            return items as Notification[];
          }
        }
        return [];
      }
    ).catch((err: GraphQLErrorResponse) => {
      const { errors } = err;
      if (errors && errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        logger.error(errorMessage);
        throw new Error(errorMessage);
      }
      logger.error(err);
      throw err;
    }
  );
};

/**
 * Creates a Profile
 *
 * @param {CreateProfileInput} input CreateProfileInput
 * @returns {Promise<Profile | undefined>} the profile
 */
export const createProfileMutation = async (
  input: CreateProfileInput
): Promise<Profile | undefined> => {
  const query = graphqlOperation(createProfile, { input });
  return (API.graphql(query) as Promise<GraphQLResult<CreateProfileMutation>>)
    .then((result: GraphQLResult<CreateProfileMutation>) => {
      return result.data?.createProfile as Profile;
    })
    .catch((err: GraphQLErrorResponse) => {
      const { errors } = err;
      if (errors && errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        throw new Error(errorMessage);
      }
      throw err;
    }
  );
};

/**
 * Updates a Profile
 *
 * @param {UpdateProfileInput} input UpdateProfileInput
 * @returns {Promise<Profile | undefined>} the profile
 */
export const updateProfileMutation = async (
  input: UpdateProfileInput
): Promise<Profile | undefined> => {
  const query = graphqlOperation(updateProfile, { input });
  return (API.graphql(query) as Promise<GraphQLResult<UpdateProfileMutation>>)
    .then((result: GraphQLResult<UpdateProfileMutation>) => {
      return result.data?.updateProfile as Profile;
    })
    .catch((err: GraphQLErrorResponse) => {
      const { errors } = err;
      if (errors && errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        throw new Error(errorMessage);
      }
      throw err;
    }
  );
};

/**
 * Updates a MeetingInvite
 *
 * @param {UpdateMeetingInviteInput} input UpdateMeetingInviteInput
 * @returns {Promise<MeetingInvite | undefined>} the meeting invite
 */
export const updateMeetingInviteMutation = async (
  input: UpdateMeetingInviteInput
): Promise<MeetingInvite | undefined> => {
  const query = graphqlOperation(updateMeetingInvite, { input });
  return (API.graphql(query) as Promise<GraphQLResult<UpdateMeetingInviteMutation>>)
    .then((result: GraphQLResult<UpdateMeetingInviteMutation>) => {
      return result.data?.updateMeetingInvite as MeetingInvite;
    })
    .catch((err: GraphQLErrorResponse) => {
      const { errors } = err;
      if (errors && errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        throw new Error(errorMessage);
      }
      throw err;
    }
  );
};

/**
 * Creates a MeetingRoomEventAuditLog
 *
 * @param {CreateMeetingRoomEventAuditLogInput} input MeetingRoomEventAuditLogInput
 * @returns {Promise<MeetingRoomEventAuditLog | undefined>} the MeetingRoomEventAuditLog
 */
 export const createMeetingRoomEventAuditLogMutation = async (
  input: CreateMeetingRoomEventAuditLogInput
): Promise<MeetingRoomEventAuditLog | undefined> => {
  const query = graphqlOperation(createMeetingRoomEventAuditLog, { input });
  return (API.graphql(query) as Promise<GraphQLResult<CreateMeetingRoomEventAuditLogMutation>>)
    .then((result: GraphQLResult<CreateMeetingRoomEventAuditLogMutation>) => {
      return result.data?.createMeetingRoomEventAuditLog as MeetingRoomEventAuditLog;
    })
    .catch((err: GraphQLErrorResponse) => {
      const { errors } = err;
      if (errors && errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        throw new Error(errorMessage);
      }
      throw err;
    }
  );
};

/**
 * Gets the product information for a meeting
 *
 * @param {MeetingInvite} meetingInvite MeetingInvite
 * @param {DataProvider} dataProvider DataProvider
 * @returns {Promise<IMeetingProductInformation>} the meeting invite and production information
 */
export async function getMeetingProductInformation(
  meetingInvite: MeetingInvite,
  dataProvider: DataProvider,
): Promise<IMeetingProductInformation> {
  let {
    product,
  } = meetingInvite;
  const {
    meetingId,
    productId,
  } = meetingInvite;
  if (!product && productId) {
    product = (await dataProvider.getOne("Products", {id: productId})).data as Product; // TODO add error handling here
  }
  if (!product) {
    throw new Error("product not found");
  }
  let sellerTeamId: string | null = null;
  let buyerTeamId: string | null = null;
  let buyerTeam: Team | null = null;
  await dataProvider.getList(
    "teamMeetingRelationships",
    {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "listTeamMeetingRelationshipsByMeetingId", order: "" }, // field is the name of the index
      filter: {
        listTeamMeetingRelationshipsByMeetingId: { // the name of the index
          meetingId,
        },
      },
    },
  ).then(
    async (listOfTeamMeetingRelationships: any) => {
      logger.info("listOfTeamMeetingRelationships", listOfTeamMeetingRelationships);
      if (listOfTeamMeetingRelationships.data.length > 0) {
        let buyerTeamPrivacyEnableOtherTeams = false;
        let sellerTeamPrivacyEnableOtherTeams = false;
        for (let i=0; i < listOfTeamMeetingRelationships.data.length; i=i+1) {
          const teamMeetingRelationship = listOfTeamMeetingRelationships.data[i] as TeamMeetingRelationship;
          if (teamMeetingRelationship.relationshipType == TeamMeetingRelationshipType.ORGANIZER) {
            sellerTeamPrivacyEnableOtherTeams = teamMeetingRelationship.team.privacyEnableOtherTeams ? true : false;
          } else if (teamMeetingRelationship.relationshipType == TeamMeetingRelationshipType.INVITEE) {
            buyerTeamPrivacyEnableOtherTeams = teamMeetingRelationship.team.privacyEnableOtherTeams ? true : false;
          }
        }
        for (let i=0; i < listOfTeamMeetingRelationships.data.length; i=i+1) {
          const teamMeetingRelationship = listOfTeamMeetingRelationships.data[i] as TeamMeetingRelationship;
          if (
            teamMeetingRelationship.relationshipType == TeamMeetingRelationshipType.ORGANIZER
            && !teamMeetingRelationship.team.privacyEnabled
            && !buyerTeamPrivacyEnableOtherTeams
          ) {
            sellerTeamId = teamMeetingRelationship.team.id;
          } else if (
            teamMeetingRelationship.relationshipType == TeamMeetingRelationshipType.INVITEE
            && !teamMeetingRelationship.team.privacyEnabled
            && !sellerTeamPrivacyEnableOtherTeams
          ) {
            buyerTeamId = teamMeetingRelationship.team.id;
            buyerTeam = teamMeetingRelationship.team;
          }
        }
      }
    }
  ).catch(
    (err: Error) => {
      logger.error("error querying listOfTeamMeetingRelationships", err);
    }
  );
  return {
    meetingInvite,
    product,
    sellerTeamId,
    buyerTeamId,
    buyerTeam,
  };
}

/**
 * Gets the Team Image
 *
 * @param {string} teamId Team ID
 * @returns {Promise<TeamImage>} the team image
 */
export async function getTeamImageByTeamId(
  teamId: string,
  dataProvider: DataProvider,
): Promise<TeamImage | null | undefined> {
  let teamImage: TeamImage | null | undefined = null;
  return dataProvider.getList(
    "teamImages",
    {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "listTeamImagesByTeamId", order: "" }, // field is the name of the index
      filter: {
        listTeamImagesByTeamId: { // the name of the index
          teamId,
        },
      },
    },
  ).then(
    async (listOfTeamImages: any) => {
      logger.info("listOfTeamImages", listOfTeamImages);
      if (listOfTeamImages.data.length > 0) {
        teamImage = (listOfTeamImages.data as TeamImage[]).find((i) => i.isDefault)
        if (!teamImage) {
          teamImage = listOfTeamImages.data[0] as TeamImage;
        }
      }
      return teamImage;
    }
  ).catch(
    (err: Error) => {
      logger.error("error querying listOfTeamMeetingRelationships", err);
      return teamImage;
    }
  );
}

/**
 * Gets the Transcription Job by meetingInvite
 *
 * @param {string} meetingInviteId meeting invite ID
 * @param {DataProvider} dataProvider the data provider
 * @returns {Promise<TranscriptionJob>} the TranscriptionJob
 */
export async function getTranscriptionJobByMeetingInviteId(
  meetingInviteId: string,
  dataProvider: DataProvider,
): Promise<TranscriptionJob | null | undefined> {
  let transcriptionJob: TranscriptionJob | null | undefined = null;
  return dataProvider.getList(
    "transcriptionJobsByMeetingInviteId",
    {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "listTranscriptionJobsByMeetingInviteId", order: "" },
      filter: {
        listTranscriptionJobsByMeetingInviteId: { // the name of the index
          meetingInviteId,
        },
      },
    },
  ).then(
    async (listOfTranscriptionJobs: any) => {
      logger.info("listOfTranscriptionJobs", listOfTranscriptionJobs);
      if (listOfTranscriptionJobs.data.length > 0) {
        transcriptionJob = (listOfTranscriptionJobs.data as TranscriptionJob[]).find((t) => t.status === JobStatus.COMPLETED)
        if (!transcriptionJob) {
          transcriptionJob = listOfTranscriptionJobs.data[0] as TranscriptionJob;
        }
      }
      return transcriptionJob;
    }
  ).catch(
    (err: Error) => {
      logger.error("error querying listTranscriptionJobsByMeetingInviteId", err);
      return transcriptionJob;
    }
  );
}

/**
 * Get completed Transcription Admin Job by meeting
 *
 * @param {string} meetingId meeting ID
 * @param {DataProvider} dataProvider the data provider
 * @returns {Promise<TranscriptionAdminJob>} the TranscriptionAdminJob
 */
export async function getTranscriptionAdminJobByMeetingId(
  meetingId: string,
  dataProvider: DataProvider,
  owner?: string | null,
): Promise<TranscriptionAdminJob | null | undefined> {
  let transcriptionAdminJob: TranscriptionAdminJob | null | undefined = null;
  return dataProvider.getList(
    "transcriptionAdminJobsByMeetingId",
    {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "listTranscriptionAdminJobsByMeetingId", order: "" },
      filter: {
        listTranscriptionAdminJobsByMeetingId: { // the name of the index
          meetingId,
        },
      },
    },
  ).then(
    async (listOfTranscriptionAdminJobs: any) => {
      logger.info("listOfTranscriptionAdminJobs", listOfTranscriptionAdminJobs);
      if (listOfTranscriptionAdminJobs.data.length > 0) {
        transcriptionAdminJob = (listOfTranscriptionAdminJobs.data as TranscriptionAdminJob[]).find((t) => t.status === JobStatus.COMPLETED);
        if (owner) {
          transcriptionAdminJob = (listOfTranscriptionAdminJobs.data as TranscriptionAdminJob[]).find((t) => t.status === JobStatus.COMPLETED && t.owner === owner);
          if (!transcriptionAdminJob) {
            transcriptionAdminJob = (listOfTranscriptionAdminJobs.data as TranscriptionAdminJob[]).find((t) => t.owner === owner);
          }
          if (!transcriptionAdminJob) {
            logger.info('transcriptionAdminJob by owner empty');
            return null;
          }
          logger.info('found transcriptionAdminJob by owner');
          return transcriptionAdminJob;
        }
        if (!transcriptionAdminJob) {
          transcriptionAdminJob = listOfTranscriptionAdminJobs.data[0] as TranscriptionAdminJob;
        }
      }
      return transcriptionAdminJob;
    }
  ).catch(
    (err: Error) => {
      logger.error("error querying listTranscriptionAdminJobsByMeetingId", err);
      return transcriptionAdminJob;
    }
  );
}

export function downloadFileFromS3(
  key: string,
  storage: StorageClass,
  level: StorageAccessLevel = "protected",
) {
  const pathIndex = key.indexOf("transcriptions");
  const pathAndFileName = key.substring(pathIndex);
  logger.info("transcriptionJob pathAndFileName", pathAndFileName);
  storage.get(pathAndFileName, { level }).then(
    (downloadUrl: string) => {
      logger.info("downloadUrl", downloadUrl);
      window.open(downloadUrl, "_blank");
    }
  ).catch(
    (err: Error) => {
      logger.error("Storage.get error", err);
    }
  );
}

export function redirectToDaily(
  props: {
    room: string,
    meetingId: string,
    meetingInviteId: string,
    meetingRoomId: string,
    profileId: string,
    privacyEnabled: string,
    transcriptionEnabled: string,
    serverTimeDifference: string,
    endTimestamp: string,
    token?: string,
    inAppLogo?: ThemeSettingsLogo,
    defaultLogo?: ThemeSettingsLogo,
  }
) {
  const {
    room,
    meetingId,
    meetingInviteId,
    meetingRoomId,
    profileId,
    privacyEnabled,
    transcriptionEnabled,
    serverTimeDifference,
    endTimestamp,
    token,
    inAppLogo,
    defaultLogo,
  } = props;
  const params = new URLSearchParams(
    {
      room,
      meetingId,
      meetingInviteId,
      meetingRoomId,
      profileId,
      privacyEnabled,
      transcriptionEnabled,
      serverTimeDifference,
      endTimestamp,
      ...(token && { t:`${token}` }),
      ...(inAppLogo && { logoUrl:`${inAppLogo.url}` }),
    }
  );
  if (!inAppLogo && defaultLogo) {
    params.set('logoUrl', defaultLogo.url);
  }

  logger.info("custom MeetingCountdown params", {
    room,
    meetingId,
    meetingInviteId,
    meetingRoomId,
    profileId,
    privacyEnabled,
    transcriptionEnabled,
    serverTimeDifference,
    endTimestamp,
    ...(token && { t:`${token}` }),
    ...(inAppLogo && { logoUrl:`${inAppLogo.url}` }),
  });
  const url = `https://meeting.insightgateway.com/?${params.toString()}`;
  logger.info("MeetingCountdown redirecting to url...", url);
  window.location.href = url;
}

export function offsetDate(
  date: Date,
  serverTimeDifference=0,
  applyServerOffset=false,
): Date {
  if (!APPLY_SERVER_OFFSET && !applyServerOffset) {
    serverTimeDifference = 0;
  } else {
    logger.debug("MeetingCountdown applying server offset");
  }
  return new Date(date.getTime() + serverTimeDifference);
}

export function getCurrentDateOrDateTime(
  serverTimeDifference=0,
  useLuxon=false,
  applyServerOffset=false,
): Date | DateTime {
  logger.debug("applyServerOffset / serverTimeDifference", { applyServerOffset, serverTimeDifference });
  logger.debug("APPLY_SERVER_OFFSET", APPLY_SERVER_OFFSET);
  if (!APPLY_SERVER_OFFSET && !applyServerOffset) {
    serverTimeDifference = 0;
  } else {
    logger.debug("applying server offset");
  }
  if (useLuxon) {
    const serverTimeDifferenceInSeconds = Math.round(serverTimeDifference / 1000);
    if (serverTimeDifference < 0) {
      return DateTime.now().minus({ seconds: Math.abs(serverTimeDifferenceInSeconds) });
    }
    return DateTime.now().plus({ seconds: serverTimeDifferenceInSeconds });
  }
  return new Date(new Date().getTime() + serverTimeDifference);
}

export function getCurrentDate(
  serverTimeDifference=0,
  applyServerOffset=false,
): Date {
  return getCurrentDateOrDateTime(
    serverTimeDifference,
    false,
    applyServerOffset,
  ) as Date;
}

export function getCurrentDateTime(
  serverTimeDifference=0,
  applyServerOffset=false,
): DateTime {
  return getCurrentDateOrDateTime(
    serverTimeDifference,
    true,
    applyServerOffset,
  ) as DateTime;
}

export async function logEvent(
  profile: Profile,
  message: string,
  data={},
  logLevel = 'info',
  serverTimeDifference=0,
  userAgentData: UserAgentParserResult | null = null,
) {
  try {
    const localDateTime = getCurrentDate();
    const messageWithPrefix = `[browser] ${profile.fullName} - ${message}`;
    let augmentedData = {};
    if (userAgentData) {
      augmentedData = userAgentData;
    }
    augmentedData = {
      ...augmentedData,
      ...data,
      ...{
        user: {
          id: profile.id,
          name: profile.fullName,
          email: profile.email,
        },
        time: {
          local_date_time: localDateTime.toLocaleString(),
          local_timestamp: localDateTime.getTime(),
          server_offset_in_milliseconds: serverTimeDifference,
        },
      },
    };
    await createAuditLog(
      {
        date: localDateTime.toLocaleString(),
        author: profile.fullName,
        resource: "Profile",
        action: messageWithPrefix,
        payload: JSON.stringify(augmentedData),
        meetingRoomId: "",
        profileId: profile.id,
      },
    );
  } catch (err) {
    logger.error("error in logEvent", err);
  }
}

export function validateExportJob(data: any) {
  let errors: any = {};

  let {
    startDateTime,
    endDateTime,
  } = data;

  if (!startDateTime) {
    const errorMessage = "Start date and time is required.";
    errors = { startDateTime: errorMessage };
    return errors;
  }
  if (typeof startDateTime === "string") {
    startDateTime = new Date(Date.parse(startDateTime));
  }
  const now = new Date();
  if (startDateTime.getTime() > now.getTime()) {
    const errorMessage = "Start date and time can not be in the future.";
    errors = { startDateTime: errorMessage };
  }

  if (!endDateTime) {
    const errorMessage = "End date and time is required.";
    errors = { endDateTime: errorMessage };
    return errors;
  }
  if (typeof endDateTime === "string") {
    endDateTime = new Date(Date.parse(endDateTime));
  }
  if (startDateTime.getTime() >= endDateTime.getTime()) {
    const errorMessage = "Start date must be before the end date.";
    errors = { startDateTime: errorMessage };
  }

  if (endDateTime.getTime() > now.getTime()) {
    const errorMessage = "End date and time can not be in the future.";
    errors = { endDateTime: errorMessage };
  }

  const durationInDays = Math.floor((endDateTime.getTime() - startDateTime.getTime()) / (24 * 60 * 60 * 1000));
  logger.info("durationInDays", durationInDays);

  if (durationInDays > 7) {
    const errorMessage = "Export date range must be 7 days or less.";
    errors = { endDateTime: errorMessage };
  }
  return errors;
}

export function meetingInviteCacheName(id: string): string {
  const cacheName = id.split("-").shift();
  return `mi${cacheName}`
}

export function joinInfoCacheName(id: string): string {
  const cacheName = id.split("-").shift();
  return `ji${cacheName}`
}

export function privacyEnabledCacheName(id: string): string {
  const cacheName = id.split("-").shift();
  return `pe${cacheName}`
}

export function meetingStartedCacheName(id: string): string {
  const cacheName = id.split("-").shift();
  return `ms${cacheName}`
}

export function meetingSucceededCacheName(id: string): string {
  const cacheName = id.split("-").shift();
  return `msed${cacheName}`
}
