import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { SITE_TITLE } from "../constants";
import { Copyright } from "./Copyright";
import { MeetingsStateContext } from "../App";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "linear-gradient(to right, #39B54A, #056839)",
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const meetingsState = useContext(MeetingsStateContext);
  const {
    defaultLogo,
  } = meetingsState;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={defaultLogo ? defaultLogo.url : "/img/logo.png"} alt={SITE_TITLE} width="300" height="110" />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() =>
            Auth.federatedSignIn({
              provider: CognitoHostedUIIdentityProvider.Cognito,
            })
          }
        >
          Sign In
        </Button>
      </div>
      <Box mt={8}>
        <Copyright
          variant="body2"
          color="textSecondary"
          align="center"
        />
      </Box>
    </Container>
  );
}
